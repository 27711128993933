import { Box, Button, Drawer, Stack, Typography } from '@mui/material';
import { navAdminItems, navItems } from './navConfig';
import Logo from '../Logo';
import useResponsive from '#/hooks/useResponsive';
import Iconify from '../Iconify';
import NavItem from './NavItem';
import useAuth from '#/hooks/useAuth';
import useLocales from '#/hooks/useLocales';
import MegaMenuItem from '#/components/shared/ui/navbar/MegaMenuItem';
import useLocalStorage from '#/lib/hooks/useLocalStorage';
import {
  useGetPartnerSettings,
  useGetPublicPartnerSettings,
} from '#/api/partnerQueries';

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

const Navbar = ({ openNav, onCloseNav }: Props) => {
  const { isAuthenticated, user } = useAuth();
  const { translate } = useLocales();
  const [ggPartner] = useLocalStorage('gg_partner_key', '');

  const isAdminLoggedIn =
    user?.partner?.role === 'admin' || user?.partner?.role === 'owner';

  const { data: byAdmin } = useGetPartnerSettings(isAdminLoggedIn);
  const { data: partnerTheme } = useGetPublicPartnerSettings(
    ggPartner,
    !!ggPartner
  );

  const hideHeaderLinks =
    ggPartner &&
    (byAdmin?.hide_header_links === true ||
      partnerTheme?.hide_header_links === true);

  const isDesktop = useResponsive('up', 'md');

  const handleRoleLinks = () => {
    const partnerRole = user?.partner?.role;
    if (
      partnerRole === 'owner' ||
      partnerRole === 'admin' ||
      partnerRole === 'consultant'
    ) {
      return navAdminItems;
    }
    return navItems;
  };

  return (
    <Box>
      {isDesktop ? (
        <Stack component="nav" direction="row" alignItems="center" spacing={2}>
          {handleRoleLinks()
            .filter(
              (item) =>
                (isAuthenticated ? item.auth : true) &&
                (hideHeaderLinks
                  ? item.title === 'global.services.login' ||
                    item.title === 'global.services.makeProvisions'
                  : true)
            )
            .map((item) => {
              // @ts-ignore
              const isMegaMenu = item?.megaMenu;
              if (isMegaMenu) {
                return (
                  <MegaMenuItem
                    key={item.title}
                    title={item.title}
                    // @ts-ignore
                    menuItems={item.items}
                  />
                );
              }
              return <NavItem key={item.title} {...item} />;
            })}
        </Stack>
      ) : (
        <Drawer
          anchor="left"
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: 250,
            },
          }}
        >
          <Stack>
            <Stack sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
              <Logo />
            </Stack>

            {handleRoleLinks()
              .filter(
                (item) =>
                  (isAuthenticated ? item.auth : true) &&
                  (hideHeaderLinks
                    ? item.title === 'global.services.login' ||
                      item.title === 'global.services.makeProvisions'
                    : true)
              )
              .map((item) => (
                <NavItem key={item.title} {...item} onCloseNav={onCloseNav} />
              ))}

            <Stack
              spacing={0.75}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                position: 'absolute',
                bottom: 0,
                left: 3,
                right: 0,
                marginBottom: 3,
                paddingBottom: 1,
                color: 'gray',
                fontSize: 15,
              }}
            >
              <Button variant="text" color="inherit" href="/dashboard/kontakt">
                <Iconify icon="material-symbols:help" />
                <Typography variant="body1" sx={{ fontSize: 14 }}>
                  {String(translate('global.needHelp'))}
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Drawer>
      )}
    </Box>
  );
};

export default Navbar;
