const metadata = {
  main: {
    title:
      'Bonne prévoyance avec gut geregelt - Votre portail suisse de prévoyance',
    description:
      'Enfin bien préparé : Créez tous les documents de prévoyance importants en ligne ! Juridiquement sûr ✓ En 10 min ✓ Confortablement depuis chez vous ✓ Plateforme suisse de prévoyance ✓',
    keywords: [
      'Directives anticipées',
      "Mandat pour cause d'inaptitude",
      'Testament',
      'Préférences personnelles',
      'Planification de la retraite',
      'Prévoyance vieillesse',
    ],
  },
  living_will: {
    title: 'Créer des directives anticipées suisses en ligne en 5 min',
    description:
      'En 5 min bien préparé : Créez un testament suisse en ligne avec gut geregelt. Juridiquement sûr ✓ Confortablement depuis chez vous ✓ Fournisseur suisse ✓',
    keywords: [
      'Directives anticipées',
      'Imprimer les directives anticipées',
      'Directive anticipée',
      'Directives anticipées à imprimer',
    ],
  },
  power_of_attorney: {
    title: "Créer un mandat pour cause d'inaptitude suisse en ligne en 5 min",
    description:
      "En 5 min bien préparé avec un mandat pour cause d'inaptitude suisse. Protection pour la famille ✓ Juridiquement sûr ✓ Confortablement depuis chez vous ✓ Fournisseur suisse ✓",
    keywords: [
      "Mandat pour cause d'inaptitude",
      "Modèle de mandat pour cause d'inaptitude",
      "Modèle de mandat pour cause d'inaptitude",
      "Modèle pour mandat pour cause d'inaptitude",
      "Mandat pour cause d'inaptitude",
    ],
  },
  last_will: {
    title: 'Créer un testament suisse en ligne en 10 min',
    description:
      'En 10 min bien préparé : Créez un testament suisse juridiquement sûr en ligne. Clarté pour les proches ✓ Simple ✓ Confortablement depuis chez vous ✓ Fournisseur suisse ✓',
    keywords: [
      'Testament',
      "Part légale d'héritage",
      'Héritage part légale',
      'Succession',
      'Succession légale',
    ],
  },
  marriage_contract: {
    title: 'Créer un contrat de mariage suisse en ligne en 5 min',
    description:
      'En 10 min bien préparé : Créez un contrat de mariage suisse en ligne avec gut geregelt. Juridiquement sûr ✓ Confortablement depuis chez vous ✓ Fournisseur suisse ✓',
    keywords: [
      'Contrat de mariage',
      "Part légale d'héritage",
      'Contrat de mariage achat immobilier',
      'Succession',
      'Succession légale',
    ],
  },
  partner_with_us: {
    title: 'Élargissez votre offre de prévoyance avec gut geregelt',
    description:
      "Utilisez la solution en marque blanche de gut geregelt pour rendre la prévoyance accessible à vos clients. S'intègre également dans votre processus de conseil.",
    keywords: [
      'Fondation de prévoyance',
      'Solution de prévoyance',
      'Conseil de fondation',
      'Conseil en prévoyance',
      'Conseiller en prévoyance',
    ],
  },
  digital_footprint: {
    title: 'Créer un héritage numérique en Suisse en ligne',
    description:
      'En 10 min bien préparé avec un héritage numérique. Documenter les comptes ✓ Représenter les valeurs numériques ✓ Conserver les données ✓ Confortablement depuis chez vous ✓ Fournisseur suisse ✓',
  },
  register_assets: {
    title: 'Créer un registre des biens et actifs suisses en ligne',
    description:
      'En 10 min bien préparé avec un registre des biens et actifs suisses. Enregistrer les actifs ✓ Créer de la clarté ✓ Confortablement depuis chez vous ✓ Fournisseur suisse ✓',
  },
  grosseItern: {
    title: 'Offre spéciale du magazine des grands-parents',
  },
  preventiveCheckUp: {
    title: 'Vérification de la prévoyance',
    description:
      'Avec la vérification de la prévoyance, vous pouvez vérifier et ajuster votre planification de la prévoyance si nécessaire.',
    keywords: [
      'Vérification de la prévoyance',
      'Risque de responsabilité',
      'Conseil de fondation',
      'Conseil en prévoyance',
      'Caisse de pension',
    ],
  },
  cohabitation_agreement: {
    title: 'Créer un contrat de cohabitation suisse en ligne',
    description:
      'En 10 min bien préparé : Créez un contrat de cohabitation suisse en ligne avec gut geregelt. Juridiquement sûr ✓ Confortablement depuis chez vous ✓ Fournisseur suisse ✓',
  },
  heirat: {
    title: 'Mariage : Ces dispositions sont maintenant importantes',
    description:
      'Se marier signifie prendre des responsabilités. Pour vous-même, pour votre conjoint, pour votre famille. Nous vous aidons à prendre les dispositions nécessaires.',
  },
  'geburt-eines-kindes': {
    title:
      "Naissance d'un enfant : Ces dispositions sont maintenant importantes",
    description:
      "La naissance d'un enfant est un événement merveilleux. C'est le moment idéal pour sécuriser les beaux moments et penser à long terme.",
  },
  immobilienkauf: {
    title:
      "Achat d'un bien immobilier : Ces dispositions sont maintenant importantes",
    description:
      "Acheter une maison est une grande étape. Pour préserver cette sécurité même en période difficile, il est important de se préparer à l'avance.",
  },
  pensionierung: {
    title: 'Retraite : Ces dispositions sont maintenant importantes',
    description:
      "Avec la retraite commence un nouveau chapitre de la vie. C'est le moment de penser à l'avenir.",
  },
  krankheit: {
    title:
      "Diagnostic d'une maladie : Ces dispositions sont maintenant importantes",
    description:
      'Une maladie grave change tout. Une directive anticipée et un testament garantissent que vos souhaits seront respectés et que vos proches seront protégés.',
  },
  'tod-verwandter': {
    title: "Décès d'un proche : Ces dispositions sont maintenant importantes",
    description:
      "La perte d'un proche est une période difficile. Mettez de l'ordre et aidez vos proches à tout gérer selon vos volontés.",
  },
  unternehmensgruendung: {
    title:
      "Création d'entreprise : Ces dispositions sont maintenant importantes",
    description:
      "Créer une entreprise concrétise votre vision et crée une valeur durable. Pendant cette période excitante, il est important de s'assurer une protection.",
  },
  scheidung: {
    title: 'Divorce : Ces dispositions sont maintenant importantes',
    description:
      'Le divorce peut être une période difficile. Nous vous aidons à prendre toutes les dispositions nécessaires pour que vous et votre famille soyez bien protégés.',
  },
};

export default metadata;
