const lastWillLanding = {
  mainBanner: {
    intro: 'Testament online erstellen:',
    title: 'Rechtssicher, in 10 Minuten erledigt',
    subtitle:
      'Die proaktive Festlegung dessen, was mit Ihrem Vermögen geschehen wird, hilft dabei, Ihren letzten Willen zu gewährleisten und den Prozess für Ihre Familie bequemer zu gestalten.',
    buttonLabel: 'Testament erstellen',
  },
  youtubeTutorial: 'Warum ein Testament wichtig ist?',
  aboutCards: {
    whoGetsWhat: {
      title: 'Wer bekommt was',
      description:
        'Das Testament regelt, wer wie viel von dem bekommt, was von der gesetzlichen Erbfolge abweichen darf.',
    },
    enforceWishes: {
      title: 'Wünsche durchsetzen',
      description:
        'Stellen Sie sicher, dass Sie Ihr Vermögen gemäss Ihren persönlichen Wünschen und Vorstellungen verteilen.',
    },
    clarificationForFamily: {
      title: 'Klarheit für die Familie',
      description:
        'Machen Sie unnötige Diskussionen überflüssig und sorgen Sie für Klarheit für Ihre Hinterbliebenen.',
    },
  },
  subBanner: {
    title: 'Gut geregelt mit einem rechtlich konformen Testament',
    subtitle:
      'Mit gut geregelt erstellen Sie schnell Ihr Testament, können rechtliche Beratung zu heiklen Themen oder offenen Fragen erhalten und tun Ihren Angehörigen einen Gefallen.',
    buttonLabel: 'Jetzt kostenlos erstellen',
  },
  newsletter: {
    title: 'Werden Sie gut geregelt mit Ihrem Testament',
    buttonLabel: 'Jetzt erstellen',
  },
  faq: {
    questions: {
      question1: 'Was bringt mir ein Testament?',
      question2:
        'Gibt es verschiedene Arten, wie ich mein Testament erstellen kann?',
      question3: 'Was fällt unter den gesetzlichen Erbanspruch?',
      question4:
        'Was hat sich mit der Revision des Erbrechts am 1. Januar 2023 geändert?',
      question5:
        'Über welche Teile meines Vermögens darf ich im Testament frei verfügen?',
      question6: 'Was geschieht, wenn ich kein Testament habe und sterbe?',
      question7:
        'Kann ich mein Testament zusammen mit meinem Ehepartner verfassen?',
      question8: 'Kann ich Menschen ausserhalb meiner Familie erben?',
      question9: 'Darf ich jemanden enterben?',
      question10: 'Muss ich einen Willensvollstrecker ernennen?',
      question11: 'Kann ich mein Testament jederzeit anpassen?',
      question12:
        'Gibt es neben dem Testament weitere Dokumente, die ich erstellen sollte?',
      question13: 'Wie hilft mir gut geregelt bei meinem Testament?',
    },
    answers: {
      answer1:
        'Mit einem Testament regeln Sie Ihren Nachlass. Sie legen fest, was mit Ihrem Vermögen nach Ihrem Tod geschehen soll. Zudem gilt: Wenn Sie im Konkubinat leben und ihren Partner beerben möchten, müssen Sie das unbedingt im Testament festhalten.',
      answer2:
        'Ja, es gibt das eigenhändige Testament, das öffentliche Testament und das mündliche Testament.',
      answer3:
        'Es gibt einen Pflichtteil, den gesetzlichen Erbanspruch, der sicherstellt, dass Ehepartner oder Kinder nicht komplett von Ihrem Erbe ausgeschlossen werden können.',
      answer4:
        'Die grösste Änderung ist, dass der Pflichtteil der Eltern komplett wegfällt und der Pflichtteil der Kinder für unverheiratete Erblasser von 75% auf 50% reduziert wurde.',
      answer5:
        'Neben den Pflichtteilen dürfen Sie frei über Ihren Nachlass entscheiden. Sie können also beispielsweise Ihren Ehepartner oder eingetragenen Partner mehr begünstigen.',
      answer6:
        'In diesem Fall tritt automatisch die gesetzliche Erbfolge in Kraft und Ihr Nachlass wird aufgeteilt, abhängig von Ihrer familiären Situation zum Todeszeitpunkt.',
      answer7:
        'Nein, Sie dürfen Ihr Testament nicht gemeinsam mit Ihrem Ehepartner erstellen.',
      answer8:
        'Ja, neben dem gesetzlich festgelegten Pflichtteil dürfen Sie selbst entscheiden, wen Sie als Erben einsetzen, auch Vereine oder Institutionen.',
      answer9:
        'Solange es sich nicht um Erben handelt, die Anrecht auf den Pflichtteil haben, können Sie enterben.',
      answer10:
        'Nein, das Bestimmen eines Willensvollstreckers ist freiwillig, kann aber vor Erbstreitigkeiten schützen.',
      answer11:
        'Ja, Sie können jederzeit Änderungen vornehmen, wichtig ist, dass Sie diese datieren und unterschreiben.',
      answer12:
        'Ja, der {{powerOfAttorney}} und die {{livingWill}} sind zwei Dokumente, die Sie möglichst zeitnah erstellen sollten.',
      answer13:
        'Bei gut geregelt helfen wir Ihnen mit dem reibungslosen und rechtsgültigen Erstellen Ihres Testaments.',
    },
    hyperlinks: {
      lastWill: 'Testament',
      livingWill: 'Patientenverfügung',
      powerOfAttorney: 'Vorsorgeauftrag',
    },
  },
  situations: {
    heirat: {
      content: {
        main: {
          title: 'Testament',
          description:
            'Mit einem Testament legen Sie fest, was mit Ihrem Vermögen im Todesfall geschieht. Sie können sicherstellen, dass Ihr Ehepartner und Ihre Familie abgesichert sind und Ihr Wille respektiert wird.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'Ein Testament gibt Ihnen die Sicherheit, dass Ihr Vermögen nach Ihren Wünschen verteilt wird und Ihr Ehepartner rechtlich abgesichert ist.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Absicherung des Ehepartners',
              'Klare Erbregelungen',
              'Vermeidung von Streitigkeiten',
            ],
          },
        ],
        ctas: {
          learnMore: 'Mehr erfahren',
          startProcess: 'Prozess starten',
        },
      },
    },
    'geburt-eines-kindes': {
      content: {
        main: {
          title: 'Testament',
          description:
            'Mit einem Testament legen Sie fest, wie Ihr Vermögen im Todesfall aufgeteilt wird. Sie können sicherstellen, dass Ihr Kind finanziell abgesichert ist und Ihre Wünsche respektiert werden.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'Ein Testament gibt Ihnen die Sicherheit, dass Ihr Kind im Falle Ihres Todes gut versorgt ist und dass Ihre Vermögenswerte nach Ihren Wünschen verteilt werden.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Finanzielle Sicherheit für Partner/in und Familie',
              'Rechtliche Klarheit über Erbfolgen',
              'Schutz des Familienvermögens',
            ],
          },
        ],
        ctas: {
          learnMore: 'Mehr erfahren',
          startProcess: 'Prozess starten',
        },
      },
    },
    pensionierung: {
      content: {
        main: {
          title: 'Testament',
          description:
            'Mit einem Testament legen Sie fest, wie Ihr Vermögen nach Ihrem Tod aufgeteilt wird. Sie sorgen dafür, dass Ihre Liebsten nach Ihren Wünschen abgesichert sind.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'Im Rentenalter ist es wichtig, die Nachlassplanung in die Hand zu nehmen, um Erbstreitigkeiten zu vermeiden und Ihre Familie abzusichern.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Klarheit über die Vermögensverteilung',
              'Absicherung der Familie',
              'Vermeidung von Streitigkeiten',
            ],
          },
        ],
        ctas: {
          learnMore: 'Mehr erfahren',
          startProcess: 'Prozess starten',
        },
      },
    },
    krankheit: {
      content: {
        main: {
          title: 'Testament',
          description:
            'Mit einem Testament bestimmen Sie, wie Ihr Vermögen nach Ihrem Tod aufgeteilt wird. So sorgen Sie dafür, dass Ihre Liebsten abgesichert sind und Ihr letzter Wille respektiert wird.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'Eine schwere Diagnose macht deutlich, wie schnell sich das Leben verändern kann. Ein Testament sorgt dafür, dass Ihre Familie finanziell abgesichert ist und Streitigkeiten vermieden werden.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Absicherung Ihrer Familie',
              'Klarheit über die Erbverteilung',
              'Schutz des Vermögens',
            ],
          },
        ],
        ctas: {
          learnMore: 'Mehr erfahren',
          startProcess: 'Prozess starten',
        },
      },
    },
    unternehmensgruendung: {
      content: {
        main: {
          title: 'Testament',
          description:
            'Mit einem Testament regeln Sie, was mit Ihrem Unternehmen und Vermögen im Todesfall geschieht. So stellen Sie sicher, dass Ihr Erbe nach Ihren Wünschen verwaltet und Ihr Unternehmen fortgeführt wird.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'Gerade als Firmengründer ist es wichtig, frühzeitig Vorkehrungen zu treffen. Mit einem Testament sichern Sie den Fortbestand Ihres Unternehmens und verhindern rechtliche Auseinandersetzungen.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Klare Erbregelungen für das Unternehmen',
              'Vermeidung von Streitigkeiten',
              'Schutz des Familienvermögens und Unternehmenswerts',
            ],
          },
        ],
        ctas: {
          learnMore: 'Mehr erfahren',
          startProcess: 'Prozess starten',
        },
      },
    },
    scheidung: {
      content: {
        main: {
          title: 'Testament',
          description:
            'Mit einem Testament legen Sie fest, wie Ihr Vermögen im Todesfall aufgeteilt wird. Gerade nach einer Scheidung sollten Sie sicherstellen, dass Ihre Erbschaftsregelungen Ihre neuen Lebensumstände widerspiegeln.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'Eine Scheidung verändert Ihre Familien- und Vermögensverhältnisse. Ein (aktualisiertes) Testament sorgt dafür, dass Ihre Wünsche auch nach der Trennung respektiert werden.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Klarheit über die Verteilung Ihres Vermögens',
              'Schutz Ihrer Liebsten',
              'Anpassung an die veränderte Familiensituation',
            ],
          },
        ],
        ctas: {
          learnMore: 'Mehr erfahren',
          startProcess: 'Prozess starten',
        },
      },
    },
  },
};

export default lastWillLanding;
