const pathnames = {
  auth: {
    index: 'auth',
    login: 'connexion',
    verify: 'vérifier',
    resetPassword: 'réinitialiser-mot-de-passe',
    passwordResetConfirm: 'confirmation-réinitialisation-mot-de-passe',
    partnerOnboarding: 'intégration-partenaire',
  },
  dashboard: {
    index: 'tableau-de-bord',
    home: 'accueil',
    guestOn: 'invité-sur',
    guestOnDocumentHistory: 'invité-sur/:guestId/historique-document',
    contact: 'contact',
    livingWill: 'directive-anticipée',
    powerAttorney: 'procuration',
    lastWill: 'testament',
    marriageContract: 'contrat-de-mariage',
    assetsRegister: 'registre-des-actifs-et-biens',
    cohabitationAgreement: 'contrat-de-cohabitation',
    digitalLegacy: {
      index: 'héritage-numérique',
      importantInformation: 'informations-importantes',
      digitalAssets: 'actifs-numériques',
      photosVideos: 'photos-vidéos',
    },
    taxFolderClient: 'dossier-fiscal',
    chat: 'chat',
    thankyouCard: 'arrangements-finaux',
    consultant: 'consultant',
    consulting: 'consultation',
    createUser: 'consultation/créer-utilisateur',
    user: {
      index: 'utilisateur',
      account: 'compte',
    },
  },
  compact: {
    forbidden: '403',
    notFound: '404',
    error: '500',
    maintenance: 'maintenance',
    comingSoon: 'bientôt-disponible',
    contact: 'contact',
    pension: 'prévoyance',
    register: 'inscription',
    onboardingStepOne: 'prévoyance/étape=1',
    onboardingStepTwo: 'prévoyance/étape=2',
    onboardingStepThree: 'prévoyance/étape=3',
    acceptInvitation: 'personne-de-confiance/invitation',
    familyInvitation: 'famille/invitation',
    partnerInvitation: 'partenaire/invitation',
  },
  main: {
    index: '/',
    situationCheck: 'vérification-de-situation',
    grosselternMagazin: 'offres/magazine-grandparents',
    preventiveCheckUp: 'bilan-préventif',
    situationLanding: 'prévoyance',
    aboutUs: 'à-propos',
    imprint: 'mentions-légales',
    privacyPolicy: 'politique-de-confidentialité',
    agb: 'termes-et-conditions',
  },
};

export default pathnames;
