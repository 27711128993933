const fr = {
  homeTitle: 'Être bien organisé',
  homeDescription:
    'Prenez dès maintenant vos dispositions personnelles et familiales ou parlez à un conseiller financier ou juridique.',
  homeHighlight: 'financier',
  homeHighlight2: 'juridique',

  clear: 'Effacer',
  search: 'Rechercher',
  done: 'Terminé',

  ch: 'Suisse',
  noData: 'Pas de données',
  beneficiaries: 'Bénéficiaires',
  representatives: 'Représentants',
  footerSettings: 'Pied de page',

  service: 'Service',
  accessLevel: "Niveau d'accès",
  addServiceAccess: 'Ajouter un accès aux artefacts',
  addNewSuccessor: 'Ajouter un nouveau bénéficiaire',
  afterDeath: 'Après la mort',
  whileAlive: 'Pendant la vie',

  customerOpinion: 'Avis des clients',
  joinWaitlist: "Rejoindre la liste d'attente",
  servicesToShare: 'Services que je souhaite partager',

  documentType: 'Type de document',

  docClassificationWarning:
    'Nous ne sommes pas sûrs du type de document. Veuillez le valider',

  pending: 'En cours',
  failed: 'Échoué',

  documentTypeStatus: {
    pending: 'En attente',
    approved: 'Approuvé',
    rejected: 'Rejeté',
  },

  ppDocumentChoices: {
    tax_declaration: 'Déclaration fiscale',
    tax_invoice: 'Facture fiscale',
    salary_statement: 'Attestation de salaire',
    yearly_salary_statement: 'Attestation de salaire annuelle',
    pension_certificate: 'Certificat de pension',
    alimony_confirmation: 'Confirmation de pension alimentaire',
    monthly_salary_statement: 'Attestation de salaire mensuelle',
    rental_income: 'Confirmation des revenus locatifs',
    pension_statement: 'Relevé de pension',
    income_statement: 'Relevé de revenus',
    balance_sheet: 'Bilan',
    tax_certificate: 'Certificat fiscal',
    account_statement: 'Relevé de compte',
    interest_capital_statement: 'Relevé des intérêts et du capital',
    receipt_travel_costs: 'Reçu des frais de déplacement',
    general_invoice: 'Facture générale',
    debt_certificate: 'Certificat de dette',
    pension_fund_purchase: "Certificat d'achat de fonds de pension",
    confirmation_donation: 'Confirmation de don',
    receipt_education: "Reçu des frais d'éducation",
    insurance_policy: "Police d'assurance",
    mortgage_contract: 'Contrat hypothécaire',
    health_insurance_invoice: "Facture d'assurance maladie",
    receipt_childcare: "Reçu de garde d'enfants",
    utilities_statement: 'Relevé des services publics',
    renovation_list: 'Liste des rénovations',
    real_estate_valuation: 'Évaluation immobilière',
    securities_trading: 'Négociation de titres',
    third_pillar_confirmation: "Confirmation d'achat du 3e pilier",
  },

  shareWithDscp:
    'Sélectionnez ou ajoutez un nouveau membre avec qui vous souhaitez partager vos impôts',

  uploadingToWarning:
    'En téléchargeant à partir de ce niveau du dossier, le fichier sera ajouté à',
  allDocuments: 'Tous les documents',
  finalTaxDeclaration: 'Déclaration fiscale finale',
  proofOfIncome: 'Justificatif de revenus',
  proofOfAssets: 'Justificatif de patrimoine',
  deductibleExpenses: 'Dépenses déductibles',
  realEstateAndProperties: 'Immobiliers et propriétés',
  otherDocuments: 'Autres documents',
  taxInvoices: 'Factures fiscales',
  tax: 'Dossier fiscal',
  can_edit: 'Peut modifier',
  can_view: 'Peut voir',
  view: 'Voir',
  access: 'Accès',

  civilStatus: {
    married: 'Marié',
    single: 'Célibataire',
    divorced: 'Divorcé',
    widowed: 'Veuf',
    registered_partnership: 'Partenariat enregistré',
  },

  contractParties: {
    self: 'Moi',
    spouse: 'Conjoint',
  },
  fileTypeOptions: {
    audio: 'Audio',
    video: 'Vidéo',
    document: 'Document',
    archive: 'Archive',
    image: 'Image',
  },

  guestTaxFolder: {
    title: 'Documents fiscaux pour {{user}}',
    description:
      'Ici, vous pouvez consulter tous les documents fiscaux pertinents et contacter votre client pour demander des informations manquantes ou clarifier des questions ouvertes.',
    messageClient: 'Message au client',
  },

  taxFolder: {
    title: 'Aperçu de vos impôts',
    requestTaxExpert: 'Demander un expert fiscal',
    submitLatestTax: 'Soumettre la dernière déclaration',
    submittedLatestTax: 'Dernière déclaration soumise',
    description:
      "Vérifiez les versions précédentes et assurez-vous de savoir comment les choses ont été faites à l'époque.",
    requestTaxExpertModal: {
      title: 'Nous vous connectons avec un expert fiscal',
      description:
        "Envoyez-nous une demande et nous vous connecterons avec un expert fiscal près de chez vous qui prendra en charge la déclaration d'impôt pour vous",
      reqBtn: 'Envoyer la demande',
    },
    findTaxExpert: {
      title: 'Trouvez un expert fiscal près de chez vous',
      description:
        "Diverses organisations et experts vous aident à préparer et à optimiser votre déclaration d'impôt. Trouvez le partenaire qui vous convient.",

      tableHead: {
        expert: 'Expert',
        location: 'Emplacement',
        price: 'Prix',
      },
    },
  },
  chat: {
    customerChat: 'Chats clients',
  },
  dashCardInfos: {
    livingWill: {
      title: 'Directives anticipées',
      description: 'Enregistrez vos souhaits concernant le traitement médical.',
      estimate: '7 minutes',
    },
    powerOfAttorney: {
      title: "Mandat pour cause d'inaptitude",
      description: 'Qui décide pour vous si vous ne pouvez pas?',
      estimate: '10 minutes',
    },
    lastWill: {
      title: 'Testament',
      description: 'Sécurisez votre héritage selon vos souhaits.',
      estimate: '12 minutes',
    },
    marriageContract: {
      title: 'Contrat de mariage',
      description:
        'Le cas échéant, établissez des règles claires pour votre partenariat.',
    },
    digitalFootprint: {
      title: 'Héritage numérique',
      description: 'Contrôlez ce qui arrive à vos comptes en ligne.',
    },
    assetsRegister: {
      title: 'Registre des biens',
      description: 'Conservez une trace de vos actifs.',
    },
    cohabitationAgreement: {
      title: 'Contrat de cohabitation',
      description: 'Organisez votre vie ensemble',
      estimate: '10 minutes',
    },
    successionPlanning: {
      title: 'Planification de la succession',
      description:
        "Vous êtes propriétaire d'une entreprise ? Assurez sa durabilité.",
    },
    taxFolder: {
      title: 'Dossier fiscal',
      description:
        'Dossier fiscal intelligent et numérique pour tous vos documents fiscaux',
    },
    comingSoon: {
      title: 'Bientôt disponible',
      description:
        'gut geregelt travaille constamment sur de nouveaux services.',
    },
    timeToComplete: 'Temps nécessaire pour la création: {{estimate}}',
    continue: 'Continuer',
    getStarted: 'Commencer',
    summaryAndReview: 'Afficher le document',
    uploadDocument: 'Télécharger le document',
    update: 'Mettre à jour',
    history: 'Historique',
    markAsDone: 'Marquer comme terminé',
    startOver: 'Recommencer',
    viewData: 'Afficher les données',
    guest: {
      disabledButton:
        "L'hôte a ajouté des restrictions supplémentaires pour l'accès à ce service.",
      getDocButton: 'Récupérer le document',
      noDocumentUploaded:
        "{{hostName}} n'a pas encore téléchargé {{documentType}}.",
    },
  },

  loginInfo: {
    title: 'Faites une faveur à vos proches, soyez bien organisé',
    title1: 'Bonjour, bienvenue de retour',
    newUser: 'Nouvel utilisateur ?',
    createAccount: 'Créer un compte',
    forgotPassword: 'Mot de passe oublié ?',
    login: 'Se connecter',
    resetPasswordInfo:
      "Veuillez entrer l'adresse e-mail associée à votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe par e-mail.",
    sendRequest: 'Envoyer la demande',
    returnToLogin: 'Retour à la connexion',
    setNewPassword: 'Définir un nouveau mot de passe',
    updatePassword: 'Mettre à jour le mot de passe',
    invalidLink: 'Le lien est invalide ou a expiré !',
    returnToResetPassword: 'Retour à la réinitialisation du mot de passe',
    welcomeOnboard: 'Bienvenue à bord',
    welcomeOnboardDescription:
      'Pour continuer vers le tableau de bord gut geregelt, veuillez définir un nouveau mot de passe.',
  },

  services: {
    forIndividuals: 'Pour les particuliers',
    forCompanies: 'Pour les entreprises',
    gutGeregelt: 'gut geregelt',
    livingWill: 'Directives anticipées',
    powerOfAttorney: "Mandat pour cause d'inaptitude",
    lastWill: 'Testament',
    marriageContract: 'Contrat de mariage',
    digitalFootprint: 'Héritage numérique',
    registerAssets: 'Registre des biens et actifs',
    cohabitationAgreement: 'Contrat de cohabitation',
    taxFolder: 'Dossier fiscal',
    pricing: 'Tarifs',
    login: 'Se connecter',
    makeProvisions: "S'inscrire maintenant",
    pensionCheck: 'Vérification de la prévoyance',
    partnerWithUs: 'Partenaire en marque blanche',
    blog: 'Blog',
    dashboard: 'Tableau de bord',
    consulting: 'Consultation',
    aboutUs: 'À propos de nous',
    other: 'Autre',
  },

  settings: {
    changeAccount: 'Changer de compte',
    changeAccountContent: {
      title: 'Passer à un autre compte',
    },
    settings: 'Paramètres',
    needHelp: "Besoin d'aide ?",
    logOut: 'Se déconnecter',
  },

  roles: {
    executor: 'Exécuteur',
    lawyer: 'Avocat',
    trustee: 'Fiduciaire',
    familyMember: 'Membre de la famille',
    friend: 'Ami',
  },
  fileSystemTableHead: {
    name: 'Nom',
    size: 'Taille',
    type: 'Type',
    modifiedAt: 'Modifié',
  },

  verifyCode: 'Veuillez vérifier le code envoyé à',
  learnMore: 'En savoir plus',
  startProcess: 'Commencer maintenant',
  selected: 'Sélectionné',
  folder: 'Dossier',
  file: 'Fichier',
  folders: 'Dossiers',
  files: 'Fichiers',
  create: 'Créer',
  upload: 'Télécharger',
  uploading: 'Téléchargement...',
  folderName: 'Nom du dossier',
  fileName: 'Nom du fichier',
  endDateError: 'La date de fin doit être postérieure à la date de début',
  newFolder: 'Nouveau dossier',
  editFolder: 'Modifier le dossier',
  uploadFiles: 'Télécharger les fichiers',
  editFile: 'Modifier le fichier',
  disabledService: {
    title:
      "Ce service n'est pas disponible dans votre plan d'abonnement actuel",
    description:
      'Veuillez mettre à niveau votre plan pour débloquer cette fonctionnalité',
  },
  editAccessRights: "Modifier les droits d'accès",
  editAccessRightsDscp:
    "Spécifiez quelles données l'institution est autorisée à consulter. Vous pouvez ajuster cela à tout moment ou révoquer complètement l'accès.",
  shareSelected: 'Partager uniquement les données sélectionnées',
  acceptingInvitation: "Accepter l'invitation",
  acceptingInvitationFail: 'Invitation expirée ou invalide...',
  removeAll: 'Tout supprimer',
  account: 'Compte',
  main: 'Principal',
  guest: 'Invité',
  manage: 'Gérer',
  trackHistoryTitle:
    "Vous pouvez suivre ci-dessous les documents téléchargés et l'historique de {{documentType}}",
  getStarted: 'Commencer',
  resetToDefault: 'Réinitialiser',
  lastUpdated: 'Dernière mise à jour :',
  description: 'Description',
  imageHelper: 'Formats de fichiers autorisés : *.jpeg, *.jpg, *.png, *.gif, ',
  imageMaxSize: 'taille maximale du fichier : ',
  storageLocation: 'Lieu de stockage',
  beneficiary: 'Bénéficiaire',
  continue: 'Continuer',
  beneficiarie: "Bénéfice à l'intention de",
  removeFilter: 'Supprimer le filtre',
  addNewBeneficiary: 'Ajouter un nouveau bénéficiaire',
  primary: 'Contact principal',
  representative: 'Représentant',
  addNewAsset: 'Ajouter un nouvel actif',
  addNewProperty: 'Ajouter une nouvelle propriété',
  uploadNew: 'Télécharger nouveau',
  apply: 'Appliquer',
  addMore: 'Ajouter plus',
  saveDetails: 'Enregistrer les détails',
  saving: 'Enregistrement...',
  enoughForNow: "Pause s'il vous plaît !",
  finishForToday: "C'est suffisant pour aujourd'hui",
  currentPackage: 'Vous avez choisi le forfait de prévoyance suivant :',
  nextPaymentDue: 'Prochain paiement dû le',
  expiresAt: 'Expire le',
  freePackage: 'Forfait gratuit',
  userDataAccess: {
    request: "Demande d'accès aux données",
    awaiting: "En attente de l'approbation",
    approved: 'Afficher les données du client',
  },
  info: 'Info',
  shareWith: 'Partager avec',
  starterPackage: 'Forfait de démarrage',
  standardPackage: 'Forfait standard',
  familyPack: 'Forfait familial',
  noPackage: 'Aucun forfait',
  needHelp: "Besoin d'aide ?",
  continueToLastWill: 'Continuer avec le testament',
  continueToPOA: "Continuer avec le mandat pour cause d'inaptitude",
  addAnother: 'Ajouter un exécuteur testamentaire',
  add: 'Ajouter',
  finish: 'Terminer',
  delete: 'Supprimer',
  edit: 'Modifier',
  save: 'Enregistrer',
  close: 'Fermer',
  next: 'Suivant',
  back: 'Retour',
  or: 'ou',
  role: 'Rôle',
  current: 'Actuel',
  gender: 'Genre',
  male: 'Masculin',
  howMany: 'Combien',
  female: 'Féminin',
  cancel: 'Annuler',
  verify: 'Vérifier',
  priority: 'Priorité',
  areYouSureYouWantToLeave: 'Voulez-vous faire une pause ?',
  enoughForNowDscp:
    "Ne vous inquiétez pas, nous enregistrons vos entrées et vous rappelons régulièrement que ce document n'est pas encore juridiquement valide. Vous pouvez reprendre à tout moment là où vous vous êtes arrêté",
  enoughForNowCancel: 'Oui, je veux faire une pause',
  enoughForNowContinue: 'Non, merci',
  addPropertyOrAsset: 'Ajouter une propriété ou un actif',
  addItem: 'Ajouter un actif',
  good: 'actif',
  addNew: 'Ajouter nouveau',
  owner: 'Propriétaire',
  adress: 'Adresse',
  download: 'Télécharger',
  upgradePlan: 'Améliorez votre plan pour télécharger deux documents ou plus',
  choosePlan: 'Choisir un plan',
  switzerland: 'Suisse',
  fillInfoTitle:
    'Veuillez vous assurer de fournir des informations importantes pour votre compte principal',
  switch: 'Changer',
  activatePersonalAccount: 'Activer votre compte personnel',
  howManyMembers: 'Membres de la famille',
  requiredBasicInfoTitle:
    'Veuillez entrer les informations de base requises avant de continuer',
  allType: 'Tous types',
  selectDate: 'Sélectionner une date',
  selectDateRange: 'Sélectionner une plage de dates',
  familyPackage: {
    title: 'Ma famille',
    description:
      'Avec le forfait famille, vous et les membres de votre famille pouvez régler votre prévoyance à prix réduit.',
    helperText:
      "Veuillez remplir soigneusement les informations de vos membres de famille. Ces informations ne peuvent pas être mises à jour une fois que l'utilisateur a accepté l'invitation.",
  },
  deleteConfirmation: {
    title: 'Confirmer la suppression',
    oneItemQuestion: 'Voulez-vous vraiment supprimer ce fichier ?',
    multipleItemsQuestion:
      'Voulez-vous vraiment supprimer ces {{itemNr}} fichiers ?',
    question: 'Êtes-vous sûr de vouloir supprimer {{item}} ?',
    multiDeleteQuestion: 'Voulez-vous vraiment supprimer {{items}} éléments ?',
    cancelDelete: 'Non, conserver {{item}}',
    confirmDelete: 'Oui, supprimer {{item}}',
  },

  formLabels: {
    tag: 'Tag',
    name: 'Nom',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    civilStatus: 'État civil',
    place_of_citinzenship: "Lieu d'origine",
    place_of_marriage: 'Lieu du mariage',
    date_of_marriage: 'Date du mariage',
    dropOrSelectFile: 'Déposer ou sélectionner un fichier',
    children_count: "Nombre d'enfants",
    street: 'Rue',
    houseNumber: 'Numéro',
    postalCode: 'Code postal',
    city: 'Ville',
    country: 'Pays',
    uploadPhoto: 'Télécharger une photo',
    twoFactorAuth: 'Authentification à deux facteurs',
    verify: 'Vérifier',
    uploadLogo: 'Télécharger le logo',
    favicon: 'Favicon',
    shareForFemalePartner: 'Part pour votre partenaire (en %)',
    shareForMalePartner: 'Part pour votre partenaire (en %)',
    submit: 'Soumettre',
    invite: 'Inviter',
    emailAddress: 'Adresse e-mail',
    role: 'Rôle',
    twoFactorAuthHelp:
      "Si vous activez l'authentification à 2 facteurs, un code sera envoyé à votre téléphone que vous devrez saisir pour vous connecter.",
    action: 'Action',
    region: 'Région',
    artifact: 'Artefact',
    search: 'Rechercher',
    mobilePhone: 'Numéro de téléphone portable',
    hide_header_links: 'Supprimer les liens du haut de page',
    hide_solution_menu: 'Supprimer la colonne "Solutions" du pied de page',
    footer_title: 'Titre du pied de page',
    footer_text: 'Texte du pied de page',
    description: 'Description',
    storageLocation: 'Lieu de stockage',
    designatedBeneficiary: 'Bénéficiaire désigné',
    benefiterAfterSeparation:
      'En cas de séparation, la propriété deviendra la propriété exclusive de',
    password: 'Mot de passe',
    oldPassword: 'Ancien mot de passe',
    newPassword: 'Nouveau mot de passe',
    confirmPassword: 'Confirmer le mot de passe',
    minimum6Characters: 'Minimum 6 caractères',
    primaryRepresentative: 'Représentant principal',
    secondaryRepresentative: 'Représentant secondaire',
    howManyChildren: "Combien d'enfants avez-vous ?",
    assetType: "Type d'actif",
    date_of_birth: 'Date de naissance',
    rep_inform_date: "Date d'information",
    trusted_person: 'Exécuteur testamentaire',
    billing_email: 'E-mail de facturation',
    asset: 'Actif',
    newConsultant: 'Nouveau consultant',
    institutionName: 'Institution financière',
    message: 'Message',
    location: 'Emplacement',
    topics: 'Sujets',
    type: 'Type',
    plusTopic: '+ Sujet',
    propertyType: 'Type de propriété',
    disposal_fixed_amount: 'Montant fixe',
    amount: 'Montant',
    hire: 'Engager un conseiller fiscal',
    passportLocation: 'Lieu de stockage du passeport',
    date_of_first_payment: 'Date du premier paiement',
    meet_year: 'Année',
    date_of_moving_in: "Date d'emménagement",
    idCardLocation: "Lieu de stockage de la carte d'identité",
    residencePermitLocation: 'Lieu de stockage du permis de séjour',
    familyBookLocation: 'Lieu de stockage du livret de famille',
    birthCertificateLocation: 'Lieu de stockage du certificat de naissance',
    disposal_percentage: 'Pourcentage',
    joint_household_account_bank: 'Banque du compte commun du ménage',
    plusUser: '+ Utilisateur',
    domain: 'Domaine',
    addWidget: 'Ajouter un widget',
    plusWidget: '+ Widget',
    organisation_name: "Nom de l'organisation",
    primary_color: 'Couleur primaire',
    secondary_color: 'Couleur secondaire',
    sendAutomatically:
      'Partager automatiquement lorsque je mets à jour les directives anticipées',
    deathReport: 'Déclarer le décès',
    executorCertificate: "Certificat d'exécuteur",
    insuranceCompany: "Compagnie d'assurance",
    insurancePolicyNumber: 'Numéro de police',
    ahvFund: 'Caisse AVS',
    ahvNumber: 'Numéro AVS',
    pensionFundOrganization: 'Organisme de caisse de pension',
    financialInstitution: 'Institution financière',
    trustedPerson: 'Exécuteur testamentaire',
    select: 'Sélectionner',
    cadenceSelect: 'Sélectionner la cadence',
    expenses_to_be_covered: 'Frais à prendre en charge',
    workload: 'Charge de travail (en %)',
    shareYouPay: 'Part que vous payez (en %)',
    afterDeath: 'Après le décès',
    access: 'Accès',
    executor: 'Exécuteur testamentaire',
    trustees_list: {
      husband_wife: 'Mari, Femme',
      partner: 'Partenaire',
      'close-friend': 'Ami proche',
      'brother-sister': 'Frère, Sœur',
      'brother-sister-in-law': 'Beau-frère, Belle-sœur',
    },
    beneficiarieCategories: {
      selectCategory: 'Sélectionner une catégorie',
      individual: 'Personne individuelle',
      company: 'Entreprise',
      foundation: 'Fondation',
      association: 'Association',
    },
    ordinalNumbers: {
      first: 'premier',
      second: 'deuxième',
      third: 'troisième',
      fourth: 'quatrième',
      fifth: 'cinquième',
      sixth: 'sixième',
      seventh: 'septième',
      eighth: 'huitième',
      ninth: 'neuvième',
      tenth: 'dixième',
      information: 'Informations sur le {{ordinalNumber}} enfant',
    },
    good_description: 'Description du bien',
    approximate_value: 'Valeur approximative',
    storage_location: 'Lieu de stockage',
    previous_owner: 'Propriétaire précédent',
    font_settings: 'Paramètres de police',
    title_font: 'Police du titre',
    subtitle_font: 'Police du sous-titre',
    label_font: "Police de l'étiquette",
    prose_font: 'Police du texte',
    link_font: 'Police du lien',
  },

  userSettings: {
    general: 'Général',
    billing: 'Facturation',
    my_family: 'Ma famille',
    successors: 'Personnes de confiance',
    notifications: 'Notifications',
    dataSharing: 'Partage de données',
    changePassword: 'Changer le mot de passe',
    changeReminder: 'Fréquence des rappels:',
    reminder: 'Notifications: Rappel pour documents en attente',
    reminderDescription:
      'Dans le cas où des documents sont en attente ou non encore complètement finalisés, nous offrons une fonction de rappel.',
    reminderFrequency: 'Fréquence des rappels',
    frequencyChoices: {
      weekly: 'Hebdomadaire',
      monthly: 'Mensuel',
      quarterly: 'Trimestriel',
      disabled: 'Désactivé',
    },
    successor: {
      title: 'Définir des personnes de confiance',
      description:
        "Pour vous assurer que vos documents et informations sont disponibles en cas de décès, vous devez vous assurer qu'au moins deux personnes ont accès à gut geregelt.",
      pleaseConfirm:
        "Veuillez confirmer que les personnes suivantes auront un accès invité à partir d'aujourd'hui (avec un accès limité - voir ce qui est inclus) et un accès complet à toutes les données en cas de décès.",
      agree: "Oui, je suis d'accord",
      addSuccessor: 'Ajouter des personnes de confiance',
      access: {
        select_label: "Droit d'accès général",
        switch_label: "Droits d'accès spécifiques aux artefacts de précaution",
        modal: {
          title: "Définir des autorisations d'accès individuelles",
        },
      },
    },

    dataSharingPage: {
      noSharingTitle: 'Partager des données',
      toggleLabel: 'Je souhaite partager mes données',
      currentlySharing: 'Accès aux données pour {{partnerName}}',
      partnerListLabel: 'Organisation partenaire',
      changedOrganisation: 'Organisation modifiée ?',
      info: 'Si vous avez activé le partage de données avec une organisation partenaire de gut geregelt, vous acceptez de partager les données suivantes avec votre conseiller bancaire :',
      partnerInfo:
        'Si vous souhaitez partager des données avec les conseillers clients de {{partnerName}}:',
      sharingName: 'Nom',
      sharingEmail: 'E-mail',
      sharingLogin: 'Dernière connexion',
      sharingServiceProgress:
        "Progrès en pourcentage des différents documents de prévoyance (aucun document ou information réel n'est partagé)",
    },
  },

  adminSettings: {
    general: 'Général',
    team: 'Équipe',
    consulting: 'Consultation',
    changePassword: 'Changer le mot de passe',
  },

  team: {
    tableHeads: {
      name: 'Nom',
      email: 'E-mail',
      role: 'Rôle',
      last_login: 'Dernière connexion',
    },
    tagChoices: {
      general: 'Général',
      pension: 'Retraite',
      mortgage: 'Hypothèque',
      asset_management: "Gestion d'actifs",
      tax: 'Conseil fiscal',
    },
    resendInvitation: "Renvoyer l'invitation",
    cancelInvitation: "Annuler l'invitation",
    consultant: 'Consultant',
    consultantDescription: 'Accès au cockpit consultant gut geregelt.',
    admin: 'Admin',
    adminDescription:
      "Gestion du cockpit gut geregelt, des personnes et d'autres paramètres.",
  },

  footer: {
    allRightsReserved: 'Tous droits réservés',
    provisionLinks: {
      title: 'Solutions',
      pricing: 'Tarifs',
      livingWill: 'Directives anticipées',
      powerOfAttorney: "Mandat pour cause d'inaptitude",
      lastWill: 'Testament',
      digitalFootprint: 'Héritage numérique',
      registerAssets: 'Registre des biens et actifs',
    },
    resourcesLinks: {
      title: 'Ressources',
      imprint: 'Mentions légales',
      termsAndConditions: "Conditions d'utilisation",
      privacyPolicy: 'Politique de confidentialité',
      contact: 'Contact',
    },
    contact: 'Contact',
  },

  contactUs: {
    getLegalSupport: 'Obtenez un soutien juridique',
    partnerTitle: "Contactez l'équipe gut geregelt",
    description:
      'gut geregelt vous met en relation avec des conseillers juridiques expérimentés dans votre région si nécessaire.',
    pleaseWriteMessage: 'Veuillez nous écrire un message',
  },

  civil_statusWarning: {
    title: 'Êtes-vous sûr de vouloir mettre à jour votre état civil ?',
    description:
      'Si vous changez votre état civil, toutes vos données testamentaires seront supprimées et vous devrez recommencer.',
    cancel: 'Annuler',
    update: 'Mettre à jour',
  },

  hyperlinks: {
    lastWill: 'Testament',
    livingWill: 'Directives anticipées',
    powerOfAttorney: "Mandat pour cause d'inaptitude",
  },
  signedDoc: {
    placeholder: {
      headingText: 'Télécharger après la signature',
      currentlySigned: 'Actuellement {{grammarFix}} {{docType}}',
      grammarFix: {
        livingWill: 'signé et actif',
        powerOfAttorney: 'signé et actif',
        lastWill: 'signé et actif',
        marriageContract: 'signé et actif',
        cohabitationAgreement: 'signé et actif',
      },
      doctype: {
        lastWill: 'testament',
        livingWill: 'directives anticipées',
        powerOfAttorney: "mandat pour cause d'inaptitude",
        marriageContract: 'contrat de mariage',
        cohabitationAgreement: 'contrat de cohabitation',
      },
      helper: {
        dropFilesHere: 'Déposez le fichier ici ou parcourez votre appareil',
        browse: 'parcourir',
        thoroughYourDevice: 'sur votre appareil',
      },
    },
    reUpload: 'Glisser-déposer ou cliquer pour télécharger à nouveau',
    updatedAt: 'Mis à jour le {{date}}',
  },
  shareDocument: {
    button: 'Partager avec le médecin',
    title: 'Partagez-le avec votre médecin de famille',
    description:
      'Nous vous recommandons de partager la version signée de vos directives anticipées avec votre médecin de famille.',
    doAutomatically:
      'Partager automatiquement lorsque je mets à jour les directives anticipées',
  },
  finfinder: {
    title: 'Choisissez votre conseiller financier sur FinFinder',
    button: 'Organiser une première consultation gratuite',
  },

  consulting: {
    title: 'Consultation',
    description:
      'Accompagnez vos clients dans la planification de leur retraite.',
    dashCardInfos: {
      livingWill: {
        title: 'Directives anticipées',
        description:
          "Règle les traitements médicaux auxquels le client consent ou qu'il refuse, s'il ne peut plus décider lui-même.",
      },
      powerOfAttorney: {
        title: "Mandat pour cause d'inaptitude",
        description:
          'Définit qui représente légalement le client dans le pire des cas, prend en charge ses droits et devoirs envers la famille et gère ses affaires financières.',
      },
      lastWill: {
        title: 'Testament',
        description:
          'Définit les souhaits du client concernant la transmission des biens.',
      },
      ageAppropriate: {
        title: "Investissement 3a adapté à l'âge",
        description:
          'Vérifiez dans quelle mesure votre client investit ses actifs 3a de manière adaptée à son âge et prenez les mesures appropriées si nécessaire.',
      },
      ahvCalculator: {
        title: 'Calculateur AVS',
        description:
          'Le calculateur AVS est une estimation de la rente basée sur une méthode de calcul simplifiée, non contraignante.',
      },
      lifeExpectancy: {
        title: 'Espérance de vie',
        description:
          "Calcule l'espérance de vie théorique du client à partir de tables actuarielles.",
      },
      getStarted: 'Commencer',
      unlock: 'Déverrouiller',
      createUser: 'Créer un utilisateur',
      createUserDescription:
        "Créez un utilisateur pour votre client, qu'il pourra continuer à utiliser indépendamment de vous pour consulter des documents et effectuer des ajustements.",
    },
    pageTitle: 'Créer un login client',
    createUserForm: {
      description:
        'Votre client recevra un e-mail séparé avec les informations de connexion. Demandez à votre client de changer le mot de passe communiqué lors de la première connexion.',
    },
  },
  consultantDashboard: {
    pageName: 'Tableau de bord du consultant',
    title: "Vue d'ensemble des clients",
    description:
      'Obtenez un aperçu de votre portefeuille de clients et de leur statut en matière de prévoyance avec gut geregelt.',
  },

  teamRoles: {
    owner: 'Propriétaire',
    consultant: 'Consultant',
    admin: 'Admin',
  },

  financialAdvisorsModal: {
    title: 'Parlez à un conseiller financier près de chez vous',
    description:
      'Pour vous offrir un accès facile à des experts financiers indépendants près de chez vous, nous collaborons avec FinFinder.',
  },
  legalAdvisorsModal: {
    title: 'Parlez à un notaire près de chez vous',
    description:
      'Pour vous offrir un accès facile à des experts juridiques près de chez vous, nous collaborons avec GetYouLawyer.',
  },

  complexFormModal: {
    title: 'Nous vous recommandons de parler à un notaire',
    description:
      'Votre cas est plus complexe et nous vous recommandons vivement de parler à un expert juridique pour clarifier la situation et élaborer la meilleure solution pour votre cas.',
    searchLabel: 'Rechercher un notaire',
    financialSearchLabel: 'Rechercher un conseiller financier',
    continueLabel: 'Continuer',
    requestConsultation: 'Demander une consultation',
  },

  financialConsultingTopics: {
    financialPlanning: 'Planification financière',
    pensionPlanning: 'Planification de la retraite',
    taxPlanning: 'Conseil fiscal',
    realEstate: 'Conseil immobilier',
    successionPlanning: 'Conseil en succession',
  },

  legalConsultingTopics: {
    livingWill: 'Directives anticipées',
    powerOfAttorney: "Mandat pour cause d'inaptitude",
    lastWill: 'Testament',
    marriageContract: 'Contrat de mariage',
  },

  markAsDoneView: {
    intro: 'Votre {{service}} efficace',
    title: 'Déposez votre {{service}} juridiquement valide',
    helperOne:
      'gut geregelt vous informe des changements de la législation et vous donne de temps en temps des impulsions pour que le document juridique en vigueur corresponde toujours à vos besoins et souhaits.',
    helperTwo:
      'Si vous souhaitez une autre disposition à un moment ultérieur, vous pouvez mettre à jour le document juridique à tout moment et régler vos affaires via "gut geregelt".',
    service: {
      livingWill: 'Directives anticipées',
      powerOfAttorney: "Mandat pour cause d'inaptitude",
      lastWill: 'Testament',
      marriageContract: 'Contrat de mariage',
      cohabitationAgreement: 'Contrat de cohabitation',
    },
  },

  otp: {
    pageTitle: "Vérification de l'e-mail",
    title: 'Code de confirmation envoyé à votre e-mail',
    subtitle:
      'Nous vous avons envoyé un code de confirmation à 6 chiffres par e-mail à {{email}}. Veuillez entrer le code dans le champ ci-dessous pour confirmer votre e-mail.',
    dontHaveCode: "Vous n'avez pas reçu de code ?",
    resendCode: 'Renvoyer le code',
    returnToLogin: 'Retour à la connexion',
    waitforResend: 'Veuillez patienter avant de demander à nouveau le code.',
  },

  paxDisclaimer: {
    text: "Pax vous permet d'utiliser gratuitement les services de gut geregelt AG pour créer divers documents. Les données que vous saisissez sur les pages web suivantes ne seront pas traitées par Pax, mais par gut geregelt AG. Pax n'a pas accès à ces données.",
    privacyLink: 'Lisez la politique de confidentialité de gut geregelt AG',
    accept: 'Compris',
  },
};

export default fr;
