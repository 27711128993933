const partnerWithUs = {
  mainBanner: {
    title: 'Offri accesso a soluzioni previdenziali digitali',
    subtitle:
      'Fondi pensione, banche, compagnie assicurative, associazioni e partner mediatici collaborano con gut geregelt per offrire alle persone un accesso semplice a una previdenza completa.',
    buttonLabel: 'Parla con noi',
  },
  youtubeTutorial: 'Perché collaborare con noi?',
  aboutCards: {
    differentiate: {
      title: 'Raggiungere la differenziazione',
      description:
        'Genera più traffico per la tua piattaforma con servizi aggiuntivi e contenuti rilevanti.',
    },
    efficiency: {
      title: 'Maggiore efficienza',
      description:
        'Con servizi aggiuntivi puoi generare facilmente più entrate riducendo al contempo i costi.',
    },
    simplyIntegrated: {
      title: 'Integrazione semplice',
      description:
        "Pronto all'uso con la semplice soluzione cloud autonoma nel tuo design.",
    },
  },
  subBanner: {
    title: 'Come soluzione self-service o consulenza professionale',
    subtitle:
      "gut geregelt può essere utilizzato come soluzione self-service in white label per i clienti finali o come soluzione di consulenza per supportare il tuo processo di consulenza. È possibile anche un'integrazione API in una soluzione esistente.",
  },
  talkWithUs: {
    title: 'Aiuta le persone a vivere una vita ben regolata',
    description:
      'Espandi la tua offerta di servizi e fornisci servizi previdenziali significativi per le persone che vogliono semplificare la loro vita e avere un impatto positivo sulle loro famiglie.',
    talkAboutPossibilities: 'Parliamo delle possibilità',
    iam: 'Io sono',
    partnerOccupations: {
      bank: 'Banca',
      insurance: 'Assicurazione',
      pensionFund: 'Fondo pensione',
      association: 'Associazione',
      media: 'Partner mediatico',
      trust_foundation: 'Fondazione',
      other: 'Altro',
    },
  },
  solutions: {
    pensionFund: {
      title: 'Fondo pensione',
      content:
        "Da tempo cerchiamo di aumentare il numero di visitatori sul nostro portale assicurativo. Tuttavia, oltre ai certificati assicurativi annuali, ci sono pochi buoni motivi. Con gut geregelt, ampliamo le possibilità di previdenza e aumentiamo contemporaneamente il traffico con un'offerta innovativa.",
    },
    bank: {
      title: 'Banca',
      content:
        'Sebbene le banche si siano concentrate in passato sulla fornitura finanziaria ai clienti, riteniamo che come banca dovremmo anche esplorare nuovi modi per aiutare i clienti a vivere una vita ben regolata. Con uno strumento come gut geregelt, questo è possibile.',
    },
    fintechPlatform: {
      title: 'Piattaforma FinTech',
      content:
        "Come soluzione, siamo già molto vicini al cliente nella previdenza finanziaria. L'integrazione della nostra offerta con soluzioni previdenziali estese e legalmente sicure è un passo logico per consentire ai clienti una previdenza completa.",
    },
  },
  faq: {
    questions: {
      question1: 'Quali vantaggi offre gut geregelt ai miei clienti?',
      question2: 'Dove sono conservati i dati?',
      question3: "gut geregelt è conforme al DSG e al GDPR dell'UE?",
      question4: 'Posso utilizzare gut geregelt come soluzione di consulenza?',
      question5: 'gut geregelt è disponibile come soluzione white label?',
      question6: 'Come posso integrare gut geregelt nella mia azienda?',
      question7: 'Quali lingue sono supportate?',
      question8: 'Posso coinvolgere anche le mie organizzazioni partner?',
      question9:
        'Quali vantaggi offre gut geregelt come membro del consiglio di fondazione?',
      question10: 'Posso richiedere funzionalità aggiuntive?',
      question11: 'Qual è il modello commerciale?',
    },
    answers: {
      answer1:
        'Con gut geregelt, le persone ottengono per la prima volta un accesso semplice a servizi previdenziali digitali con sicurezza legale. Ti supportiamo nei primi passi verso una vita ben regolata. In questo modo, aiutiamo loro e le loro famiglie a ottenere più controllo e meno stress in caso di emergenza.',
      answer2:
        'I dati sono conservati in Svizzera. Per le organizzazioni partner in Germania e Austria, prevediamo una zona di conformità UE.',
      answer3:
        "Sì. I requisiti del DSG e del GDPR dell'UE sono stati utilizzati come requisiti di progettazione nell'implementazione di gut geregelt come software e costituiscono quindi la base dell'architettura del prodotto.",
      answer4:
        'Sì, gut geregelt offre oltre alla soluzione self-service per i clienti finali anche un cockpit per consulenti. Questo consente ai tuoi consulenti di sviluppare soluzioni insieme ai clienti, con la possibilità per il cliente di apportare modifiche o integrazioni in un secondo momento.',
      answer5:
        'Sì. gut geregelt è una soluzione white label dedicata per organizzazioni come banche, assicurazioni, fondi pensione e altre istituzioni previdenziali, nonché istituzioni finanziarie e associazioni. La soluzione può essere integrata in modi diversi.',
      answer6:
        "Sono possibili diversi scenari. L'integrazione più semplice avviene tramite un semplice link a gut geregelt. Tuttavia, offriamo anche integrazioni tramite SSO e API. È anche possibile l'integrazione come iFrame.",
      answer7: 'gut geregelt supporta tedesco, inglese, francese e italiano.',
      answer8:
        'gut geregelt ha diversi modelli di partnership e ti offre, ad esempio, la possibilità di elencare notai e avvocati, nonché consulenti finanziari e patrimoniali come esperti.',
      answer9:
        "Con gut geregelt, come membro del consiglio di fondazione, posso attivamente ridurre il mio rischio di responsabilità, incoraggiando gli assicurati a pianificare la previdenza, fornendo uno strumento per aumentare il traffico sul portale dell'istituzione previdenziale e creando sempre più trasparenza ed efficienza.",
      answer10:
        'Sì. In gut geregelt ascoltiamo molto ciò che dicono i nostri clienti e partner. Esaminiamo i desideri e le esigenze coinvolgendo tutti e diamo priorità a quelle funzionalità che offrono un valore aggiunto per la maggior parte dei nostri clienti e partner.',
      answer11:
        'I partner pagano una tariffa annuale per la soluzione gut geregelt. Da ogni contratto concluso dai clienti finali, il partner riceve un rimborso. Tuttavia, ci sono anche partner che coprono parzialmente o completamente i costi per i loro clienti finali e offrono il servizio a un prezzo ridotto.',
    },
  },
};

export default partnerWithUs;
