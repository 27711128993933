import AOS from 'aos';
import 'aos/dist/aos.css';
import './utils/fontCss.css';

import { useEffect, useRef, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import ScrollToTop from '#/components/shared/ui/ScrollToTop';
import Router from './routes';
import ThemeProvider from './theme';
import loadScript from '#/utils/loadScript';
import useLocales from '#/hooks/useLocales';
import { PartnerProvider } from './context/PartnerContext';
import PartnerSwitcher from '#/components/shared/ui/PartnerSwitcher';
import useLocalStorage from './lib/hooks/useLocalStorage';
import PaxDisclaimerModal from './components/shared/PaxDisclaimerModal';

type Locale = {
  [key: string]: any;
};

type LanguageMappings = {
  [key: string]: Locale;
};

function App() {
  const loaded = useRef(false);
  const location = useLocation();

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${
          import.meta.env.VITE_GOOGLE_PLACES_API
        }&callback=Function.prototype&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const { currentLang } = useLocales();
  const languageMappings: LanguageMappings = {
    de,
    fr,
    en,
    it,
  };

  const locale = languageMappings[currentLang.value] || de;

  useEffect(() => {
    AOS.init();
  }, []);

  ReactGA.initialize('G-RVDR0M7Q7Y');

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  const showDevTools =
    import.meta.env.DEV ||
    import.meta.env.MODE === 'development' ||
    import.meta.env.VITE_ENV === 'development' ||
    window.location.hostname === 'localhost';

  return (
    <PartnerProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <ThemeProvider>
          <ScrollToTop />
          <Router />
          {showDevTools && <PartnerSwitcher />}
        </ThemeProvider>
      </LocalizationProvider>
    </PartnerProvider>
  );
}
export default App;
