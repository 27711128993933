const pathnames = {
  auth: {
    index: 'auth',
    login: 'login',
    verify: 'verifizieren',
    resetPassword: 'reset-passwort',
    passwordResetConfirm: 'passwort-reset-bestaetigen',
    partnerOnboarding: 'partner-onboarding',
  },
  dashboard: {
    index: 'dashboard',
    home: 'home',
    guestOn: 'gast-bei',
    guestOnDocumentHistory: 'gast-bei/:guestId/dokumentverlauf',
    contact: 'kontakt',
    livingWill: 'patientenverfuegung',
    powerAttorney: 'vorsorgeauftrag',
    lastWill: 'testament',
    marriageContract: 'ehevertrag',
    assetsRegister: 'vermoegens-und-sachregister',
    cohabitationAgreement: 'konkubinatsvertrag',
    digitalLegacy: {
      index: 'digitaler-nachlass',
      importantInformation: 'wichtige-informationen',
      digitalAssets: 'digital-vermoegen',
      photosVideos: 'fotos-videos',
    },
    taxFolderClient: 'steuerordner',
    chat: 'chat',
    thankyouCard: 'vorsorge-final',
    consultant: 'berater',
    consulting: 'beratung',
    createUser: 'beratung/user-erstellen',
    user: {
      index: 'user',
      account: 'account',
    },
  },
  compact: {
    forbidden: '403',
    notFound: '404',
    error: '500',
    maintenance: 'maintenance',
    comingSoon: 'coming-soon',
    contact: 'kontakt',
    pension: 'vorsorge',
    register: 'registrieren',
    onboardingStepOne: 'vorsorge/schritt=1',
    onboardingStepTwo: 'vorsorge/schritt=2',
    onboardingStepThree: 'vorsorge/schritt=3',
    acceptInvitation: 'vertrauensperson/einladung',
    familyInvitation: 'familie/einladung',
    partnerInvitation: 'partner/einladung',
  },
  main: {
    index: '/',
    situationCheck: 'lebenssituations-check',
    grosselternMagazin: 'deals/grosseltern-magazin',
    preventiveCheckUp: 'vorsorgecheck',
    situationLanding: 'vorsorge',
    aboutUs: 'ueber-uns',
    imprint: 'impressum',
    privacyPolicy: 'datenschutzbestimmungen',
    agb: 'agb',
  },
};

export default pathnames;
