import { useForm } from 'react-hook-form';
import { Button, Card, Stack, Typography } from '@mui/material';
import {
  useNavigate,
  Link,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import useAuth from '../../../lib/hooks/useAuth';
import { FormProvider, RHFTextField } from '../../shared/hook-form';
import { PATH_AFTER_LOGIN } from '#/config';
import useLocales from '#/hooks/useLocales';
import useLocalStorage from '#/hooks/useLocalStorage';
import Iconify from '#/components/shared/ui/Iconify';

const LoginForm = () => {
  const { login, isLoading } = useAuth();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { pathname, state } = useLocation();
  const [searchParams] = useSearchParams();
  const [, setValueInLocalStorage] = useLocalStorage('showModal', false);

  const redirectLink = searchParams.get('redirectLink');
  const isChangePassword = searchParams.get('active_tab') === 'change_password';

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(String(translate('validations.user.email')))
      .required(String(translate('validations.user.email'))),
    password: Yup.string().required(
      String(translate('validations.user.password'))
    ),
  });

  const defaultValues = {
    email: import.meta.env.VITE_LOGIN_EMAIL || '',
    password: import.meta.env.VITE_LOGIN_PASSWORD || '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const handleBack = () => {
    navigate(-1);
  };

  const onSubmit = (data: any) => {
    const toastId = toast.loading('Signin in...');

    const body = {
      email: data.email.toLowerCase(),
      password: data.password,
    };

    const serviceRequest = redirectLink
      ? `/dashboard/${redirectLink}`
      : undefined;

    const path_after_login = serviceRequest || PATH_AFTER_LOGIN;

    login(body);
    if (state?.modal) setValueInLocalStorage(true);
    let navigationPath = pathname;
    if (pathname === '/auth/login') {
      navigationPath = path_after_login;
    } else if (isChangePassword) {
      navigationPath = '/dashboard/user-account?active_tab=change_password';
    }
    navigate(navigationPath);
    toast.dismiss(toastId);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card
        sx={{
          padding: '2rem',
          borderRadius: '8px',
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
        }}
      >
        <Button
          startIcon={<Iconify icon="mdi:arrow-left" />}
          onClick={handleBack}
          sx={{ mb: 2 }}
        >
          <Typography variant="body1">
            {String(translate('global.back'))}
          </Typography>
        </Button>
        <Typography variant="h5" gutterBottom>
          {String(translate('global.loginInfo.login'))}
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            marginY: 3,
          }}
        >
          {String(translate('global.loginInfo.newUser'))}&nbsp;
          <Link to="/vorsorge">
            {String(translate('global.loginInfo.createAccount'))}
          </Link>
        </Typography>
        <Stack spacing={3}>
          <RHFTextField
            name="email"
            label={String(translate('global.formLabels.emailAddress'))}
          />
          <RHFTextField
            name="password"
            autoComplete="password"
            label={String(translate('global.formLabels.password'))}
            type="password"
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Link to="/auth/reset-passwort">
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                my: 2,
                mb: 4,
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              {String(translate('global.loginInfo.forgotPassword'))}
            </Typography>
          </Link>
        </Stack>
        <LoadingButton
          aria-label={String(translate('global.loginInfo.login'))}
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          sx={{ borderRadius: '8px' }}
          loading={isLoading}
        >
          <Typography variant="body1">
            {String(translate('global.loginInfo.login'))}
          </Typography>
        </LoadingButton>
      </Card>
    </FormProvider>
  );
};

export default LoginForm;
