const cohabitationAgreementLanding = {
  mainBanner: {
    intro: '',
    title: 'Regeln Sie Ihre Beziehung rechtlich mit einem Konkubinatsvertrag',
    subtitle:
      'Viele Aspekte der sozialen und rechtlichen Absicherung bei unverheirateten Lebensgemeinschaften sind gesetzlich nicht geregelt. Mit einem Konkubinatsvertrag profitieren Sie von den steuerlichen Vorteilen und klären viele ungeklärte Fragen.',
    buttonLabel: 'Jetzt kostenlos erstellen',
  },
  youtubeTutorial: 'Was ist ein Konkubinatsvertrag?',
  aboutCards: {
    proactiveMeasures: {
      title: 'Proaktive Massnahme',
      description:
        'Schützen Sie sich und Ihren Partner vor ungelösten Problemen und Themen in der unverheirateten Lebensgemeinschaft.',
    },
    defineWishes: {
      title: 'Definieren Sie Ihre Wünsche',
      description:
        'Nutzen Sie den Gestaltungsspielraum bei Trennung und Todesfall.',
    },
    benefitMaximally: {
      title: 'Maximal profitieren',
      description:
        'Profitieren Sie von den Vorteilen eines sicheren Zusammenlebens - persönlich, finanziell und steuerlich.',
    },
  },
  subBanner: {
    title: 'Gut geregelt mit einem rechtskonformen Konkubinatsvertrag',
    subtitle:
      'Mit gut geregelt können Sie Ihren Konkubinatsvertrag schnell und einfach erstellen, jederzeit anpassen – ebenso das zugehörige Inventar – und so sensible Themen oder Fragen rechtlich klären, um sich gegenseitig abzusichern und von der Lebensgemeinschaft zu profitieren.',
    buttonLabel: 'Jetzt kostenlos erstellen',
  },
  newsletter: {
    title: 'Werden Sie gut geregelt mit Ihrem Konkubinatsvertrag',
    buttonLabel: 'Jetzt kostenlos erstellen',
  },
  faq: {
    questions: {
      question1: 'Was ist ein Konkubinatsvertrag?',
      question2: 'Welche Vorteile bietet das Konkubinat gegenüber der Ehe?',
      question3: 'Wo schützt das Konkubinat nicht gleichermassen wie die Ehe?',
      question4: 'Wie schützt das Konkubinat den Nachlass bzw. mein Erbe?',
      question5:
        'Hab ich Anspruch auf die Sozialleistungen von meinem Lebenspartner?',
      question6: 'Braucht ein Konkubinatsvertrag eine notarielle Beglaubigung?',
      question7:
        'Für welche Aspekte sieht das Recht mit dem Konkubinat Absicherungen vor?',
      question8:
        'Kann man einen Konkubinatsvertrag jederzeit ändern oder auflösen?',
      question9: 'Was passiert mit dem Konkubinatsvertrag, wenn man heiratet?',
      question10: 'Wie regelt man den Unterhalt im Falle einer Trennung?',
    },
    answers: {
      answer1:
        'Der Konkubinatsvertrag ist ein privatrechtlicher Vertrag zwischen zwei Personen, die in einer eheähnlichen Gemeinschaft leben, jedoch ohne verheiratet zu sein. Der Vertrag regelt die Rechte und Pflichten der Partner während der gemeinsamen Lebensgemeinschaft und im Falle einer Trennung.',
      answer2: '',
      answer3: '',
      answer4:
        'Das Konkubinat bietet keinen gesetzlichen Erbschutz für den Partner. Der Nachlass muss durch ein Testament oder einen Erbvertrag geregelt werden, um den Lebenspartner abzusichern. Andernfalls hat der Konkubinatspartner kein Anrecht auf das Erbe.',
      answer5:
        'Nein, im Konkubinat besteht grundsätzlich kein Anspruch auf die Sozialleistungen des Lebenspartners, wie z.B. Witwen- oder Witwerrente. Sozialleistungen wie Krankenversicherung oder Rentenansprüche sind nur innerhalb einer Ehe abgesichert, es sei denn, es gibt spezielle vertragliche Vereinbarungen. Einige Vorsorgeeinrichtungen bieten jedoch die Möglichkeit, den Lebenspartner zu melden und Ansprüche im Todesfall geltend zu machen.',
      answer6:
        'Ein Konkubinatsvertrag muss nicht zwingend notariell beglaubigt werden. Bei komplexeren Regelungen, insbesondere bezüglich Immobilien oder Erbfragen, kann eine notarielle Beglaubigung sinnvoll sein. Für welche Aspekte sieht das Recht mit dem Konkubinat Absicherungen vor?',
      answer7:
        'Ja, ein Konkubinatsvertrag kann jederzeit einvernehmlich von beiden Parteien geändert oder aufgelöst werden. Dies sollte schriftlich festgehalten werden, um spätere Streitigkeiten zu vermeiden.',
      answer8:
        'Mit der Eheschliessung wird der Konkubinatsvertrag hinfällig, da die Ehe durch das Zivilgesetzbuch (ZGB) umfassend geregelt ist. Alle im Konkubinatsvertrag festgehaltenen Regelungen werden durch das Eherecht ersetzt.',
      answer9:
        'Da es im Konkubinat keinen gesetzlichen Unterhaltsanspruch gibt, müssen die Partner dies selbst regeln. Es empfiehlt sich, entsprechende Vereinbarungen im Konkubinatsvertrag festzuhalten. Sollte keine Regelung getroffen worden sein, kann es bei einer Trennung zu rechtlichen Auseinandersetzungen kommen, bei denen die allgemeine Vertragspraxis Anwendung findet.',
    },
    lists: {
      answer1: {
        title:
          'Unterschied zwischen Lebenspartner und Ehegatten (Konkubinat versus Ehepaar)',
        items: [
          {
            description:
              'Rechtsstatus: Ehegatten sind rechtlich durch das Zivilgesetzbuch (ZGB) geschützt, während Lebenspartner im Konkubinat keine speziellen gesetzlichen Rechte und Pflichten haben.',
          },
          {
            description:
              'Unterhaltsanspruch: Ehegatten haben gegenseitige Unterhaltsansprüche, Lebenspartner im Konkubinat hingegen nicht automatisch.',
          },
          {
            description:
              'Erbrecht: Ehegatten sind gesetzlich erbberechtigt, Lebenspartner hingegen nicht, sofern nicht durch ein Testament oder Erbvertrag anders festgelegt.',
          },
          {
            description:
              'Steuerrecht: Ehegatten werden gemeinsam besteuert, währenddem Lebenspartner nach wie vor getrennt besteuert werden (dadurch ergibt sich ein Steuervorteil für Konkubinatspartner).',
          },
          {
            description:
              'Sozialversicherungen: Ehegatten haben Anspruch auf bestimmte Sozialleistungen wie Witwen- oder Witwerrente; Konkubinatspartner haben diesen Anspruch nicht bzw. nicht automatisch. Je nach Vorsorgeeinrichtung können Ansprüche jedoch auf Meldung des Lebenspartners sichergestellt werden. Auch die AHV-Rente wird bei Konkubinatspartnern höher ausfallen als bei Ehegatten.',
          },
        ],
      },
      answer2: {
        title: '',
        items: [
          {
            description:
              'Flexibilität: Konkubinatspartner können ihre Beziehung und Lebensgestaltung freier und flexibler gestalten, ohne die formalen Anforderungen einer Ehe.',
          },
          {
            description:
              'Keine Scheidung: Da das Konkubinat keine rechtlich bindende Ehe ist, gibt es keine aufwendigen Scheidungsverfahren bei einer Trennung.',
          },
          {
            description:
              'Steuern: In einigen Fällen kann das Konkubinat steuerliche Vorteile bieten, da die Partner getrennt veranlagt werden.',
          },
        ],
      },
      answer3: {
        title: '',
        items: [
          {
            description:
              'Erbrecht: Lebenspartner haben im Konkubinat kein gesetzliches Erbrecht.',
          },
          {
            description:
              'Unterhalt: Es besteht kein gesetzlicher Unterhaltsanspruch nach einer Trennung.',
          },
          {
            description:
              'Sozialversicherungen: Lebenspartner sind nicht automatisch sozialversichert, z.B. bezüglich Witwen- oder Witwerrente.',
          },
          {
            description:
              'Vermögensaufteilung: Es gibt keine gesetzliche Regelung zur Aufteilung des Vermögens im Falle einer Trennung.',
          },
        ],
      },
      answer6: {
        title:
          'Das schweizerische Recht sieht Wirkungen für folgende Themenfelder vor:',
        items: [
          {
            description: 'Beistandschaft',
          },
          {
            description: 'Unterhalt',
          },
          {
            description: 'Vertretung gegenüber Dritten',
          },
          {
            description: 'Vermögen',
          },
          {
            description: 'Verhältnisse zu den Kindern',
          },
        ],
      },
    },
  },
};

export default cohabitationAgreementLanding;
