const marriageContractLanding = {
  mainBanner: {
    intro: 'Créer un contrat de mariage en ligne :',
    title: 'Légalement sûr, en 10 minutes',
    subtitle:
      "Le contrat de mariage vous aide, en tant qu'époux, à protéger votre patrimoine et à éviter les conflits financiers pendant ou après le mariage.",
    buttonLabel: 'Créer un contrat de mariage',
  },
  youtubeTutorial: 'Pourquoi un contrat de mariage est-il important ?',
  aboutCards: {
    protectSpouse: {
      title: 'Protéger le conjoint',
      description:
        "Le contrat de mariage vous protège, de sorte que vous n'ayez pas à vendre la propriété en cas de décès pour régler la succession des enfants.",
    },
    winClarity: {
      title: 'Gagner en clarté',
      description:
        "Définissez clairement à l'avance comment le patrimoine sera réparti et les finances gérées en cas de décès.",
    },
    saveDiscussions: {
      title: 'Éviter les conflits',
      description:
        'Créez de la clarté en formalisant la gestion financière en cas de succession pour éviter les conflits.',
    },
  },
  subBanner: {
    title: 'Bien organisé avec un contrat de mariage conforme à la loi',
    subtitle:
      'Avec gut geregelt, vous créez rapidement votre contrat de mariage, pouvez obtenir des conseils juridiques sur des sujets sensibles ou des questions ouvertes et vous protégez, vous et votre conjoint. Créez-le maintenant et faites-le certifier par un notaire.',
    buttonLabel: 'Créer gratuitement maintenant',
  },
  testimonials: {
    testimonial1: {
      name: 'Daniel Schreiber',
      text: "Ma femme et moi avons deux enfants et avons acheté une propriété il y a un an. Avec le contrat de mariage, nous nous assurons que ma femme n'aura pas à vendre la maison en cas de mon décès pour payer nos enfants. C'était très simple.",
    },
    testimonial2: {
      name: 'Greta Beck',
      text: "Mon mari et moi avons essayé 2-3 fois de nous lancer et de nous occuper du contrat de mariage. gut geregelt ne nous a pas complètement épargné le chemin vers l'avocat, mais a rendu l'entrée dans le sujet beaucoup plus facile.",
    },
    testimonial3: {
      name: 'Sarah Barmettler',
      text: "Processus simple et compréhensible. Nous nous sommes renseignés à l'avance sur Internet et savions ce que nous voulions. L'offre de gut geregelt a parfaitement convenu. Pour la certification, nous sommes allés chez le notaire du village.",
    },
  },
  newsletter: {
    title: 'Soyez bien organisé avec votre contrat de mariage',
    buttonLabel: 'Créer maintenant',
  },
  faq: {
    questions: {
      question1: "Qu'est-ce qu'un contrat de mariage ?",
      question2: 'Pourquoi conclure un contrat de mariage ?',
      question3: 'Quand un contrat de mariage doit-il être conclu ?',
      question4: 'Comment un contrat de mariage est-il conclu en Suisse ?',
      question5: 'Un contrat de mariage peut-il être modifié ultérieurement ?',
      question6: "Que se passe-t-il si aucun contrat de mariage n'est conclu ?",
    },
    answers: {
      answer1:
        'Un contrat de mariage est un document juridiquement contraignant conclu par les époux avant ou pendant le mariage. Il régit les relations patrimoniales des époux et peut contenir des accords individuels sur le partage des biens, la responsabilité des dettes et la pension alimentaire en cas de divorce ou de décès.',
      answer2:
        'Un contrat de mariage offre la possibilité de régler clairement et individuellement les affaires financières des époux. Cela peut aider à éviter les malentendus et les conflits et à avoir des règles claires en cas de divorce ou de décès. Un contrat de mariage peut être particulièrement utile en cas de différences de patrimoine ou de revenus entre les époux, de participations dans des entreprises ou de familles recomposées.',
      answer3:
        'Un contrat de mariage peut être conclu à tout moment avant ou pendant le mariage. Cependant, il est conseillé de conclure le contrat avant le mariage pour établir des relations claires dès le départ.',
      answer4:
        "En Suisse, un contrat de mariage doit être conclu sous forme authentique. Cela signifie qu'il doit être examiné et authentifié par un notaire. Les deux époux doivent être présents en personne et signer le contrat.",
      answer5:
        "Oui, un contrat de mariage peut être modifié ou annulé à tout moment d'un commun accord entre les deux époux. Une authentification publique par un notaire est également requise pour cela.",
      answer6:
        "Sans contrat de mariage, le régime ordinaire de la participation aux acquêts s'applique en Suisse. Cela signifie que les biens acquis pendant le mariage sont partagés à parts égales entre les époux en cas de divorce, tandis que les biens acquis avant le mariage et les héritages sont considérés comme des biens propres et ne sont pas pris en compte lors du partage.",
    },
  },
  situations: {
    heirat: {
      content: {
        main: {
          title: 'Contrat de mariage',
          description:
            'Un contrat de mariage régit la manière dont vos biens seront partagés pendant le mariage et en cas de séparation ou de divorce. Cela crée des conditions claires et évite les incertitudes.',
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              'Le meilleur moment pour un contrat de mariage est juste après le mariage. Cela garantit que les deux partenaires sont traités équitablement si votre situation de vie change.',
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Partage clair des biens',
              'Protection des deux partenaires',
              'Prévention des conflits',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'En savoir plus',
        startProcess: 'Démarrer le processus',
      },
    },
    immobilienkauf: {
      content: {
        main: {
          title: 'Contrat de mariage',
          description:
            'Un contrat de mariage régit le partage des biens, en particulier si vous achetez un bien immobilier ensemble. Cela protège les deux partenaires et garantit que tout est clairement réglementé.',
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              "Lors de l'achat d'un bien immobilier, la question du partage des biens se pose. Un contrat de mariage apporte de la clarté et garantit que vous et votre partenaire êtes protégés en cas de séparation ou d'événement imprévu.",
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Réglementation claire des biens pour les biens communs',
              'Protection des deux partenaires en cas de séparation ou de décès',
              'Prévention des litiges ultérieurs',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'En savoir plus',
        startProcess: 'Démarrer le processus',
      },
    },
    'geburt-eines-kindes': {
      content: {
        main: {
          title: 'Contrat de mariage',
          description:
            'Avec un contrat de mariage, vous créez des règlements clairs concernant les biens et la sécurité de votre enfant. Cela assure une protection si votre situation de vie change.',
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              "Maintenant qu'un enfant fait partie de votre famille, un contrat de mariage offre une protection supplémentaire pour les biens de votre enfant. Il crée des conditions claires pour la répartition des biens et l'entretien de votre enfant.",
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              "Protection des biens de l'enfant",
              'Règlements clairs en cas de séparation',
              'Prévention des litiges',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'En savoir plus',
        startProcess: 'Démarrer le processus',
      },
    },
  },
};

export default marriageContractLanding;
