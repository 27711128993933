import { IQuestion } from '#/types/globalTypes';

export const financialInformationQuestions: IQuestion[] = [
  {
    question: 'Documenti bancari',
  },
  {
    question: 'Polizze assicurative',
  },
  {
    question: 'Pensione statale (AHV)',
  },
  {
    question: 'Cassa pensione',
  },
  {
    question: '3a',
  },
];

export const trustedPersonQuestions: IQuestion[] = [
  {
    question: 'Esecutore testamentario',
  },
  {
    question: 'Notaio/Fiduciario',
  },
];
