const digitalAssets = {
  introHead: "Assurez-vous d'avoir une empreinte numérique propre",
  introDescription:
    'Assurez-vous que vos descendants ont accès et définissez qui hérite de quoi.',
  passwordWarning:
    'Ne partagez jamais de mots de passe directement avec bien organisé !',
  passwordWarningTooltip:
    'Ne donnez jamais de mots de passe à bien organisé. Indiquez seulement où les proches peuvent trouver un aperçu de vos mots de passe.',
  downloadTemplate:
    'Télécharger le modèle pour un stockage sécurisé des mots de passe',
  requestOtherPasswordManager:
    "Demander d'autres gestionnaires de mots de passe",
  addItems: 'Ajouter des éléments manuellement',
  downloadList: 'Télécharger la liste',
  addItem: 'Ajouter un élément',
  yourPhoneNumber: 'Votre numéro de téléphone',

  afterDeathOptions: {
    delete: 'Supprimer',
    transfer: 'Transférer',
    inherit: 'Hériter',
  },
  assetTypes: {
    email: 'E-mail',
    social_media_account: 'Réseaux sociaux',
    membership: 'Adhésion',
    subscription: 'Abonnement',
    domain: 'Domaine',
    royalty: 'Revenus de droits',
    crypto_wallet: 'Portefeuille crypto',
    device: 'Appareil',
    password_manager: 'Gestionnaire de mots de passe',
    phone_provider: 'Fournisseur de téléphonie',
    other: 'Autre',
  },

  assetsTable: {
    tableHeads: {
      name: 'Nom',
      access: 'Accès',
      type: 'Type',
      after_death: 'Après la mort',
      beneficiary: 'Bénéficiaire',
    },
  },

  confirmDeleteModal: {
    title:
      'Êtes-vous sûr de vouloir supprimer {{asset}} de vos actifs numériques ?',
    description: 'Vous pouvez le rajouter à tout moment plus tard.',
    onClose: 'Fermer',
    onConfirm: 'Supprimer',
  },
};

export default digitalAssets;
