const outputDoc = {
  title: 'Testamento',
  from: 'da',
  born: ' nato il ',
  civilStatus: {
    single: 'Celibe/Nubile',
    married: 'Sposato/a',
    widowed: 'Vedovo/a',
    divorced: 'Divorziato/a',
    registered_partnership: 'Partenariato registrato',
  },
  civil_status: 'Stato civile: {{civil_status}}',
  address: 'Indirizzo: {{address}}',
  declare: 'Io, {{first_name}} {{last_name}}, dichiaro quanto segue:  ',

  paragraph1: 'Revoco tutti i testamenti che ho redatto in precedenza.',

  paragraph2:
    "Le passività correnti e i costi del decesso devono essere pagati dall'eredità.",

  paragraph3: '{{civil_status}}',

  receivesNothing: 'non riceve nulla.',

  and: 'e',

  p4MarriedSpouse: {
    receives: 'riceve',
    marriedStatus: {
      male: 'Mia moglie',
      female: 'Mio marito',
    },
    livingPartnershipStatus: {
      male: 'La mia partner registrata',
      female: 'Il mio partner registrato',
    },
    partnershipContract: {
      yes: 'giuridicamente tutto il patrimonio comune e ereditariamente tutti i beni nel quadro della quota liberamente disponibile',
      no: "giuridicamente l'intera proposta e ereditariamente tutti i beni nel quadro della quota liberamente disponibile",
    },
    quota: 'Quota',
    noContractButChildren: {
      main: "solo per l'usufrutto invece della quota legittima, che spetta a {{children}}",
      oneChild: 'il bambino',
      multipleChildren: 'i bambini',
      receive: ' spetta.',
    },
    propertyContractAndChildren: {
      main: "solo per l'usufrutto invece della quota legittima, che spetta a {{children}}",
      oneChild: 'il bambino riceve',
      multipleChildren: 'i bambini spetta.',
    },
    born: ', nato il {{birthday}}, ',
  },

  p4UnmarriedPartner: {
    receives:
      'riceve tutti i beni nel quadro della quota liberamente disponibile.',
    receivesProperty: 'riceve in particolare anche',
    theProperty: 'la proprietà',
    atTheAddress: "all'indirizzo",
    assignedToProperty: 'assegnato come proprietà.',
    born: ', nato il {{birthday}}, ',
    genderStatus: {
      male: 'La mia partner',
      female: 'Il mio partner',
      male1: 'della mia partner',
      female1: 'del mio partner',
      registeredPartnerMale: 'La mia partner',
      registeredPartnerFemale: 'Il mio partner',
    },
  },

  p5UnmarriedPartnerWithChildren: {
    commonPartnership: {
      oneChild: {
        main: 'Il nostro figlio comune, ',
        born: ', nato il {{birthday}}, ',
        receive: 'o i suoi discendenti ricevono la quota legittima.',
      },
      multipleChildren: {
        main: 'I nostri figli comuni, ',
        born: ', nati il {{birthday}}, ',
        receive:
          'o tutti i loro discendenti ricevono ciascuno la quota legittima.',
      },
      and: 'e',
    },
    otherPartnership: {
      oneChild: {
        main: 'Il mio figlio non comune, ',
        born: ', nato il {{birthday}}, ',
        receive: 'o i suoi discendenti ricevono la quota legittima.',
      },
      multipleChildren: {
        main: 'I miei figli non comuni, ',
        born: ', nati il {{birthday}}, ',
        receive:
          'o tutti i loro discendenti ricevono ciascuno in parti uguali la quota legittima.',
      },
    },
    noPartnership: {
      oneChild: {
        main: 'Il mio figlio, ',
        born: ', nato il {{birthday}}, ',
        receive: ' o i suoi discendenti ricevono la quota legittima.',
      },
      multipleChildren: {
        main: 'I miei figli, ',
        born: ', nati il {{birthday}}, ',
        receive:
          ' o tutti i loro discendenti ricevono ciascuno la quota legittima.',
      },
    },
    deathCase:
      'Se dovessi morire contemporaneamente a {{partner}}, allora {{childrenCase}}',
    born: ', nato il {{birthday}}, ',
    childrenCase: {
      oneChild: {
        fromCommonMarriage: 'riceve il nostro figlio comune, ',
        fromOtherMarriage: 'riceve il nostro figlio non comune, ',
        fromUnknownMarriage: 'riceve il mio figlio,',
      },
      onesDescendants: 'o i suoi discendenti tutti i beni.',
      multipleChildren: {
        fromCommonMarriage: 'ricevono i nostri figli comuni, ',
        fromOtherMarriage: 'ricevono i nostri figli non comuni, ',
        fromUnknownMarriage: 'ricevono i miei figli, ',
      },
      allDescendants: 'o tutti i loro discendenti ciascuno tutti i beni.',
    },
  },

  p4Child: {
    commonMarriage: {
      oneChild: {
        main: 'Il nostro figlio comune, ',
        born: ', nato il {{birthday}}, ',
        receive:
          'o tutti i suoi discendenti ricevono ciascuno in parti uguali tutti i beni nel quadro della quota liberamente disponibile. ',
      },
      multipleChildren: {
        main: 'I nostri figli comuni, ',
        born: ', nati il {{birthday}}, ',
        receive:
          'o tutti i loro discendenti ricevono ciascuno in parti uguali tutti i beni nel quadro della quota liberamente disponibile. ',
      },
    },
    otherMarriage: {
      oneChild: {
        main: 'Il mio figlio non comune, ',
        born: ', nato il {{birthday}}, ',
        receive:
          'o tutti i suoi discendenti ricevono ciascuno in parti uguali tutti i beni nel quadro della quota liberamente disponibile. ',
      },
      multipleChildren: {
        main: 'I miei figli non comuni, ',
        born: ', nati il {{birthday}}, ',
        receive:
          'o tutti i loro discendenti ricevono ciascuno in parti uguali tutti i beni nel quadro della quota liberamente disponibile. ',
      },
      and: 'e',
    },
    partnerCase: {
      male: 'Mia moglie',
      female: 'Mio marito',
      born: ', nato il {{birthday}}, ',
      receivesShare: 'riceve la quota legittima.',
    },
  },

  unmarriedChildrenChoice: {
    commonPartnership: {
      oneChild: {
        main: 'Il nostro figlio comune, ',
        born: ', nato il {{birthday}}, ',
        receives:
          'o tutti i suoi discendenti ricevono ciascuno in parti uguali tutti i beni',
      },
      multipleChildren: {
        main: 'I nostri figli comuni, ',
        born: ', nati il {{birthday}}, ',
        receives:
          'o tutti i loro discendenti ricevono ciascuno in parti uguali tutti i beni',
      },
      quota: 'nel quadro della quota liberamente disponibile.',
      and: 'e',
    },
    otherPartnership: {
      oneChild: {
        main: 'Il mio figlio non comune, ',
        born: ', nato il {{birthday}}, ',
        receives:
          'o tutti i suoi discendenti ricevono ciascuno in parti uguali tutti i beni',
      },
      multipleChildren: {
        main: 'I miei figli non comuni, ',
        born: ', nati il {{birthday}}, ',
        receives:
          'o tutti i loro discendenti ricevono ciascuno in parti uguali tutti i beni',
      },
      quota: 'nel quadro della quota liberamente disponibile.',
      and: 'e',
    },
    noPartnership: {
      oneChild: {
        main: 'Il mio figlio, ',
        born: ', nato il {{birthday}}, ',
        receives: 'o i suoi discendenti ricevono',
      },
      multipleChildren: {
        main: 'I miei figli, ',
        born: ', nati il {{birthday}}, ',
        receives: 'o tutti i loro discendenti ricevono ciascuno',
      },
      quota:
        'ciascuno in parti uguali tutti i beni nel quadro della quota liberamente disponibile.',
      and: 'e',
    },
  },

  unmarriedInstitutionChoice: {
    commonPartnership: {
      oneChild: {
        main: 'Il nostro figlio comune, ',
        born: ', nato il {{birthday}}, ',
        receive: 'o i suoi discendenti ricevono la quota legittima.',
      },
      multipleChildren: {
        main: 'I nostri figli comuni, ',
        born: ', nati il {{birthday}}, ',
        receive:
          'o tutti i loro discendenti ricevono ciascuno la quota legittima.',
      },
      and: 'e',
    },
    otherPartnership: {
      oneChild: {
        main: 'Il mio figlio non comune, ',
        born: ', nato il {{birthday}}, ',
        receive: 'o i suoi discendenti ricevono la quota legittima.',
      },
      multipleChildren: {
        main: 'I miei figli non comuni, ',
        born: ', nati il {{birthday}}, ',
        receive:
          'o tutti i loro discendenti ricevono ciascuno la quota legittima.',
      },
      and: 'e',
    },
    noPartnership: {
      oneChild: {
        main: 'Il mio figlio, ',
        born: ', nato il {{birthday}}, ',
        receive: 'o i suoi discendenti ricevono la quota legittima.',
      },
      multipleChildren: {
        main: 'I miei figli, ',
        born: ', nati il {{birthday}}, ',
        receive:
          'o tutti i loro discendenti ricevono ciascuno la quota legittima.',
      },
      and: 'e',
    },
  },

  p4Institution: {
    oneInstitution: {
      main: "L'istituzione",
      receives: 'riceve',
    },
    multipleInstitutions: {
      main: 'Le istituzioni',
      receives: 'ricevono',
    },
    finals:
      ' ciascuno in parti uguali tutti i beni nel quadro della quota liberamente disponibile. ',
    final: ' tutti i beni nel quadro della quota liberamente disponibile. ',
    partnerReceives: 'riceve la quota legittima.',
    born: ', nato il {{birthday}}, ',
    and: ' e ',
  },

  p5MarriedSpouse: {
    marriedStatus: {
      male: 'Mia moglie',
      female: 'Mio marito',
    },
    livingPartnershipStatus: {
      male: 'La mia partner registrata',
      female: 'Il mio partner registrato',
    },
    particularReceives: 'riceve in particolare anche',
    theProperty: 'la proprietà',
    atTheAddress: "all'indirizzo",
    assignedToProperty: 'assegnato come proprietà.',
  },

  p5Institution: {
    commonMarriage: {
      oneChild: {
        main: 'Il nostro figlio comune, ',
        born: ' nato il {{birthday}}, ',
        receive: 'o i suoi discendenti ricevono la quota legittima.',
      },
      multipleChildren: {
        main: 'I nostri figli comuni, ',
        born: ' nati il {{birthday}}, ',
        receive:
          'o tutti i loro discendenti ricevono ciascuno la quota legittima.',
      },
      and: 'e',
    },
    otherMarriage: {
      oneChild: {
        main: 'Il mio figlio non comune, ',
        born: ' nato il {{birthday}}, ',
        receive: 'o i suoi discendenti ricevono la quota legittima.',
      },
      multipleChildren: {
        main: 'I miei figli non comuni, ',
        born: ' nati il {{birthday}}, ',
        receive:
          'o tutti i loro discendenti ricevono ciascuno la quota legittima.',
      },
      and: 'e',
    },
  },

  p6MarriedSpouse: {
    oneChild: {
      commonMarriage: 'Il nostro figlio comune, ',
      otherMarriage: 'Il mio figlio non comune, ',
      born: ', nato il {{birthday}}, ',
      receives: 'o i suoi discendenti ricevono ',
    },
    multipleChildren: {
      commonMarriage: 'I nostri figli comuni, ',
      otherMarriage: 'I miei figli non comuni, ',
      born: ', nati il {{birthday}}, ',
      receives: 'o tutti i loro discendenti ricevono ciascuno',
    },
    final:
      "la quota legittima, gravata tuttavia dall'usufrutto menzionato nel primo paragrafo. ",
  },

  willExecutor:
    'Nomino {{executor1}} come esecutore testamentario per tutta la mia eredità. Nomino {{executor2}} come esecutore testamentario sostitutivo.',

  forFollowing: {
    main: 'Per i seguenti beni si applica tuttavia una disposizione diversa:',
    asset: '{{asset}}, {{address}} va a: {{benefiter}}. ',
    devisionProvision:
      'Questa è una disposizione di divisione a favore di {{benefiter}}, non un ulteriore beneficio.',
  },
};

export default outputDoc;
