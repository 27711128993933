const pathnames = {
  auth: {
    index: 'auth',
    login: 'login',
    verify: 'verify',
    resetPassword: 'reset-password',
    passwordResetConfirm: 'password-reset-confirm',
    partnerOnboarding: 'partner-onboarding',
  },
  dashboard: {
    index: 'dashboard',
    home: 'home',
    guestOn: 'guest-on',
    guestOnDocumentHistory: 'guest-on/:guestId/document-history',
    contact: 'contact',
    livingWill: 'living-will',
    powerAttorney: 'power-of-attorney',
    lastWill: 'last-will',
    marriageContract: 'marriage-contract',
    assetsRegister: 'assets-and-property-register',
    cohabitationAgreement: 'cohabitation-agreement',
    digitalLegacy: {
      index: 'digital-legacy',
      importantInformation: 'important-information',
      digitalAssets: 'digital-assets',
      photosVideos: 'photos-videos',
    },
    taxFolderClient: 'tax-folder',
    chat: 'chat',
    thankyouCard: 'final-arrangements',
    consultant: 'consultant',
    consulting: 'consulting',
    createUser: 'consulting/create-user',
    user: {
      index: 'user',
      account: 'account',
    },
  },
  compact: {
    forbidden: '403',
    notFound: '404',
    error: '500',
    maintenance: 'maintenance',
    comingSoon: 'coming-soon',
    contact: 'contact',
    pension: 'pension',
    register: 'register',
    onboardingStepOne: 'pension/step=1',
    onboardingStepTwo: 'pension/step=2',
    onboardingStepThree: 'pension/step=3',
    acceptInvitation: 'trusted-person/invitation',
    familyInvitation: 'family/invitation',
    partnerInvitation: 'partner/invitation',
  },
  main: {
    index: '/',
    situationCheck: 'life-situation-check',
    grosselternMagazin: 'deals/grandparents-magazine',
    preventiveCheckUp: 'preventive-checkup',
    situationLanding: 'pension',
    aboutUs: 'about-us',
    imprint: 'imprint',
    privacyPolicy: 'privacy-policy',
    agb: 'terms-and-conditions',
  },
};

export default pathnames;
