const it = {
  homeTitle: 'Essere ben organizzati',
  homeDescription:
    'Prendi ora le tue decisioni personali e familiari o parla con un consulente finanziario o legale.',
  homeHighlight: 'finanziario',
  homeHighlight2: 'legale',

  clear: 'Cancella',
  search: 'Cerca',
  done: 'Fatto',

  ch: 'Svizzera',
  noData: 'Nessun dato',
  beneficiaries: 'Beneficiari',
  representatives: 'Rappresentanti',
  footerSettings: 'Piè di pagina',

  service: 'Servizio',
  accessLevel: 'Livello di accesso',
  addServiceAccess: 'Aggiungi accesso al servizio',
  addNewSuccessor: 'Aggiungi nuovo beneficiario',
  afterDeath: 'Dopo la morte',
  whileAlive: 'Durante la vita',

  documentType: 'Tipo di documento',

  documentTypeStatus: {
    pending: 'In attesa',
    approved: 'Approvato',
    rejected: 'Rifiutato',
  },

  docClassificationWarning:
    'Non siamo sicuri del tipo di documento. Per favore, validatelo',

  pending: 'In attesa',
  failed: 'Fallito',

  customerOpinion: 'Opinioni dei clienti',
  joinWaitlist: "Unisciti alla lista d'attesa",
  servicesToShare: 'Servizi che voglio condividere',

  ppDocumentChoices: {
    tax_declaration: 'Dichiarazione fiscale',
    tax_invoice: 'Fattura fiscale',
    salary_statement: 'Attestato di salario',
    yearly_salary_statement: 'Attestato di salario annuale',
    pension_certificate: 'Certificato di pensione',
    alimony_confirmation: 'Conferma alimenti',
    monthly_salary_statement: 'Attestato di salario mensile',
    rental_income: 'Conferma reddito da locazione',
    pension_statement: 'Estratto conto pensione',
    income_statement: 'Dichiarazione dei redditi',
    balance_sheet: 'Bilancio',
    tax_certificate: 'Certificato fiscale',
    account_statement: 'Estratto conto',
    interest_capital_statement: 'Estratto conto interessi e capitale',
    receipt_travel_costs: 'Ricevuta spese di viaggio',
    general_invoice: 'Fattura generale',
    debt_certificate: 'Certificato di debito',
    pension_fund_purchase: 'Certificato di acquisto fondo pensione',
    confirmation_donation: 'Conferma donazione',
    receipt_education: 'Ricevuta spese di istruzione',
    insurance_policy: 'Polizza assicurativa',
    mortgage_contract: 'Contratto ipotecario',
    health_insurance_invoice: 'Fattura assicurazione sanitaria',
    receipt_childcare: "Ricevuta assistenza all'infanzia",
    utilities_statement: 'Bollette utenze',
    renovation_list: 'Lista ristrutturazioni',
    real_estate_valuation: 'Valutazione immobiliare',
    securities_trading: 'Negoziazione titoli',
    third_pillar_confirmation: 'Conferma acquisto 3° pilastro',
  },

  shareWithDscp:
    'Seleziona o aggiungi un nuovo membro con il quale vuoi condividere le tasse',

  uploadingToWarning:
    'Caricando da questo livello della cartella, il file sarà aggiunto a',
  allDocuments: 'Tutti i documenti',
  finalTaxDeclaration: 'Dichiarazione fiscale finale',
  proofOfIncome: 'Certificato di reddito',
  proofOfAssets: 'Certificato di patrimonio',
  deductibleExpenses: 'Certificato di spese deducibili',
  realEstateAndProperties: 'Certificato di immobili e proprietà',
  otherDocuments: 'Altri documenti',
  taxInvoices: 'Fatture fiscali',
  tax: 'Cartella fiscale',
  can_edit: 'Può modificare',
  can_view: 'Può vedere',
  view: 'Vedi',
  access: 'Accesso',

  civilStatus: {
    married: 'Sposato/a',
    single: 'Celibe/Nubile',
    divorced: 'Divorziato/a',
    widowed: 'Vedovo/a',
    registered_partnership: 'Unione registrata',
  },

  contractParties: {
    self: 'Io',
    spouse: 'Coniuge',
  },
  fileTypeOptions: {
    audio: 'Audio',
    video: 'Video',
    document: 'Documento',
    archive: 'Archivio',
    image: 'Immagine',
  },
  guestTaxFolder: {
    title: 'Documenti fiscali per {{user}}',
    description:
      'Qui puoi visualizzare tutti i documenti rilevanti ai fini fiscali e contattare il tuo cliente per richiedere informazioni mancanti o chiarire domande aperte.',
    messageClient: 'Messaggio al cliente',
  },

  taxFolder: {
    title: 'Panoramica delle tue tasse',
    requestTaxExpert: 'Richiedi un esperto fiscale',
    submitLatestTax: 'Invia la tua ultima dichiarazione',
    submittedLatestTax: 'Dichiarazione fiscale inviata',
    description:
      "Controlla le versioni precedenti e assicurati di sapere come sono state fatte le cose all'epoca.",
    requestTaxExpertModal: {
      title: 'Ci connettiamo con un esperto fiscale',
      description:
        'Invia una richiesta e ci connettiamo con un esperto fiscale vicino a te che prenderà in carico la dichiarazione dei redditi per te',
      reqBtn: 'Invia richiesta',
    },
    findTaxExpert: {
      title: 'Trova un esperto fiscale vicino a te',
      description:
        'Varie organizzazioni ed esperti ti assistono nella preparazione e ottimizzazione della tua dichiarazione dei redditi. Trova il partner giusto per te.',

      tableHead: {
        expert: 'Esperto',
        location: 'Posizione',
        price: 'Prezzo',
      },
    },
  },
  chat: {
    customerChat: 'Chat clienti',
  },
  dashCardInfos: {
    livingWill: {
      title: 'Testamento biologico',
      description: 'Registra i tuoi desideri riguardo alle cure mediche.',
      estimate: '7 minuti',
    },
    powerOfAttorney: {
      title: 'Mandato precauzionale',
      description: 'Chi decide per te se non puoi farlo tu?',
      estimate: '10 minuti',
    },
    lastWill: {
      title: 'Testamento',
      description: 'Metti al sicuro la tua eredità secondo i tuoi desideri.',
      estimate: '12 minuti',
    },
    marriageContract: {
      title: 'Contratto matrimoniale',
      description:
        'Se pertinente, stabilisci delle regole chiare per la tua partnership.',
    },
    digitalFootprint: {
      title: 'Eredità digitale',
      description: 'Controlla cosa succede ai tuoi account online.',
    },
    assetsRegister: {
      title: 'Registro dei beni',
      description: 'Tieni traccia dei tuoi beni.',
    },
    cohabitationAgreement: {
      title: 'Contratto di convivenza',
      description: 'Regola la tua vita insieme',
      estimate: '10 minuti',
    },
    successionPlanning: {
      title: 'Pianificazione della successione',
      description: "Possiedi un'azienda?! Assicurati della sua sostenibilità.",
    },
    taxFolder: {
      title: 'Cartella fiscale',
      description:
        'Cartella fiscale intelligente e digitale per tutti i tuoi documenti fiscali',
    },
    comingSoon: {
      title: 'Prossimamente',
      description: 'gut geregelt sta lavorando costantemente a nuovi servizi.',
    },
    timeToComplete: 'Tempo necessario per la creazione: {{estimate}}',
    continue: 'Continua',
    getStarted: 'Inizia',
    summaryAndReview: 'Visualizza documento',
    uploadDocument: 'Carica documento',
    update: 'Aggiorna',
    history: 'Cronologia',
    markAsDone: 'Segna come completato',
    startOver: 'Ricominci da capo',
    viewData: 'Visualizza dati',
    guest: {
      disabledButton:
        "L'host ha aggiunto restrizioni aggiuntive per l'accesso a questo servizio.",
      getDocButton: 'Recupera documento',
      noDocumentUploaded:
        '{{hostName}} non ha ancora caricato {{documentType}}.',
    },
  },

  loginInfo: {
    title: 'Fai un favore ai tuoi cari, diventa ben organizzato',
    title1: 'Ciao, bentornato',
    newUser: 'Nuovo utente?',
    createAccount: 'Crea un account',
    forgotPassword: 'Password dimenticata?',
    login: 'Accedi',
    resetPasswordInfo:
      "Inserisci l'indirizzo email associato al tuo account e ti invieremo un link per reimpostare la password.",
    sendRequest: 'Invia richiesta',
    returnToLogin: 'Torna al login',
    setNewPassword: 'Imposta nuova password',
    updatePassword: 'Aggiorna password',
    invalidLink: 'Link non valido o scaduto!',
    returnToResetPassword: 'Torna alla reimpostazione della password',
    welcomeOnboard: 'Benvenuto a bordo',
    welcomeOnboardDescription:
      'Per procedere al dashboard di gut geregelt, imposta una nuova password.',
  },

  services: {
    forIndividuals: 'Per privati',
    forCompanies: 'Per aziende',
    gutGeregelt: 'gut geregelt',
    livingWill: 'Testamento biologico',
    powerOfAttorney: 'Mandato precauzionale',
    lastWill: 'Testamento',
    marriageContract: 'Contratto matrimoniale',
    digitalFootprint: 'Eredità digitale',
    registerAssets: 'Registro dei beni',
    cohabitationAgreement: 'Contratto di convivenza',
    taxFolder: 'Cartella fiscale',
    pricing: 'Prezzi',
    login: 'Accedi',
    makeProvisions: 'Registrati ora',
    pensionCheck: 'Controllo pensionistico',
    partnerWithUs: 'Partner in white label',
    blog: 'Blog',
    dashboard: 'Dashboard',
    consulting: 'Consulenza',
    aboutUs: 'Chi siamo',
    other: 'Altro',
  },

  settings: {
    changeAccount: 'Cambia account',
    changeAccountContent: {
      title: 'Passa a un altro account',
    },
    settings: 'Impostazioni',
    needHelp: 'Hai bisogno di aiuto?',
    logOut: 'Disconnetti',
  },

  roles: {
    executor: 'Esecutore',
    lawyer: 'Avvocato',
    trustee: 'Fiduciario',
    familyMember: 'Membro della famiglia',
    friend: 'Amico',
  },
  fileSystemTableHead: {
    name: 'Nome',
    size: 'Dimensione',
    type: 'Tipo',
    modifiedAt: 'Modificato',
  },

  verifyCode: 'Verifica il codice inviato a',
  learnMore: 'Scopri di più',
  startProcess: 'Inizia subito',
  selected: 'Selezionato',
  folder: 'Cartella',
  file: 'File',
  folders: 'Cartelle',
  files: 'File',
  create: 'Crea',
  upload: 'Carica',
  apply: 'Applica',
  uploading: 'Caricamento...',
  folderName: 'Nome cartella',
  fileName: 'Nome file',
  endDateError: 'La data di fine deve essere successiva alla data di inizio',
  newFolder: 'Nuova cartella',
  editFolder: 'Modifica cartella',
  uploadFiles: 'Carica file',
  editFile: 'Modifica file',
  disabledService: {
    title:
      'Questo servizio non è disponibile nel tuo attuale piano di abbonamento',
    description: 'Aggiorna il tuo piano per sbloccare questa funzionalità',
  },
  editAccessRights: 'Modifica i diritti di accesso',
  editAccessRightsDscp:
    "Specifica quali dati l'istituzione è autorizzata a visualizzare. Puoi modificarlo in qualsiasi momento o revocare completamente l'accesso.",
  shareSelected: 'Condividi solo i dati selezionati',
  acceptingInvitation: 'Accetta invito',
  acceptingInvitationFail: 'Invito scaduto o non valido...',
  removeAll: 'Rimuovi tutto',
  account: 'Account',
  main: 'Principale',
  guest: 'Ospite',
  manage: 'Gestisci',
  trackHistoryTitle:
    'Di seguito puoi tenere traccia dei documenti caricati e della cronologia di {{documentType}}',
  getStarted: 'Inizia',
  resetToDefault: 'Ripristina le impostazioni predefinite',
  lastUpdated: 'Ultimo aggiornamento:',
  description: 'Descrizione',
  imageHelper: 'Formati file consentiti: *.jpeg, *.jpg, *.png, *.gif, ',
  imageMaxSize: 'dimensione massima del file: ',
  storageLocation: 'Luogo di conservazione',
  hire: 'Engager un conseiller fiscal',
  beneficiary: 'Beneficiario',
  continue: 'Continua',
  beneficiarie: 'Beneficiario a favore di',
  removeFilter: 'Rimuovi filtro',
  addNewBeneficiary: 'Aggiungi nuovo beneficiario',
  primary: 'Contatto principale',
  representative: 'Rappresentante',
  addNewAsset: 'Aggiungi nuovo bene',
  addNewProperty: 'Aggiungi nuova proprietà',
  uploadNew: 'Carica nuovo',
  addMore: 'Aggiungi altro',
  saveDetails: 'Salva dettagli',
  saving: 'Salvataggio...',
  enoughForNow: 'Basta per ora!',
  finishForToday: 'Basta per oggi',
  currentPackage: 'Hai scelto il seguente pacchetto previdenziale:',
  nextPaymentDue: 'Prossimo pagamento dovuto il',
  expiresAt: 'Scade il',
  freePackage: 'Pacchetto gratuito',
  userDataAccess: {
    request: 'Richiesta accesso ai dati',
    awaiting: 'In attesa di approvazione',
    approved: 'Visualizza dati cliente',
  },
  info: 'Info',
  shareWith: 'Condividi con',
  starterPackage: 'Pacchetto iniziale',
  standardPackage: 'Pacchetto standard',
  familyPack: 'Pacchetto famiglia',
  noPackage: 'Nessun pacchetto',
  needHelp: 'Hai bisogno di aiuto?',
  continueToLastWill: 'Continua con il testamento',
  continueToPOA: 'Continua con il mandato precauzionale',
  addAnother: 'Aggiungi un altro esecutore',
  add: 'Aggiungi',
  finish: 'Finito',
  delete: 'Elimina',
  edit: 'Modifica',
  save: 'Salvare',
  close: 'Chiudi',
  next: 'Avanti',
  back: 'Indietro',
  or: 'o',
  role: 'Ruolo',
  current: 'Attuale',
  gender: 'Genere',
  male: 'Maschio',
  howMany: 'Quanti',
  female: 'Femmina',
  cancel: 'Annulla',
  verify: 'Verifica',
  priority: 'Priorità',
  areYouSureYouWantToLeave: 'Vuoi fare una pausa?',
  enoughForNowDscp:
    'Non preoccuparti, salveremo i tuoi dati e ti ricorderemo regolarmente che questo documento non è ancora legalmente concluso. Puoi continuare in qualsiasi momento da dove hai lasciato.',
  enoughForNowCancel: 'Sì, voglio fare una pausa',
  enoughForNowContinue: 'No, grazie',
  addPropertyOrAsset: 'Aggiungi proprietà o bene',
  addItem: 'Aggiungi bene',
  good: 'bene',
  addNew: 'Aggiungi nuovo',
  owner: 'Proprietario',
  adress: 'Indirizzo',
  download: 'Scarica',
  upgradePlan: 'Aggiorna il tuo piano per scaricare due o più documenti',
  choosePlan: 'Scegli un piano',
  switzerland: 'Svizzera',
  fillInfoTitle:
    'Assicurati di fornire informazioni importanti per il tuo account principale',
  switch: 'Cambia',
  activatePersonalAccount: 'Attiva il tuo account personale',
  howManyMembers: 'Membri della famiglia',
  requiredBasicInfoTitle:
    'Inserisci le informazioni di base richieste prima di procedere',
  allType: 'Tutti i tipi',
  selectDate: 'Seleziona data',
  selectDateRange: 'Seleziona intervallo di date',
  familyPackage: {
    title: 'La mia famiglia',
    description:
      'Con il pacchetto famiglia, tu e i tuoi familiari potete gestire la vostra previdenza a prezzi scontati.',
    helperText:
      "Compila attentamente le informazioni sui tuoi familiari. Queste informazioni non possono essere aggiornate una volta che l'utente ha accettato l'invito.",
  },
  deleteConfirmation: {
    title: 'Conferma eliminazione',
    oneItemQuestion: 'Vuoi davvero eliminare questo file?',
    multipleItemsQuestion: 'Vuoi davvero eliminare questi {{itemNr}} file?',
    question: 'Sei sicuro di voler eliminare {{item}}?',
    multiDeleteQuestion: 'Vuoi davvero eliminare {{items}} elementi?',
    cancelDelete: 'No, mantieni {{item}}',
    confirmDelete: 'Sì, elimina {{item}}',
  },

  formLabels: {
    name: 'Nome',
    firstName: 'Nome',
    lastName: 'Cognome',
    civilStatus: 'Stato civile',
    place_of_citinzenship: 'Luogo di cittadinanza',
    place_of_marriage: 'Luogo del matrimonio',
    date_of_marriage: 'Data del matrimonio',
    dropOrSelectFile: 'Rilascia o seleziona file',
    children_count: 'Numero di figli',
    street: 'Via',
    houseNumber: 'Numero',
    postalCode: 'CAP',
    city: 'Città',
    country: 'Paese',
    uploadPhoto: 'Carica foto',
    twoFactorAuth: 'Autenticazione a due fattori',
    verify: 'Verifica',
    uploadLogo: 'Carica logo',
    favicon: 'Favicon',
    tag: 'Tag',
    shareForFemalePartner: 'Quota per la tua partner (in %)',
    shareForMalePartner: 'Quota per il tuo partner (in %)',
    submit: 'Invia',
    invite: 'Invita',
    emailAddress: 'Indirizzo email',
    role: 'Ruolo',
    twoFactorAuthHelp:
      "Se attivi l'autenticazione a due fattori, verrà inviato un codice al tuo telefono che dovrai inserire per accedere.",
    action: 'Azione',
    region: 'Regione',
    artifact: 'Artefatto',
    search: 'Cerca',
    mobilePhone: 'Numero di cellulare',
    hide_header_links: "Nascondi i link dell'intestazione",
    hide_solution_menu: 'Rimuovi la colonna "Soluzioni" dal piè di pagina',
    footer_title: 'Titolo piè di pagina',
    footer_text: 'Testo piè di pagina',
    description: 'Descrizione',
    storageLocation: 'Luogo di conservazione',
    designatedBeneficiary: 'Beneficiario designato',
    benefiterAfterSeparation:
      'In caso di separazione, la proprietà diventerà di esclusiva proprietà di',
    password: 'Password',
    oldPassword: 'Vecchia password',
    newPassword: 'Nuova password',
    confirmPassword: 'Conferma password',
    minimum6Characters: 'Minimo 6 caratteri',
    primaryRepresentative: 'Rappresentante principale',
    secondaryRepresentative: 'Rappresentante secondario',
    howManyChildren: 'Quanti figli hai?',
    assetType: 'Tipo di bene',
    date_of_birth: 'Data di nascita',
    rep_inform_date: 'Data di informazione',
    trusted_person: 'Esecutore testamentario',
    billing_email: 'Email di fatturazione',
    asset: 'Bene',
    newConsultant: 'Nuovo consulente',
    institutionName: 'Istituto finanziario',
    message: 'Messaggio',
    location: 'Posizione',
    topics: 'Argomenti',
    type: 'Tipo',
    plusTopic: '+ Argomento',
    propertyType: 'Tipo di proprietà',
    disposal_fixed_amount: 'Importo fisso',
    amount: 'Importo',
    passportLocation: 'Luogo di conservazione del passaporto',
    date_of_first_payment: 'Data del primo pagamento',
    meet_year: 'Anno',
    date_of_moving_in: 'Data di trasferimento',
    idCardLocation: "Luogo di conservazione della carta d'identità",
    residencePermitLocation: 'Luogo di conservazione del permesso di soggiorno',
    familyBookLocation: 'Luogo di conservazione del libretto di famiglia',
    birthCertificateLocation:
      'Luogo di conservazione del certificato di nascita',
    disposal_percentage: 'Percentuale',
    joint_household_account_bank: 'Banca del conto familiare congiunto',
    plusUser: '+ Utente',
    domain: 'Dominio',
    addWidget: 'Aggiungi widget',
    plusWidget: '+ Widget',
    organisation_name: "Nome dell'organizzazione",
    primary_color: 'Colore primario',
    secondary_color: 'Colore secondario',
    sendAutomatically:
      'Condividi automaticamente quando aggiorno il testamento biologico',
    deathReport: 'Segnala decesso',
    executorCertificate: 'Certificato di esecutore',
    insuranceCompany: 'Compagnia assicurativa',
    insurancePolicyNumber: 'Numero di polizza',
    ahvFund: 'Fondo AVS',
    ahvNumber: 'Numero AVS',
    pensionFundOrganization: 'Organizzazione del fondo pensione',
    financialInstitution: 'Istituto finanziario',
    trustedPerson: 'Esecutore testamentario',
    select: 'Seleziona',
    cadenceSelect: 'Seleziona cadenza',
    expenses_to_be_covered: 'Spese da coprire',
    workload: 'Carico di lavoro (in %)',
    shareYouPay: 'Quota che paghi (in %)',
    afterDeath: 'Dopo la morte',
    access: 'Accesso',
    executor: 'Esecutore testamentario',
    trustees_list: {
      husband_wife: 'Marito, Moglie',
      partner: 'Partner',
      'close-friend': 'Amico, Amica',
      'brother-sister': 'Fratello, Sorella',
      'brother-sister-in-law': 'Cognato, Cognata',
    },
    beneficiarieCategories: {
      selectCategory: 'Seleziona categoria',
      individual: 'Persona singola',
      company: 'Azienda',
      foundation: 'Fondazione',
      association: 'Associazione',
    },
    ordinalNumbers: {
      first: 'primo',
      second: 'secondo',
      third: 'terzo',
      fourth: 'quarto',
      fifth: 'quinto',
      sixth: 'sesto',
      seventh: 'settimo',
      eighth: 'ottavo',
      ninth: 'nono',
      tenth: 'decimo',
      information: 'Informazioni sul {{ordinalNumber}} figlio',
    },
    good_description: 'Descrizione del bene',
    approximate_value: 'Valore approssimativo',
    storage_location: 'Luogo di conservazione',
    previous_owner: 'Proprietario precedente',
    font_settings: 'Impostazioni carattere',
    title_font: 'Carattere titolo',
    subtitle_font: 'Carattere sottotitolo',
    label_font: 'Carattere etichetta',
    prose_font: 'Carattere testo',
    link_font: 'Carattere link',
  },

  userSettings: {
    general: 'Generale',
    billing: 'Fatturazione',
    my_family: 'La mia famiglia',
    successors: 'Persone di fiducia',
    notifications: 'Notifiche',
    dataSharing: 'Condivisione dati',
    changePassword: 'Cambia password',
    changeReminder: 'Frequenza dei promemoria:',
    reminder: 'Notifiche: Promemoria per documenti in attesa',
    reminderDescription:
      'Nel caso in cui i documenti siano in attesa o non siano completamente finalizzati, offriamo una funzione di promemoria.',
    reminderFrequency: 'Frequenza dei promemoria',
    frequencyChoices: {
      weekly: 'Settimanale',
      monthly: 'Mensile',
      quarterly: 'Trimestrale',
      disabled: 'Disabilitato',
    },
    successor: {
      title: 'Definisci persone di fiducia',
      description:
        'Per garantire che i tuoi documenti e informazioni siano disponibili in caso di decesso, assicurati che almeno due persone abbiano accesso a gut geregelt.',
      pleaseConfirm:
        'Conferma che le seguenti persone riceveranno accesso ospite da oggi (con accesso limitato - vedi cosa è incluso) e in caso di decesso avranno accesso illimitato a tutti i dati.',
      agree: "Sì, sono d'accordo",
      addSuccessor: 'Aggiungi persone di fiducia',
      access: {
        select_label: 'Diritto di accesso generale',
        switch_label:
          'Diritti di accesso specifici agli artefatti precauzionali',
        modal: {
          title: 'Definisci autorizzazioni di accesso individuali',
        },
      },
    },

    dataSharingPage: {
      noSharingTitle: 'Condivisione dati',
      toggleLabel: 'Voglio condividere i miei dati',
      currentlySharing: 'Accesso ai dati per {{partnerName}}',
      partnerListLabel: 'Organizzazione partner',
      changedOrganisation: 'Organizzazione cambiata?',
      info: "Se hai attivato la condivisione dei dati con un'organizzazione partner di gut geregelt, accetti di condividere i seguenti dati con il tuo consulente bancario:",
      partnerInfo:
        'Se desideri condividere i dati con i consulenti clienti di {{partnerName}}:',
      sharingName: 'Nome',
      sharingEmail: 'Email',
      sharingLogin: 'Ultimo accesso',
      sharingServiceProgress:
        'Progresso in percentuale per i singoli documenti previdenziali (tuttavia, non vengono condivisi documenti o informazioni effettive)',
    },
  },

  adminSettings: {
    general: 'Generale',
    team: 'Team',
    consulting: 'Consulenza',
    changePassword: 'Cambia password',
  },

  team: {
    tableHeads: {
      name: 'Nome',
      email: 'Email',
      role: 'Ruolo',
      last_login: 'Ultimo accesso',
    },
    tagChoices: {
      general: 'Generale',
      pension: 'Pensioni',
      mortgage: 'Mutui',
      asset_management: 'Gestione patrimoniale',
      tax: 'Consulenza fiscale',
    },
    resendInvitation: "Invia nuovamente l'invito",
    cancelInvitation: 'Annulla invito',
    consultant: 'Consulente',
    consultantDescription: 'Accesso al cockpit del consulente di gut geregelt.',
    admin: 'Admin',
    adminDescription:
      'Gestione del cockpit di gut geregelt, delle persone e di altre impostazioni.',
  },

  footer: {
    allRightsReserved: 'Tutti i diritti riservati',
    provisionLinks: {
      title: 'Soluzioni',
      pricing: 'Prezzi',
      livingWill: 'Testamento biologico',
      powerOfAttorney: 'Mandato precauzionale',
      lastWill: 'Testamento',
      digitalFootprint: 'Eredità digitale',
      registerAssets: 'Registro dei beni',
    },
    resourcesLinks: {
      title: 'Risorse',
      imprint: 'Impronta',
      termsAndConditions: 'Termini e condizioni',
      privacyPolicy: 'Politica sulla privacy',
      contact: 'Contatto',
    },
    contact: 'Contatto',
  },

  contactUs: {
    getLegalSupport: 'Ottieni supporto legale',
    partnerTitle: 'Contatta il team di gut geregelt',
    description:
      'gut geregelt ti collega volentieri con consulenti legali esperti nella tua regione, se necessario.',
    pleaseWriteMessage: 'Per favore scrivici un messaggio',
  },

  civil_statusWarning: {
    title: 'Sei sicuro di voler aggiornare il tuo stato civile?',
    description:
      'Se cambi il tuo stato civile, tutti i dati del tuo testamento verranno eliminati e dovrai ricominciare da capo.',
    cancel: 'Annulla',
    update: 'Aggiorna',
  },

  hyperlinks: {
    lastWill: 'Testamento',
    livingWill: 'Testamento biologico',
    powerOfAttorney: 'Mandato precauzionale',
  },
  signedDoc: {
    placeholder: {
      headingText: 'Carica dopo la firma',
      currentlySigned: 'Attualmente {{grammarFix}} {{docType}}',
      grammarFix: {
        livingWill: 'firmato e attivo',
        powerOfAttorney: 'firmato e attivo',
        lastWill: 'firmato e attivo',
        marriageContract: 'firmato e attivo',
        cohabitationAgreement: 'firmato e attivo',
      },
      doctype: {
        lastWill: 'testamento',
        livingWill: 'testamento biologico',
        powerOfAttorney: 'mandato precauzionale',
        marriageContract: 'contratto matrimoniale',
        cohabitationAgreement: 'contratto di convivenza',
      },
      helper: {
        dropFilesHere: 'Trascina il file qui o esplora il tuo dispositivo',
        browse: 'sfoglia',
        thoroughYourDevice: 'sul tuo dispositivo',
      },
    },
    reUpload: 'Trascina e rilascia o clicca per caricare nuovamente',
    updatedAt: 'Aggiornato il {{date}}',
  },
  shareDocument: {
    button: 'Condividi con il medico',
    title: 'Condividilo con il tuo medico di base',
    description:
      'Ti consigliamo di condividere la versione firmata del tuo testamento biologico con il tuo medico di base.',
    doAutomatically:
      'Condividi automaticamente quando aggiorno il testamento biologico',
  },
  finfinder: {
    title: 'Scegli il tuo consulente finanziario su FinFinder',
    button: 'Prenota una consulenza gratuita',
  },

  consulting: {
    title: 'Consulenza',
    description: 'Supporta i tuoi clienti nella pianificazione della pensione.',
    dashCardInfos: {
      livingWill: {
        title: 'Testamento biologico',
        description:
          'Regola a quali trattamenti medici il cliente acconsente o rifiuta quando non può più decidere autonomamente.',
      },
      powerOfAttorney: {
        title: 'Mandato precauzionale',
        description:
          'Definisce chi rappresenta legalmente il cliente nel peggiore dei casi, gestisce i suoi diritti e doveri nei confronti della famiglia e si occupa delle sue questioni finanziarie.',
      },
      lastWill: {
        title: 'Testamento',
        description:
          "Definisce i desideri del cliente riguardo l'eredità dei beni.",
      },
      ageAppropriate: {
        title: "Investimento 3a adeguato all'età",
        description:
          "Verifica in che misura il tuo cliente investe i beni 3a in modo adeguato all'età e prendi le misure appropriate se necessario.",
      },
      ahvCalculator: {
        title: 'Calcolatore AVS',
        description:
          'Il calcolatore AVS è una stima della pensione basata su un metodo di calcolo semplificato, non vincolante.',
      },
      lifeExpectancy: {
        title: 'Aspettativa di vita',
        description:
          "Calcola l'aspettativa di vita teorica del cliente basandosi su tabelle attuariali.",
      },
      getStarted: 'Inizia',
      unlock: 'Sblocca',
      createUser: 'Crea utente',
      createUserDescription:
        'Crea un utente per il tuo cliente, che potrà continuare a utilizzare indipendentemente da te per visualizzare documenti e apportare modifiche.',
    },
    pageTitle: 'Crea login cliente',
    createUserForm: {
      description:
        "Il tuo cliente riceverà un'email separata con le credenziali di accesso. Chiedi al tuo cliente di cambiare la password comunicata al primo accesso.",
    },
  },
  consultantDashboard: {
    pageName: 'Dashboard del consulente',
    title: 'Panoramica clienti',
    description:
      'Ottieni una panoramica del tuo portafoglio clienti e del loro stato in relazione alla pianificazione della pensione con gut geregelt.',
  },

  teamRoles: {
    owner: 'Proprietario',
    consultant: 'Consulente',
    admin: 'Admin',
  },

  financialAdvisorsModal: {
    title: 'Parla con un consulente finanziario vicino a te',
    description:
      'Per offrirti un facile accesso a esperti finanziari indipendenti nella tua zona, collaboriamo con FinFinder.',
  },
  legalAdvisorsModal: {
    title: 'Parla con un notaio vicino a te',
    description:
      'Per offrirti un facile accesso a esperti legali nella tua zona, collaboriamo con GetYouLawyer.',
  },

  complexFormModal: {
    title: 'Ti consigliamo di parlare con un notaio',
    description:
      'Il tuo caso è più complesso e quindi ti consigliamo vivamente di parlare con un esperto legale per chiarire la situazione e trovare la migliore soluzione per il tuo caso.',
    searchLabel: 'Cerca notaio',
    financialSearchLabel: 'Cerca consulente finanziario',
    continueLabel: 'Continua',
    requestConsultation: 'Richiedi consulenza',
  },

  financialConsultingTopics: {
    financialPlanning: 'Pianificazione finanziaria',
    pensionPlanning: 'Pianificazione pensionistica',
    taxPlanning: 'Consulenza fiscale',
    realEstate: 'Consulenza immobiliare',
    successionPlanning: 'Consulenza sulla successione',
  },

  legalConsultingTopics: {
    livingWill: 'Testamento biologico',
    powerOfAttorney: 'Mandato precauzionale',
    lastWill: 'Testamento',
    marriageContract: 'Contratto matrimoniale',
  },

  markAsDoneView: {
    intro: 'Il tuo efficace {{service}}',
    title: 'Deposita il tuo {{service}} legalmente valido',
    helperOne:
      'gut geregelt ti informa sui cambiamenti delle basi legali e fornisce anche impulsi di tanto in tanto, in modo che il documento legale in vigore continui a soddisfare le tue esigenze e desideri.',
    helperTwo:
      'Se in un secondo momento desideri una diversa regolamentazione, puoi aggiornare il documento legale in qualsiasi momento e gestire le tue questioni tramite "gut geregelt".',
    service: {
      livingWill: 'Testamento biologico',
      powerOfAttorney: 'Mandato precauzionale',
      lastWill: 'Testamento',
      marriageContract: 'Contratto matrimoniale',
      cohabitationAgreement: 'Contratto di convivenza',
    },
  },

  otp: {
    pageTitle: 'Verifica email',
    title: 'Codice di conferma inviato alla tua email',
    subtitle:
      'Ti abbiamo inviato un codice di conferma a 6 cifre via email a {{email}}. Inserisci il codice nel campo sottostante per confermare la tua email.',
    dontHaveCode: 'Non hai ricevuto il codice?',
    resendCode: 'Invia nuovamente il codice',
    returnToLogin: 'Torna al login',
    waitforResend: 'Attendi prima di richiedere nuovamente il codice.',
  },

  paxDisclaimer: {
    text: 'Pax ti permette di utilizzare gratuitamente i servizi di gut geregelt AG per creare vari documenti. I dati che inserisci nelle pagine web seguenti non saranno elaborati da Pax, ma da gut geregelt AG. Pax non ha accesso a questi dati.',
    privacyLink: "Leggi l'informativa sulla privacy di gut geregelt AG",
    accept: 'Ho capito',
  },
};

export default it;
