const outputDoc = {
  title: 'Directive Anticipée',
  from: 'de ',
  born: 'né le ',
  civilStatus: {
    single: 'Célibataire',
    married: 'Marié',
    widowed: 'Veuf',
    divorced: 'Divorcé',
    registered_partnership: 'Partenariat enregistré',
  },
  civil_status: 'État civil : {{civil_status}}',
  address: 'Adresse : {{address}}',
  declare: 'Je, {{first_name}} {{last_name}}, déclare ce qui suit :  ',
  paragraph1:
    "Dans le cas où je deviendrais incapable de discernement en raison d'une maladie ou d'un accident, je règle avec cette directive anticipée ma volonté juridiquement contraignante et mes souhaits concernant le traitement médical, les soins, la mort et le décès. Je désigne la/les personne(s) suivante(s) comme représentant(s) autorisé(s) : ",
  paragraph2:
    "La personne représentante met en œuvre ma volonté établie en cas d'incapacité de discernement et prend pour moi des décisions médicales et de soins. Elle doit être informée de mon état de santé ainsi que de l'évolution probable de la maladie. Je délie expressément l'équipe de soins du secret professionnel dans la mesure où cela est nécessaire pour une décision à prendre. La personne représentante doit être impliquée – dans la mesure du possible – avant que des décisions médicales ne soient prises. Si cette directive anticipée ne règle pas une question, la personne représentante décide selon ma volonté présumée.",
  paragraph3:
    "La personne mentionnée est également autorisée à consulter la documentation médicale ainsi que tout rapport d'autopsie éventuel après mon décès. ",
  repInformParagraph:
    "J'ai discuté de la mission avec la personne représentante le {{representative_inform_date}}. ",
  secondaryRepParagraph:
    'Dans le cas où la personne mandatée selon le chiffre 1 ne pourrait pas assumer sa tâche, je nomme comme personne de remplacement :  ',
  motivation: {
    title: 'Motivation et attitude de base',
    paragraph1: 'Je rédige cette directive anticipée ',
    takePrecautions: 'en bonne santé, mais avec une planification prévoyante.',
    makeProvisions:
      'avec un certain tableau clinique qui me conduit à prendre des précautions.',
    makeProvisionsForFuture:
      'après avoir atteint un certain âge, où des précautions à court terme doivent également être prises.',
    paragraph2:
      'Avec cette directive anticipée, je souhaite principalement atteindre que ',
    alleviateSuffering:
      "les possibilités médicales soient principalement utilisées pour soulager mes souffrances. La prolongation de ma vie à tout prix n'est pas prioritaire pour moi. J'accepte que le renoncement à un traitement médical puisse raccourcir ma vie.",
    exhaustedMedicalPossibilities:
      "toutes les possibilités médicales pour maintenir ma vie soient épuisées. Mes souffrances doivent être soulagées autant que possible. J'accepte que cela puisse être associé à des contraintes (par exemple, une conscience altérée).",
  },
  medical: {
    title: 'Directives médicales / Objectif du traitement ',
    paragraph1:
      "Si je me trouve dans l'une des situations suivantes, j'attends de l'équipe de soins le comportement suivant :",
    exhaustedMedicalPossibilities:
      "Je souhaite principalement un traitement efficace de la douleur et d'autres symptômes tels que l'anxiété. J'accepte que cela puisse être associé à des contraintes (par exemple, une altération de la conscience).",
    alleviateSuffering:
      "Je souhaite principalement conserver la conscience et ma capacité de communication. J'accepte que cela puisse compromettre le soulagement optimal de la douleur et d'autres symptômes tels que l'anxiété.",
  },
  forego_measures: {
    yes: "Je renonce à toutes les mesures qui n'ont pour effet que de prolonger la vie et les souffrances, si je deviens incapable de discernement en raison d'un événement aigu inattendu et s'il est impossible ou improbable, après une évaluation médicale approfondie, que je retrouve un jour ma capacité de discernement. ",
    no: "Je veux que toutes les mesures possibles soient mises en œuvre, si je deviens incapable de discernement en raison d'un événement aigu inattendu, même s'il est impossible ou improbable, après une évaluation médicale approfondie, que je retrouve un jour ma capacité de discernement. ",
  },
  revive_preference: {
    paragraph: "En cas d'arrêt cardiaque ou respiratoire, il doit y avoir",
    yes: 'une',
    no: 'aucune',
    paragraph1: 'réanimation.',
  },
  moreDetails: {
    treatment_goal_sudden_incapacity: {
      paragraph: "En cas d'incapacité soudaine (urgence) ",
      resuscitationAndTreatment:
        'je souhaite une réanimation et un traitement en soins intensifs (objectif du traitement : prolongation de la vie). ',
      treatedInIntensiveCareUnit:
        'je ne souhaite pas de réanimation, mais un traitement en soins intensifs. ',
    },
    suffering_relief_sudden_incapacity: {},
    treatment_goal_prolonged_incapacity: {
      paragraph: "En cas d'incapacité prolongée ",
      resuscitationAndTreatment:
        'je souhaite une réanimation et un traitement en soins intensifs (objectif du traitement : prolongation de la vie). ',
      treatedInIntensiveCareUnit:
        'je ne souhaite pas de réanimation, mais un traitement en soins intensifs.',
    },
    treatment_goal_permanent_incapacity: {
      paragraph: "En cas d'incapacité permanente ",
      resuscitationAndTreatment:
        'je souhaite une réanimation et un traitement en soins intensifs (objectif du traitement : prolongation de la vie). ',
      treatedInIntensiveCareUnit:
        'je ne souhaite pas de réanimation, mais un traitement en soins intensifs. ',
    },
  },
  revocation: {
    title: 'Révocation',
    paragraph1:
      'Je révoque par la présente toutes les directives anticipées antérieures.',
  },
  abilityToConsent: {
    title: 'Capacité à consentir ',
    paragraph1:
      "Je fais cette déclaration après mûre réflexion et en pleine responsabilité pour moi-même, ainsi qu'en étant conscient que, concernant les mesures médicales, mes médecins, soignants ou mandataires sont liés par la décision de la personne mandatée.",
    informedRepresentative:
      "J'ai discuté de cette directive avec les personnes mentionnées au début de la première page, qui peuvent confirmer qu'à ce moment-là, selon leur perception, j'étais capable de discernement et que le contenu correspond à ma volonté. ",
  },
};

export default outputDoc;
