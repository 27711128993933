import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { defaultLang } from '#/config';

import countryLabels from './de/countries';

import globalEn from './en/en';
import livingWill from './en/livingWill/livingWill';
import powerOfAttorney from './en/powerOfAttorney/powerOfAttorney';
import lastWill from './en/lastWill/lastWill';
import marriageContract from './en/marriageContract/marriageContract';
import imprint from './en/legals/imprint';
import termsConditions from './en/legals/tt';
import privacyPolicy from './en/legals/privacyPolicy';
import provisionLanding from './en/landings/provisionLanding';
import livingWillLanding from './en/landings/livingWillLanding';
import marriageContractLanding from './en/landings/marriageContractLanding';
import digitalFootprint from './en/digitalFootprint/digitalFootprint';
import importantInformation from './en/importantInformation/importantInformation';
import digitalAssets from './en/digitalAssets/digitalAssets';
import dfPhotosVideos from './en/dfPhotosVideos/dfPhotosVideos';
import cohabitationAgreementLanding from './en/landings/cohabitationAgreementLanding';
import cohabitationAgreement from './en/cohabitationAgreement/cohabitationAgreement';
import poaLanding from './en/landings/poaLanding';
import lastWillLanding from './en/landings/lastWillLanding';
import partnerWithUs from './en/landings/partnerWithUs';
import digitalFootprintLanding from './en/landings/digitalFootprint';
import registerAssets from './en/landings/registerAssets';
import pensionProvision from './en/pensionProvision/pensionProvision';
import pathnames from './en/pathnames';
import validations from './en/validations';
import toast_notifications from './en/toast_notifications';
import metadata from './en/metadata';
import guest from './en/guest';
import adminDashboard from './en/adminDashboard';
import grosseItern from './en/landings/grosseItern';
import agb from './en/legals/agb';
import preventiveCheckUp from './en/landings/preventiveCheckUp';
import aboutUs from './en/landings/aboutUs';
import assetsRegister from './en/assetsRegister/assetsRegister';
import financialPlanning from '#/locales/en/landings/financialPlanning';
import lsLandingMains from './en/landings/landingContent';

import globalDe from './de/de';
import livingWillDe from './de/livingWill/livingWill';
import lastWillDe from './de/lastWill/lastWill';
import powerOfAttorneyDe from './de/powerOfAttorney/powerOfAttorney';
import marriageContractDe from './de/marriageContract/marriageContract';
import imprintDe from './de/legals/imprint';
import ttDe from './de/legals/tt';
import ppDe from './de/legals/privacyPolicy';
import provisionLandingDe from './de/landings/provisionLanding';
import livingWillLandingDe from './de/landings/livingWillLanding';
import marriageContractLandingDe from './de/landings/marriageContractLanding';
import cohabitationAgreementLandingDe from './de/landings/cohabitationAgreementLanding';
import aboutUsDe from './de/landings/aboutUs';
import assetsRegisterDe from './de/assetsRegister/assetsRegister';
import digitalFootprintDE from './de/digitalFootprint/digitalFootprint';
import importantInformationDe from './de/importantInformation/importantInformation';
import digitalAssetsDe from './de/digitalAssets/digitalAssets';
import dfPhotosVideosDe from './de/dfPhotosVideos/dfPhotosVideos';
import cohabitationAgreementDe from './de/cohabitationAgreement/cohabitationAgreement';
import poaLandingDe from './de/landings/poaLanding';
import lastWillLandingDe from './de/landings/lastWillLanding';
import partnerWithUsDe from './de/landings/partnerWithUs';
import digitalFootprintLandingDe from './de/landings/digitalFootprint';
import registerAssetsDe from './de/landings/registerAssets';
import pensionProvisionDe from './de/pensionProvision/pensionProvision';
import pathnamesDe from './de/pathnames';
import validationsDe from './de/validations';
import toast_notificationsDe from './de/toast_notifications';
import agbDe from './de/legals/agb';
import metadataDe from './de/metadata';
import guestDe from './de/guest';
import adminDashboardDe from './de/adminDashboard';
import grosseIternDe from './de/landings/grosseItern';
import preventiveCheckUpDe from './de/landings/preventiveCheckUp';
import financialPlanningDe from '#/locales/de/landings/financialPlanning';
import lsLandingMainsDe from './de/landings/landingContent';

import globalIt from './it/it';
import livingWillIt from './it/livingWill/livingWill';
import lastWillIt from './it/lastWill/lastWill';
import powerOfAttorneyIt from './it/powerOfAttorney/powerOfAttorney';
import marriageContractIt from './it/marriageContract/marriageContract';
import imprintIt from './it/legals/imprint';
import ttIt from './it/legals/tt';
import privacyPolicyIt from './it/legals/privacyPolicy';
import provisionLandingIt from './it/landings/provisionLanding';
import livingWillLandingIt from './it/landings/livingWillLanding';
import marriageContractLandingIt from './it/landings/marriageContractLanding';
import digitalFootprintIt from './it/digitalFootprint/digitalFootprint';
import importantInformationIt from './it/importantInformation/importantInformation';
import digitalAssetsIt from './it/digitalAssets/digitalAssets';
import dfPhotosVideosIt from './it/dfPhotosVideos/dfPhotosVideos';
import cohabitationAgreementLandingIt from './it/landings/cohabitationAgreementLanding';
import cohabitationAgreementIt from './it/cohabitationAgreement/cohabitationAgreement';
import poaLandingIt from './it/landings/poaLanding';
import lastWillLandingIt from './it/landings/lastWillLanding';
import partnerWithUsIt from './it/landings/partnerWithUs';
import digitalFootprintLandingIt from './it/landings/digitalFootprint';
import registerAssetsIt from './it/landings/registerAssets';
import pensionProvisionIt from './it/pensionProvision/pensionProvision';
import pathnamesIt from './it/pathnames';
import validationsIt from './it/validations';
import toast_notificationsIt from './it/toast_notifications';
import agbIt from './it/legals/agb';
import metadataIt from './it/metadata';
import guestIt from './it/guest';
import adminDashboardIt from './it/adminDashboard';
import grosseIternIt from './it/landings/grosseItern';
import preventiveCheckUpIt from './it/landings/preventiveCheckUp';
import aboutUsIt from './it/landings/aboutUs';
import assetsRegisterIt from './it/assetsRegister/assetsRegister';
import financialPlanningIt from '#/locales/it/landings/financialPlanning';
import lsLandingMainsIt from './it/landings/landingContent';

import globalFe from './fr/fr';
import livingWillLandingFe from './fr/landings/livingWillLanding';
import lastWillLandingFe from './fr/landings/lastWillLanding';
import poaLandingFe from './fr/landings/poaLanding';
import provisionLandingFe from './fr/landings/provisionLanding';
import lastWillFe from './fr/lastWill/lastWill';
import livingWillFe from './fr/livingWill/livingWill';
import powerOfAttorneyFe from './fr/powerOfAttorney/powerOfAttorney';
import imprintFe from './fr/legals/imprint';
import ttFe from './fr/legals/tt';
import ppFe from './fr/legals/privacyPolicy';
import partnerWithUsFe from './fr/landings/partnerWithUs';
import digitalFootprintFe from './fr/digitalFootprint/digitalFootprint';
import registerAssetsFe from './fr/landings/registerAssets';
import dfPhotosVideosFe from './fr/dfPhotosVideos/dfPhotosVideos';
import pensionProvisionFe from './fr/pensionProvision/pensionProvision';
import pathnamesFe from './fr/pathnames';
import validationsFe from './fr/validations';
import toast_notificationsFe from './fr/toast_notifications';
import agbFe from './fr/legals/agb';
import metadataFe from './fr/metadata';
import guestFe from './fr/guest';
import adminDashboardFe from './fr/adminDashboard';
import grosseIternFe from './fr/landings/grosseItern';
import aboutUsFe from './fr/landings/aboutUs';
import assetsRegisterFe from './fr/assetsRegister/assetsRegister';
import importantInformationFe from './fr/importantInformation/importantInformation';
import digitalAssetsFe from './fr/digitalAssets/digitalAssets';
import cohabitationAgreementFe from './fr/cohabitationAgreement/cohabitationAgreement';
import cohabitationAgreementLandingFe from './fr/landings/cohabitationAgreementLanding';
import marriageContractLandingFe from './fr/landings/marriageContractLanding';
import digitalFootprintLandingFe from './fr/landings/digitalFootprint';
import preventiveCheckUpFe from './fr/landings/preventiveCheckUp';
import marriageContractFe from './fr/marriageContract/marriageContract';
import financialPlanningFe from '#/locales/fr/landings/financialPlanning';
import lsLandingMainsFe from './fr/landings/landingContent';

const enLocales = {
  global: globalEn,
  livingWill,
  lastWill,
  powerOfAttorney,
  marriageContract,
  imprint,
  termsConditions,
  privacyPolicy,
  provisionLanding,
  livingWillLanding,
  marriageContractLanding,
  cohabitationAgreementLanding,
  digitalFootprint,
  importantInformation,
  digitalAssets,
  dfPhotosVideos,
  cohabitationAgreement,
  poaLanding,
  lastWillLanding,
  partnerWithUs,
  pensionProvision,
  pathnames,
  validations,
  toast_notifications,
  agb,
  metadata,
  guest,
  adminDashboard,
  digitalFootprintLanding,
  registerAssets,
  grosseItern,
  preventiveCheckUp,
  aboutUs,
  assetsRegister,
  financialPlanning,
  lsLandingMains,
};

const deLocales = {
  global: globalDe,
  livingWill: livingWillDe,
  lastWill: lastWillDe,
  powerOfAttorney: powerOfAttorneyDe,
  marriageContract: marriageContractDe,
  digitalFootprint: digitalFootprintDE,
  importantInformation: importantInformationDe,
  digitalAssets: digitalAssetsDe,
  dfPhotosVideos: dfPhotosVideosDe,
  imprint: imprintDe,
  termsConditions: ttDe,
  privacyPolicy: ppDe,
  provisionLanding: provisionLandingDe,
  livingWillLanding: livingWillLandingDe,
  marriageContractLanding: marriageContractLandingDe,
  assetsRegister: assetsRegisterDe,
  cohabitationAgreement: cohabitationAgreementDe,
  cohabitationAgreementLanding: cohabitationAgreementLandingDe,
  poaLanding: poaLandingDe,
  lastWillLanding: lastWillLandingDe,
  partnerWithUs: partnerWithUsDe,
  aboutUs: aboutUsDe,
  pensionProvision: pensionProvisionDe,
  pathnames: pathnamesDe,
  validations: validationsDe,
  toast_notifications: toast_notificationsDe,
  agb: agbDe,
  metadata: metadataDe,
  guest: guestDe,
  adminDashboard: adminDashboardDe,
  digitalFootprintLanding: digitalFootprintLandingDe,
  registerAssets: registerAssetsDe,
  grosseItern: grosseIternDe,
  preventiveCheckUp: preventiveCheckUpDe,
  countries: countryLabels,
  financialPlanning: financialPlanningDe,
  lsLandingMains: lsLandingMainsDe,
};

const itLocales = {
  global: globalIt,
  livingWill: livingWillIt,
  lastWill: lastWillIt,
  powerOfAttorney: powerOfAttorneyIt,
  marriageContract: marriageContractIt,
  imprint: imprintIt,
  termsConditions: ttIt,
  privacyPolicy: privacyPolicyIt,
  provisionLanding: provisionLandingIt,
  livingWillLanding: livingWillLandingIt,
  marriageContractLanding: marriageContractLandingIt,
  digitalFootprint: digitalFootprintIt,
  importantInformation: importantInformationIt,
  digitalAssets: digitalAssetsIt,
  dfPhotosVideos: dfPhotosVideosIt,
  cohabitationAgreement: cohabitationAgreementIt,
  cohabitationAgreementLanding: cohabitationAgreementLandingIt,
  poaLanding: poaLandingIt,
  lastWillLanding: lastWillLandingIt,
  partnerWithUs: partnerWithUsIt,
  pensionProvision: pensionProvisionIt,
  pathnames: pathnamesIt,
  validations: validationsIt,
  toast_notifications: toast_notificationsIt,
  agb: agbIt,
  metadata: metadataIt,
  guest: guestIt,
  adminDashboard: adminDashboardIt,
  digitalFootprintLanding: digitalFootprintLandingIt,
  registerAssets: registerAssetsIt,
  grosseItern: grosseIternIt,
  preventiveCheckUp: preventiveCheckUpIt,
  aboutUs: aboutUsIt,
  assetsRegister: assetsRegisterIt,
  financialPlanning: financialPlanningIt,
  lsLandingMains: lsLandingMainsIt,
};

const frLocales = {
  global: globalFe,
  livingWill: livingWillFe,
  lastWill: lastWillFe,
  powerOfAttorney: powerOfAttorneyFe,
  imprint: imprintFe,
  termsConditions: ttFe,
  privacyPolicy: ppFe,
  provisionLanding: provisionLandingFe,
  livingWillLanding: livingWillLandingFe,
  poaLanding: poaLandingFe,
  lastWillLanding: lastWillLandingFe,
  partnerWithUs: partnerWithUsFe,
  pensionProvision: pensionProvisionFe,
  pathnames: pathnamesFe,
  validations: validationsFe,
  toast_notifications: toast_notificationsFe,
  agb: agbFe,
  metadata: metadataFe,
  guest: guestFe,
  adminDashboard: adminDashboardFe,
  digitalFootprint: digitalFootprintFe,
  registerAssets: registerAssetsFe,
  dfPhotosVideos: dfPhotosVideosFe,
  grosseItern: grosseIternFe,
  aboutUs: aboutUsFe,
  assetsRegister: assetsRegisterFe,
  importantInformation: importantInformationFe,
  digitalAssets: digitalAssetsFe,
  cohabitationAgreement: cohabitationAgreementFe,
  cohabitationAgreementLanding: cohabitationAgreementLandingFe,
  preventiveCheckUp: preventiveCheckUpFe,
  marriageContractLanding: marriageContractLandingFe,
  digitalFootprintLanding: digitalFootprintLandingFe,
  marriageContract: marriageContractFe,
  financialPlanning: financialPlanningFe,
  lsLandingMains: lsLandingMainsFe,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      caches: [],
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      // ... other detection options
    },
    debug: false,
    resources: {
      en: { translations: enLocales },
      de: { translations: deLocales },
      fr: { translations: frLocales },
      it: { translations: itLocales },
    },
    // lng: localStorage.getItem('i18nextLng') || defaultLang.value,
    fallbackLng: defaultLang.value,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  })

  .then((r) => r);
