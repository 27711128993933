import { Box, Stack, Typography, Link, Button } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Link as ScrollTo, scroller } from 'react-scroll';
import { useEffect } from 'react';
import { footerLinks } from '#/components/pages/Landing/landingData';
import useLocales from '#/hooks/useLocales';
import useLocalStorage from '#/lib/hooks/useLocalStorage';
import useAuth from '#/lib/hooks/useAuth';
import { getSubdomain } from '#/utils/helpers';
import {
  useGetPartnerSettings,
  useGetPublicPartnerSettings,
} from '#/lib/api/partnerQueries';

const contactInfo = {
  title: 'contact',
  mobile: '+41 61 589 65 07',
  email: 'info@gutgeregelt.ch',
  address1: 'gut geregelt AG',
  address2: 'Gewerbestrasse 12',
  address3: 'CH-4450 Sissach',
  contact: '/kontakt',
};

const Footer = () => {
  const { translate } = useLocales();
  const { pathname, state } = useLocation();

  const { user } = useAuth();
  const [ggPartnerKey] = useLocalStorage('gg_partner_key', null);
  const validSubdomain = getSubdomain(window.location.href);

  const isAdminLoggedIn =
    user?.partner?.role === 'admin' || user?.partner?.role === 'owner';

  const { data: byAdmin } = useGetPartnerSettings(isAdminLoggedIn);
  const { data: partnerTheme } = useGetPublicPartnerSettings(
    ggPartnerKey,
    !!validSubdomain
  );

  const removeSolutions =
    byAdmin?.hide_solution_menu || partnerTheme?.hide_solution_menu;

  const footerTitle = byAdmin?.footer_title || partnerTheme?.footer_title;
  const footerText = byAdmin?.footer_text || partnerTheme?.footer_text;

  useEffect(() => {
    if (state?.pricing) {
      scroller.scrollTo('pricing', {
        smooth: true,
      });
    }
  }, [state]);

  return (
    <Box
      sx={{
        backgroundColor: '#F8F8F8',
        py: 5,
        // flexDirection: {
        //   xs: 'column',
        //   md: 'row',
        // },
        // gap: {
        //   xs: 5,
        //   md: 10,
        // },
      }}
    >
      <Stack
        direction="row"
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
        justifyContent="space-between"
        sx={{
          maxWidth: '1240px',
          margin: '0 auto',
        }}
      >
        {footerTitle && (
          <Stack
            sx={{
              textAlign: {
                xs: 'center',
                md: 'left',
              },
              pb: '50px',
            }}
          >
            <Typography variant="h4">{footerTitle}</Typography>
            <Typography variant="body1">{footerText}</Typography>
          </Stack>
        )}
        {footerLinks
          .filter(
            (link) =>
              !(
                removeSolutions &&
                link.title === 'provisions' &&
                link.links.length > 1
              )
          )
          .map((link, i) => (
            <Stack
              key={i}
              spacing={{
                xs: 1,
                md: 4,
              }}
              sx={{
                textAlign: {
                  xs: 'center',
                  md: 'left',
                },
                pb: '50px',
              }}
            >
              <Typography variant="subtitle2" textTransform="uppercase">
                {String(translate(`global.footer.${link.i18nKey}.title`))}
              </Typography>
              <Stack spacing={1}>
                {link.links.map((item, i) => (
                  <Link
                    component={RouterLink}
                    to={item.link}
                    key={i}
                    color="inherit"
                    sx={{
                      textDecoration: 'none',
                    }}
                    state={
                      item.title === 'Pricing' && pathname !== '/main'
                        ? {
                            pricing: true,
                          }
                        : null
                    }
                  >
                    <Button
                      variant="text"
                      color="inherit"
                      sx={{ textTransform: 'none' }}
                    >
                      {item.title === 'Pricing' && pathname === '/main' ? (
                        <ScrollTo to={item.title} smooth duration={500}>
                          <Typography variant="body1">
                            {String(
                              translate(
                                `global.footer.${link.i18nKey}.${item.i18nKey}`
                              )
                            )}
                          </Typography>
                        </ScrollTo>
                      ) : (
                        <Typography variant="body1">
                          {String(
                            translate(
                              `global.footer.${link.i18nKey}.${item.i18nKey}`
                            )
                          )}
                        </Typography>
                      )}
                    </Button>
                  </Link>
                ))}
              </Stack>
            </Stack>
          ))}
        <Stack
          spacing={{
            xs: 1,
            md: 4,
          }}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}
        >
          <Typography variant="subtitle2" textTransform="uppercase">
            {String(translate(`global.footer.${contactInfo.title}`))}
          </Typography>
          <Stack spacing={1}>
            <Typography variant="body1">{contactInfo.email}</Typography>
            <Typography variant="body1">{contactInfo.mobile}</Typography>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {contactInfo.address1}
            </Typography>
            <Typography variant="body1">{contactInfo.address2}</Typography>
            <Typography variant="body1">{contactInfo.address3}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Footer;
