const digitalFootprintLanding = {
  mainBanner: {
    title: 'Regola e proteggi la tua eredità digitale in un caveau sicuro',
    subtitle:
      'Assicurati che tutti i tuoi dati elettronici o beni, dispositivi e assicurazioni siano protetti e possano essere resi accessibili a persone autorizzate in caso di decesso.',
    buttonLabel: "Proteggi l'eredità",
  },
  youtubeTutorial: 'Come preservare la tua eredità digitale?',
  aboutCards: {
    documentAccounts: {
      title: 'Documenta i tuoi conti',
      description:
        'Trasferisci facilmente tutti i conti dal tuo gestore di password in gut geregelt. Non preoccuparti. Non vogliamo assolutamente le password!',
    },
    connectDigitalValues: {
      title: 'Mappa i valori digitali',
      description:
        "Elenca tutti i tuoi portafogli digitali, domini e altri valori e descrivi dove e come la tua famiglia può trovare le password per ottenere l'accesso.",
    },
    preserveDigitalData: {
      title: 'Conserva i dati digitali',
      description:
        'Organizza e conserva dati come immagini o video, musica, documenti importanti e altri dati in un caveau sicuro.',
    },
  },
  subBanner: {
    title: 'Assicurati di lasciare tutto pulito e ordinato',
    subtitle:
      'A meno che non sia espressamente stabilito nel tuo testamento o contratto testamentario, i tuoi beni digitali passeranno ai tuoi eredi legali in caso di decesso. Dovresti quindi decidere in vita cosa deve essere cancellato o trasferito dopo la tua morte.',
    buttonLabel: "Regola l'impronta digitale",
  },
  testimonials: {
    testimonial1: {
      name: 'Patrick Zesiger',
      text: "L'eredità digitale di gut geregelt mi ha aiutato a mantenere in ordine le mie questioni digitali. Un must assoluto per chiunque faccia cose nel mondo digitale!",
    },
    testimonial2: {
      name: 'Sara Becker',
      text: 'La certezza che la mia famiglia non debba preoccuparsi di sistemare tutto e che tutti i miei canali social possano essere chiusi mi dà una buona sensazione. Super facile da configurare. Lo consiglio vivamente.',
    },
    testimonial3: {
      name: 'Luisa Stauffer',
      text: "Ho recentemente impostato l'eredità digitale con e per mio padre e mia madre. È stato molto veloce e semplice. Anche mio padre è stato felice che finalmente ce ne siamo occupati.",
    },
  },
  newsletter: {
    title: 'Diventa ben regolato con la tua eredità digitale',
    buttonLabel: 'Organizza ora',
  },
  faq: {
    questions: {
      question1: "Cos'è un'eredità digitale e perché è importante?",
      question2:
        "Quali tipi di beni digitali possono essere inclusi nell'eredità digitale?",
      question3: 'Come funziona il servizio per le eredità digitali?',
      question4:
        'Come posso assicurarmi che i miei cari abbiano accesso alla mia eredità digitale?',
      question5: 'Cosa succede ai miei beni digitali quando muoio?',
      question6:
        'Quanto sono sicure le mie informazioni personali con il vostro servizio?',
      question7: 'Posso aggiornare o modificare la mia eredità digitale?',
      question8:
        "Quali aspetti legali dovrebbero essere considerati nella gestione di un'eredità digitale?",
      question9:
        'Cosa succede ai miei abbonamenti online e conti dopo la mia morte?',
      question10:
        'Come supporta il vostro servizio la gestione di documenti assicurativi e bancari?',
      question11: 'Gut geregelt memorizza password o altri dati di accesso?',
    },
    answers: {
      answer1:
        "L'eredità digitale comprende tutti i beni digitali e le informazioni memorizzate online di una persona. Questo può variare da account di social media a abbonamenti online fino a portafogli di criptovalute. È importante gestire l'eredità digitale per garantire che le tue questioni digitali siano gestite correttamente dopo la tua morte e per facilitare l'accesso dei tuoi cari a informazioni importanti.",
      answer2:
        "Il nostro servizio ti consente di registrare una varietà di beni digitali, inclusi account di social media, abbonamenti online, portafogli di criptovalute, domini, gestori di password, dispositivi elettronici e molto altro. Puoi documentare tutti i beni digitali rilevanti e lasciare istruzioni su cosa dovrebbe accadere loro dopo la tua morte e come ottenere l'accesso.",
      answer3:
        'Il nostro servizio ti offre una piattaforma facile da usare, dove puoi registrare, gestire e stabilire istruzioni per la gestione dei tuoi beni digitali dopo la tua morte. Puoi stabilire chi dovrebbe avere accesso a quali informazioni e definire cosa dovrebbe accadere ai tuoi beni digitali.',
      answer4:
        'Con il nostro servizio, puoi nominare persone specifiche che avranno accesso alla tua eredità digitale. Puoi anche lasciare istruzioni dettagliate su come i tuoi beni digitali dovrebbero essere gestiti, per garantire che i tuoi cari abbiano accesso a informazioni importanti.',
      answer5:
        "Dopo la tua morte, le persone da te nominate avranno accesso al tuo account gut geregelt presentando il certificato di morte e la procura dell'esecutore testamentario. In questo modo, garantisci che possano gestire i tuoi beni digitali secondo le tue istruzioni. Puoi stabilire se determinati account devono essere cancellati, continuati o trasferiti a persone specifiche. Il nostro servizio garantisce che le tue questioni digitali siano gestite secondo i tuoi desideri.",
      answer6:
        'Prendiamo molto sul serio la sicurezza delle tue informazioni personali. Il nostro servizio utilizza misure di sicurezza standard del settore per proteggere i tuoi dati, incluse tecnologie di crittografia e restrizioni di accesso. Inoltre, non memorizziamo dati sensibili come password, saldi di conti e simili.',
      answer7:
        'Sì, puoi aggiornare o modificare la tua eredità digitale in qualsiasi momento. La nostra piattaforma ti consente di aggiungere nuovi beni, eliminare o modificare informazioni esistenti e lasciare nuove istruzioni.',
      answer8:
        "Nella gestione di un'eredità digitale, dovrebbero essere considerati aspetti legali come la creazione di un testamento e la determinazione degli eredi. È consigliabile ottenere consulenza legale per garantire che le tue questioni digitali siano gestite secondo le leggi vigenti.",
      answer9:
        'Dopo la tua morte, i tuoi abbonamenti online e conti possono essere gestiti secondo le tue istruzioni. Puoi stabilire se determinati abbonamenti devono essere cancellati, conti eliminati o trasferiti a persone specifiche.',
      answer10:
        "Il nostro servizio offre funzionalità per l'organizzazione delle tue relazioni assicurative e bancarie. Puoi indicare dove si trovano determinati documenti. Supportiamo anche la creazione automatica di lettere di cancellazione per polizze assicurative dopo la tua morte, una volta che un certificato di morte e una procura dell'esecutore testamentario sono stati presentati.",
      answer11:
        'No. Lo sottolineiamo espressamente e offriamo persino supporto affinché tu, come cliente, NON memorizzi o visualizzi password in gut geregelt. Invece, ti incoraggiamo a memorizzare separatamente i dati di accesso per i conti rilevanti e a conservarli in un luogo sicuro a cui la tua famiglia ha accesso. Questo luogo sicuro lo documenti per i tuoi cari in gut geregelt.',
    },
  },
  situations: {
    'tod-verwandter': {
      content: {
        main: {
          title: 'Eredità digitale',
          description:
            "L'eredità digitale regola cosa succede ai tuoi account online, ai social network e ai dati digitali dopo la tua morte. Decidi tu cosa cancellare, trasferire o continuare.",
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Oggi lasciamo molte tracce digitali. Regolando la tua eredità digitale, impedisci che i dati sensibili rimangano incontrollati in rete e sollevi i tuoi parenti da questo peso.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Controllo sui tuoi dati digitali',
              'Protezione contro il furto di identità',
              'Sollievo per i tuoi parenti',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      },
    },

    pensionierung: {
      content: {
        main: {
          title: 'Eredità digitale',
          description:
            'La tua vita digitale lascia tracce - dagli account online ai social media. Con una regolamentazione per la tua eredità digitale, determini cosa succede ai tuoi dati dopo la tua morte.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              "Nell'era digitale, è importante regolare anche l'eredità digitale per garantire la gestione dei dati sensibili.",
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Protezione della tua identità digitale',
              "Prevenzione dell'abuso",
              'Sollievo per i parenti',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      },
    },

    unternehmensgruendung: {
      content: {
        main: {
          title: 'Eredità digitale',
          description:
            "L'eredità digitale regola cosa succede ai tuoi account online, ai social network e ai dati digitali dopo la tua morte. Decidi tu cosa cancellare, trasferire o continuare.",
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Oggi lasciamo molte tracce digitali. Regolando la tua eredità digitale, impedisci che i dati sensibili rimangano incontrollati in rete e sollevi i tuoi parenti da questo peso.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Controllo sui tuoi dati digitali',
              'Protezione contro il furto di identità',
              'Sollievo per i tuoi parenti',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      },
    },
  },
};

export default digitalFootprintLanding;
