const poaLanding = {
  mainBanner: {
    intro: 'Créer une procuration en ligne :',
    title: 'Légalement sûr, en 5 minutes',
    subtitle:
      "Sans une procuration, votre conjoint ou votre famille ne peut pas prendre de décisions pour vous en cas d'urgence. Déterminez maintenant qui est légalement autorisé à agir en votre nom.",
    buttonLabel: 'Créer une procuration',
  },
  youtubeTutorial: 'Pourquoi une procuration est-elle importante ?',
  aboutCards: {
    guaranteeForPower: {
      title: 'Garantie de pouvoir',
      description:
        'Permettez à vos proches de prendre des décisions dans votre meilleur intérêt lorsque vous ne pouvez pas les prendre vous-même.',
    },
    mutualProtection: {
      title: 'Protection mutuelle',
      description:
        "Protégez l'intégrité de vos biens en vous assurant que les décisions importantes pour la famille ne peuvent être prises que par des membres de la famille.",
    },
    clarificationForFamily: {
      title: 'Clarté pour la famille',
      description:
        'Évitez les discussions inutiles et assurez la clarté pour les survivants.',
    },
  },
  subBanner: {
    title: 'Bien organisé avec une procuration conforme à la loi',
    subtitle:
      'Avec gut geregelt, vous créez rapidement votre procuration, pouvez obtenir des conseils juridiques sur des sujets sensibles ou des questions ouvertes et faites une faveur à vos proches.',
    buttonLabel: 'Créer gratuitement maintenant',
  },
  situations: {
    'geburt-eines-kindes': {
      content: {
        main: {
          title: 'Procuration',
          description:
            "Une procuration détermine qui prendra les décisions pour vous et votre famille si vous n'êtes plus en mesure de le faire vous-même. Protégez-vous et votre enfant avec des dispositions claires.",
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              "Avec la naissance d'un enfant vient une responsabilité supplémentaire. Une procuration garantit que votre enfant sera bien pris en charge même en cas d'urgence.",
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              "Déterminez qui s'occupera de votre enfant",
              'Sécurité juridique pour votre famille',
              "Protection en cas de maladie ou d'urgence",
            ],
          },
        ],
      },
      actions: {
        learnMore: 'En savoir plus',
        startProcess: 'Démarrer le processus',
      },
    },
    immobilienkauf: {
      content: {
        main: {
          title: 'Procuration',
          description:
            "Une procuration détermine qui s'occupera de vos biens et de vos affaires personnelles si vous n'êtes plus en mesure de le faire vous-même. Cela protège votre maison et votre famille.",
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              "Avec l'achat d'un bien immobilier vient une responsabilité accrue. Une procuration garantit que votre propriété reste protégée même en cas d'urgence et que vos souhaits sont respectés.",
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              "Protection de votre maison en cas de maladie ou d'accident",
              'Sécurité pour votre famille',
              'Clarté juridique pour les urgences',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'En savoir plus',
        startProcess: 'Démarrer le processus',
      },
    },
    pensionierung: {
      content: {
        main: {
          title: 'Procuration',
          description:
            "La procuration spécifie qui gérera vos affaires si vous n'êtes plus en mesure de le faire vous-même. Cela garantit que votre vie et vos biens sont gérés selon vos souhaits.",
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              "Avec l'âge de la retraite, il faut aussi déterminer qui s'occupera de vos affaires financières et personnelles en cas d'urgence.",
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              "Dispositions claires en cas d'incapacité",
              'Protection des biens et des droits',
              'Soulagement pour la famille',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'En savoir plus',
        startProcess: 'Démarrer le processus',
      },
    },
    unternehmensgruendung: {
      content: {
        main: {
          title: 'Procuration',
          description:
            "Une procuration détermine qui reprendra vos affaires professionnelles et privées si vous n'êtes plus en mesure de le faire vous-même. Cela assure la continuité de votre entreprise et protège votre famille.",
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              "En tant qu'entrepreneur, vous portez une grande responsabilité – pas seulement pour vous-même, mais aussi pour vos employés et partenaires commerciaux. Une procuration garantit que votre entreprise reste opérationnelle même en temps de crise.",
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              "Protection de l'entreprise en cas de maladie ou d'accident",
              "Continuité de la gestion en cas d'urgence",
              'Soulagement pour la famille',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'En savoir plus',
        startProcess: 'Démarrer le processus',
      },
    },
    scheidung: {
      content: {
        main: {
          title: 'Procuration',
          description:
            "Une procuration spécifie qui gérera vos affaires personnelles et financières si vous n'êtes plus en mesure de le faire. Particulièrement après un divorce, il est important de redéfinir ces dispositions.",
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              'Après un divorce, de nombreux cadres juridiques changent souvent. Une procuration mise à jour garantit que vos souhaits sont pris en compte dans votre nouvelle situation de vie.',
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Nouvelle organisation de vos affaires personnelles',
              'Protection en cas de maladie',
              "Clarté et sécurité pour l'avenir",
            ],
          },
        ],
      },
      actions: {
        learnMore: 'En savoir plus',
        startProcess: 'Démarrer le processus',
      },
    },
  },
  newsletter: {
    title: 'Soyez bien organisé avec votre procuration',
    buttonLabel: 'Créer maintenant',
  },
  faq: {
    questions: {
      question1: "Qu'est-ce qu'une procuration ?",
      question2: "Quand l'incapacité s'applique-t-elle ?",
      question3:
        "Que se passe-t-il si je deviens incapable et que je n'ai pas de procuration ?",
      question4: 'Que dois-je inclure dans la procuration ?',
      question5: 'Comment créer ma procuration ?',
      question6: 'Comment choisir mon représentant ?',
      question7: "Qu'est-ce que la KESB et quel est son rôle ?",
      question8: 'Puis-je ajuster ma procuration quand je veux ?',
      question9: 'Puis-je révoquer ma procuration ?',
      question10:
        'Puis-je inclure mes souhaits de traitement médical pour une urgence dans la procuration ?',
      question11:
        "Y a-t-il d'autres documents que je devrais créer en plus de la procuration ?",
      question12: "Comment gut geregelt m'aide-t-il avec ma procuration ?",
    },
    answers: {
      answer1:
        "Dans une procuration, vous spécifiez qui représentera vos intérêts dans les affaires personnelles et financières en cas d'incapacité. Avec une procuration, vous vous assurez que vos intérêts sont défendus et que vos souhaits sont respectés.",
      answer2:
        "L'incapacité peut survenir, par exemple, en raison d'une maladie grave comme la démence, après un AVC ou suite à un accident grave entraînant un coma.",
      answer3:
        'Cela dépend de votre état civil, marié ou en partenariat enregistré, ou vivant en concubinage.',
      answer4:
        'Une procuration comprend trois domaines : les soins, les soins financiers et les transactions juridiques. Vous pouvez attribuer tous les domaines au même représentant ou les répartir entre plusieurs représentants.',
      answer5:
        "Vous pouvez créer votre procuration de manière indépendante ; n'oubliez pas de la dater et de la signer. Alternativement, vous pouvez la créer avec un notaire et la faire certifier publiquement.",
      answer6:
        'Vous pouvez choisir une personne pour les trois domaines ou en nommer plusieurs. Il est crucial que vous ayez une confiance totale en cette personne.',
      answer7:
        "L'Autorité de protection de l'enfant et de l'adulte (KESB) assure la protection des enfants et des adultes vulnérables. Dans le cas de la procuration, la KESB intervient lorsqu'elle est informée de l'incapacité de discernement d'une personne.",
      answer8:
        "Oui, à la seule condition que vous soyez capable de discernement au moment de l'adaptation. Une nouvelle procuration remplace la dernière version.",
      answer9:
        'Oui, vous pouvez annuler votre procuration à tout moment en détruisant le document.',
      answer10:
        'Oui, vous pouvez. Cependant, il est également judicieux de créer une directive anticipée séparément.',
      answer11:
        'Oui, le {{lastWill}} et la {{livingWill}} sont deux autres documents importants que vous devriez rédiger rapidement.',
      answer12:
        'Chez gut geregelt, nous vous aidons à créer votre procuration de manière fluide et légale.',
    },
    hyperlinks: {
      lastWill: 'Testament',
      livingWill: 'Directive anticipée',
      powerOfAttorney: 'Procuration',
    },
  },
};

export default poaLanding;
