import { IQuestion } from '#/types/globalTypes';

const powerOfTrustQuestions: IQuestion[] = [
  {
    question: 'La mia persona di fiducia per me come persona è:',
  },
  {
    question:
      "Assumere la responsabilità per un'altra persona è spesso associato a un notevole impegno. Vuoi pagare un compenso alla tua persona di fiducia?",
    options: [
      {
        label: 'Sì, compenso secondo gli standard locali e di settore',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'Se la persona sopra menzionata non è disponibile, desidero nominare la seguente persona come mia persona di fiducia:',
  },
  {
    question:
      "Assumere la responsabilità per un'altra persona è spesso associato a un notevole impegno. Vuoi pagare un compenso alla tua persona di fiducia alternativa?",
    options: [
      {
        label: 'Sì, compenso secondo gli standard locali e di settore',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question: 'Hai figli sotto i 18 anni?',
    options: [
      {
        label: 'Sì',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question: 'Chi dovrebbe essere la persona di fiducia per i tuoi figli?',
  },
  {
    question: 'Ruolo: ',
  },
  {
    question:
      'Vuoi pagare una tariffa alla tua persona di fiducia per essere responsabile dei tuoi figli?',
    options: [
      {
        label: 'Sì, compenso secondo gli standard locali e di settore',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'Se questa persona non è disponibile, desidero che la seguente persona sia responsabile dei miei figli:',
  },
  {
    question: 'Ruolo: ',
  },
  {
    question:
      'Vuoi pagare una tariffa alla tua persona di fiducia alternativa per la cura dei tuoi figli?',
    options: [
      {
        label: 'Sì, compenso secondo gli standard locali e di settore',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question: 'Affido la gestione del mio patrimonio alla seguente persona:',
  },
  {
    question:
      'Vuoi pagare una tariffa alla tua persona di fiducia per la gestione del tuo patrimonio?',
    options: [
      {
        label: 'Sì, compenso secondo gli standard locali e di settore',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'Se questa persona non è disponibile, desidero che la seguente persona sia responsabile del mio patrimonio:',
  },
  {
    question:
      'Vuoi pagare un compenso alla tua persona di fiducia alternativa per la gestione del tuo patrimonio?',
    options: [
      {
        label: `Sì,
                compenso in conformità con gli standard locali e di settore`,
      },
      {
        label: 'No',
      },
    ],
  },
];

export default powerOfTrustQuestions;
