const validations = {
  user: {
    name: 'Nome è richiesto',
    first_name: 'Nome è richiesto',
    last_name: 'Cognome è richiesto',
    email: 'E-mail è richiesta',
    invalidEmail: 'E-mail non valida',
    civil_status: 'Stato civile è richiesto',
    gender: 'Per favore seleziona il tuo genere',
    place_of_citinzenship: 'Luogo di cittadinanza è richiesto',
    date_of_birth: {
      required: 'Data di nascita è richiesta',
      max: 'Il rappresentante deve avere almeno 18 anni',
    },
    mobile_phone: {
      required: 'Numero di cellulare è richiesto',
      min: 'Il numero di cellulare è troppo corto',
      max: 'Il numero di cellulare è troppo lungo',
      matches: 'Il numero di cellulare non è valido',
    },
    minPassword: 'La password deve essere lunga almeno 6 caratteri',
    password: 'Password è richiesta',
    confirmPassword: 'Conferma password è richiesta',
    passwordMatch: 'Le password devono corrispondere',
    number_of_children: {
      max: 'Massimo 12 bambini consentiti',
    },
    agree:
      'Per favore assicurati di accettare i termini di registrazione di gut geregelt',
  },
  adress: {
    street: 'Via è richiesta',
    house_number: 'Numero civico è richiesto',
    zip_code: 'Codice postale è richiesto',
    city: 'Città è richiesta',
    country: 'Paese è richiesto',
  },
  invalid_date: 'Data non valida',
  mustBeNumber: 'Questo campo deve essere un numero',
  date_errors: {
    min_date: 'La data deve essere successiva a {{date}}',
    max_date: 'La data non può essere nel futuro',
    invalid_format: 'Formato data non valido',
  },
  platform_logo: "L'immagine deve essere almeno 630 x 80 pixel",
  primary_representative: 'Rappresentante principale è richiesto',
  secondary_representative: 'Rappresentante secondario è richiesto',
  secondary_kids: 'Salva o seleziona una persona',
  rep_selection: 'Per favore seleziona rappresentante principale e secondario',
  rep_inform_date: 'Per favore seleziona la data di informazione',
  selectAnOption: "Seleziona un'opzione",
  current_rental_agreement_name:
    'Nome del contratto di locazione attuale è richiesto',
  description_good: 'Descrizione è richiesta',
  storage_location: 'Luogo di conservazione è richiesto',
  beneficiary: 'Beneficiario è richiesto',
  next_benefiter: 'Prossimo beneficiario è richiesto',
  previous_owner: 'Proprietario precedente è richiesto',
  who_are_you: 'Per favore dimmi chi sei',
  shouldNotBeEmpty: 'Questo campo non dovrebbe essere vuoto',
  default_message: 'Questo campo è richiesto',
  meet_year: "Anno dell'incontro è richiesto",
  must_be_number: 'Questo campo dovrebbe essere un numero',
  min_0: 'Questo campo dovrebbe essere almeno 0',
  max_100: 'Questo campo dovrebbe essere al massimo 100',
  payment_ratio_per_party: 'Rapporto di pagamento per parte è richiesto',
  date_of_moving_in: 'Data di trasferimento è richiesta',
  successors: {
    first_name: 'Nome è richiesto',
    last_name: 'Cognome è richiesto',
    email: 'E-mail è richiesta',
    invalidEmail: 'E-mail non valida',
    agree: 'Per favore accetta che queste persone ricevano accesso ospite.',
    min: 'Per favore seleziona almeno due persone di fiducia',
    general_access_right: 'Accesso è richiesto',
  },
  message: 'Messaggio è richiesto',
  role: 'Ruolo è richiesto',
  shouldBeNumber: 'Questo campo dovrebbe essere un numero',
  duplicateChild: 'Esiste un bambino con lo stesso nome',
  otpCode: 'Codice OTP è richiesto',
  otpMinimum: 'Il codice OTP deve essere lungo almeno 6 caratteri',
  marriage: {
    place_of_marriage: 'Luogo del matrimonio è richiesto',
    date_of_marriage: 'Data del matrimonio è richiesta',
    have_children: "Per favore seleziona un'opzione",
    number_of_children: 'Numero di bambini è richiesto',
    is_less_than_children:
      'Il numero di bambini deve corrispondere al numero nella lista',
    childrenAmount:
      'Il numero di bambini deve corrispondere al numero nella lista',
    acquired_after_marriage: "Per favore seleziona un'opzione",
    acquired_property_after_marriage: "Per favore seleziona un'opzione",
    maintain_ordinary_property_regime: "Per favore seleziona un'opzione",
    spouse_disposal_right: "Per favore seleziona un'opzione",
    disposal_fixed_amount: 'Importo fisso è richiesto',
    disposal_percentage: 'Percentuale è richiesta',
    executor: 'Esecutore è richiesto',
    deputy: 'Vice è richiesto',
    sameAsContractParties:
      'Le parti del contratto non possono essere selezionate come esecutore o vice',
    assets: {
      description_good: 'Descrizione è richiesta',
      approximate_value: 'Valore approssimativo è richiesto',
      approximate_value_number: 'Il valore deve essere un numero',
      storage_location: 'Luogo di conservazione è richiesto',
      previous_owner: 'Proprietario precedente è richiesto',
    },
  },

  digitalAssets: {
    assets: {
      description: 'Descrizione è richiesta',
      storage_location: 'Luogo di conservazione è richiesto',
      beneficiary_id: 'Beneficiario è richiesto',
    },
    financial: {
      institution_name: "Nome dell'istituzione è richiesto",
      type: 'Tipo è richiesto',
    },
  },

  importantInformation: {
    passport_storage_location:
      'Luogo di conservazione del passaporto è richiesto',
    identity_card_storage_location:
      "Luogo di conservazione della carta d'identità è richiesto",
    residence_permit_storage_location:
      'Luogo di conservazione del permesso di soggiorno è richiesto',
    family_book_storage_location:
      'Luogo di conservazione del libro di famiglia è richiesto',
    birth_certificate_storage_location:
      'Luogo di conservazione del certificato di nascita è richiesto',
    bank_documents_storage_location:
      'Luogo di conservazione dei documenti bancari è richiesto',
    ahv_fund: 'Fondo AVS è richiesto',
    ahv_number: 'Numero AVS è richiesto',
    pension_fund_organization: 'Organizzazione del fondo pensione è richiesta',
    trusted_person: 'Esecutore testamentario è richiesto',
    insurancePolicySchema: {
      insurance_type: 'Tipo di assicurazione è richiesto',
      insurance_company: 'Compagnia di assicurazione è richiesta',
      policy_number: 'Numero di polizza è richiesto',
      storage_location: 'Luogo di conservazione è richiesto',
    },
    threeASchema: {
      pension_type: 'Tipo di pensione è richiesto',
      financial_institution: 'Istituto finanziario è richiesto',
      storage_location: 'Luogo di conservazione è richiesto',
    },
    notarySchema: {
      name: 'Nome è richiesto',
      email: 'E-mail è richiesta',
      invalidEmail: 'E-mail non valida',
    },
  },

  acceptedFiles: {
    type: {
      image: 'Sono consentite solo immagini',
      png: 'Sono consentiti solo file PNG',
      jpg: 'Sono consentiti solo file JPG',
      txt: 'Sono consentiti solo file TXT',
      docx: 'Sono consentiti solo file DOCX',
      pdf: 'Sono consentiti solo documenti PDF o Word.',
    },
  },
  tag: 'Tag è richiesto',
};

export default validations;
