const assetsRegister = {
  mainTitle: 'Panoramica del tuo patrimonio',
  mainDescription:
    'Crea per te e i tuoi cari una panoramica centrale dei principali beni finanziari e non finanziari e delle istituzioni presso cui li detieni (ad es. banche o assicurazioni).',

  exportAssets: 'Esporta beni',
  exportFinancialInstitutions: 'Esporta istituti finanziari',
  exportAllData: 'Esporta tutti i dati',

  tabs: {
    assets: 'Beni',
    financialInstitutions: 'Istituti finanziari',
  },

  deleteAsset: {
    title: 'Stai per rimuovere {{asset}} dalla lista. Sei sicuro?',
    description: "Per favore, conferma l'eliminazione.",
    onConfirm: 'Elimina',
    onClose: 'Annulla',
  },

  deleteFinancialInstitution: {
    title: 'Stai per rimuovere {{institution}} dalla lista. Sei sicuro?',
    description: "Per favore, conferma l'eliminazione.",
    onConfirm: 'Elimina',
    onClose: 'Annulla',
  },

  assetsTable: {
    tableHeads: {
      description: 'Descrizione',
      storageLocation: 'Luogo di conservazione',
      designatedBeneficiary: 'Beneficiario designato',
    },
    addAsset: 'Aggiungi bene',
  },

  institutionType: {
    secondary: 'Banca non principale',
    primary: 'Banca principale',
  },

  financialInstitutionsTable: {
    tableHeads: {
      institution: 'Istituzione',
      type: 'Tipo',
    },
    addFinancialInstitution: 'Aggiungi istituto finanziario',
  },
};

export default assetsRegister;
