import { useGet, usePatch, usePost, usePut } from '#/api/reactQuery';
import { UserPassword } from '#/types/auth';
import {
  ContactUs,
  IUserAccountGeneral,
  PartnerMessage,
  ReminderService,
} from '#/types/user';
import queryClient from '#/api/query-client';

const baseURL = '/users';

export const UserEndpoints = {
  getProfile: () => `${baseURL}/profile/`,
  changePassword: () => `${baseURL}/change-password/`,
  updateProfile: () => `${baseURL}/profile/update/`,
  updateProfilePicture: () => `${baseURL}/profile/update/picture`,
  resetPassword: () => `${baseURL}/request-reset-email/`,
  confirmResetPassword: (
    uidb64: string | number | undefined,
    token: string | number | undefined
  ) => `${baseURL}/password-reset/${uidb64}/${token}/`,
  completeResetPassword: () => `${baseURL}/password-reset-complete`,
  contactUs: () => `${baseURL}/contact-form/`,
  partnerMessage: () => `${baseURL}/partner-contact-form/`,
  setLanguage: () => `${baseURL}/language/`,
  reminderService: () => `${baseURL}/update-reminder/`,
  joinWaitList: () => `${baseURL}/wait-list/`,
  preventiveCheckUpContact: () => `${baseURL}/vorsorgecheck/`,
  otpVerify: () => `${baseURL}/verify-otp/`,
  triggerOTP: () => `${baseURL}/send-otp/`,
  verifyOTPTrigger: () => `${baseURL}/verify-sent-otp/`,
  resendCode: () => `${baseURL}/resend-otp/`,
};

export const invalidateProfileQueries = {
  getProfile: () => {
    queryClient.invalidateQueries(UserEndpoints.getProfile()).then((r) => r);
  },
  reminderService: () => {
    queryClient
      .invalidateQueries(UserEndpoints.reminderService())
      .then((r) => r);
  },
};

export const useGetProfile = (enabled: boolean) =>
  useGet<IUserAccountGeneral>(
    UserEndpoints.getProfile(),
    {},
    {
      enabled,
    }
  );
export const useChangePassword = () =>
  usePost<UserPassword, UserPassword>(UserEndpoints.changePassword());

export const useUpdateProfile = () =>
  usePut<IUserAccountGeneral, IUserAccountGeneral>(
    UserEndpoints.updateProfile()
  );

export const useResetPassword = (partner?: string | null) =>
  usePost<{ email: string }, { email: string }>(UserEndpoints.resetPassword(), {
    partner,
  });

export const useConfirmResetPassword = (
  uidb64: string | number | undefined,
  token: string | number | undefined,
  enabled: boolean,
  partner?: string | null
) =>
  useGet<{ message: string; success: boolean; token: string; uidb64: string }>(
    UserEndpoints.confirmResetPassword(uidb64, token),
    {
      partner,
    },
    {
      enabled,
    }
  );

export const useCompleteResetPassword = (partner?: string | null) =>
  usePatch<
    { password: string; uidb64: string | number; token: string | number },
    { password: string; uidb64: string | number; token: string | number }
  >(UserEndpoints.completeResetPassword(), {
    partner,
  });

export const useContactUs = () =>
  usePost<ContactUs, ContactUs>(UserEndpoints.contactUs());

export const usePartnerMessage = () =>
  usePost<PartnerMessage, PartnerMessage>(UserEndpoints.partnerMessage());

export const useSetLanguage = () =>
  usePost<{ language: string }, { language: string }>(
    UserEndpoints.setLanguage()
  );

export const useGetReminderService = () =>
  useGet<ReminderService>(UserEndpoints.reminderService());

export const useUpdateReminderService = () =>
  usePut<ReminderService, ReminderService>(UserEndpoints.reminderService());

export const useUpdateProfilePicture = () =>
  usePut<
    { profile_picture: string | (File & { preview: string }) },
    { profile_picture: string | (File & { preview: string }) }
  >(UserEndpoints.updateProfilePicture());

export const useJoinWaitList = () =>
  usePost<{ email: string }, { email: string }>(UserEndpoints.joinWaitList());

export const usePreventiveCheckUpContact = () =>
  usePost<
    { email: string; name: string; mobile_phone: string },
    { email: string; name: string; mobile_phone: string }
  >(UserEndpoints.preventiveCheckUpContact());

export const useOtpVerify = () =>
  usePost<{ otp: string; email: string }, { otp: string; email: string }>(
    UserEndpoints.otpVerify()
  );

export const useTriggerOTP = () =>
  usePost<{ email: string }, { email: string }>(UserEndpoints.triggerOTP());

export const useVerifyOTPTrigger = () =>
  usePost<{ otp: string; email: string }, { otp: string; email: string }>(
    UserEndpoints.verifyOTPTrigger()
  );

export const useResendCode = () =>
  usePost<{ email: string }, { email: string }>(UserEndpoints.resendCode());
