import { Button, Link, Paper, Stack, Typography } from '@mui/material';
import DialogAnimate from './animate/DialogAnimate';
import useLocales from '#/lib/hooks/useLocales';

type Props = {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
};

export default function PaxDisclaimerModal({ open, onClose, onAccept }: Props) {
  const { translate } = useLocales();

  return (
    <DialogAnimate open={open} onClose={onClose}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="body1" paragraph>
          {translate('global.paxDisclaimer.text')}{' '}
          <Link href="/datenschutzbestimmungen" target="_blank" rel="noopener">
            {translate('global.paxDisclaimer.privacyLink')}
          </Link>
          .
        </Typography>

        <Stack alignItems="center" mt={2}>
          <Button variant="contained" onClick={onAccept}>
            <Typography variant="body1">
              {translate('global.paxDisclaimer.accept')}
            </Typography>
          </Button>
        </Stack>
      </Paper>
    </DialogAnimate>
  );
}
