const outputDoc = {
  title: 'Mandato Precauzionale',
  from: 'da',
  born: 'nato il ',
  civilStatus: {
    single: 'Celibe/Nubile',
    married: 'Sposato/a',
    widowed: 'Vedovo/a',
    divorced: 'Divorziato/a',
    registered_partnership: 'Unione registrata',
  },
  primary: 'Persona principale',
  deputy: 'Sostituto',
  civil_status: 'Stato civile: {{civil_status}}',
  address: 'Indirizzo: {{address}}',
  declare: 'Io, {{first_name}} {{last_name}}, dichiaro quanto segue:  ',
  paragraph1:
    'Nel caso in cui diventi incapace di intendere e di volere e non sia più in grado di formare o comunicare la mia volontà in modo permanente o temporaneo, incarico la/le seguente/i persona/e di assumere i compiti per me come fiduciario/a e rappresentarmi in tutte le questioni legali: ',
  firstParagraph: {
    sameTrustees: 'Cura personale e patrimoniale, stessa persona e',
    differentTrustees: 'Cura personale e patrimoniale, persone diverse',
  },
  assetCare: 'Cura patrimoniale',
  personCare: 'Cura personale',
  mainCharacter: 'Persona principale',
  alternate: 'Sostituto',
  compensationDeclare: 'Riceve un compenso secondo la definizione sottostante',
  nonCompensationDeclare: 'Non riceve alcun compenso',
  paragraph2: 'Nomino come persona sostituta',
  paragraph3:
    'Nel caso in cui diventi incapace di intendere e di volere e non sia più in grado di formare o comunicare la mia volontà in modo permanente o temporaneo, incarico la/le seguente/i persona/e sostituta/e di assumere i compiti per me come fiduciario/a e rappresentarmi in tutte le questioni legali: ',
  personCareDeclare1:
    'La persona incaricata decide quali misure adottare per la mia cura e assistenza medica e garantisce un quotidiano ordinato e una partecipazione adeguata alla vita sociale.',
  personCareDeclare2:
    'A meno che non sia espressamente stabilito nel tuo testamento, i tuoi beni digitali passeranno ai tuoi eredi legali in caso di morte. Pertanto, dovresti decidere in vita cosa eliminare o a chi trasferire dopo la tua morte. ',
  assetCare1:
    'La persona incaricata gestisce il mio reddito e patrimonio, ne dispone e adotta tutte le misure correlate. Fornisce o presenta a terzi, banche e autorità le istruzioni e le richieste necessarie per tutelare i miei interessi. La persona incaricata si occupa del pagamento delle mie fatture tramite posta o banca e riceve pagamenti per me. È autorizzata a ricevere e aprire tutta la posta indirizzata a me.',
  assetCare2:
    "La gestione dei conti bancari e del patrimonio mobiliare deve seguire gli accordi e le consuetudini esistenti. La gestione patrimoniale non è espressamente soggetta alle disposizioni del regolamento sulla gestione patrimoniale nell'ambito di una tutela o curatela.",
  assetCare3:
    'La persona incaricata non può effettuare donazioni dal mio patrimonio né alienare beni a titolo gratuito, ad eccezione di doni occasionali o elargizioni per adempiere a un dovere morale.',
  repLegal: 'Rappresentanza legale',
  repLegal1:
    'La persona incaricata è autorizzata a intraprendere tutte le azioni legali e, se necessario, processuali richieste da questo mandato precauzionale, in particolare a concludere o rescindere i contratti necessari, presentare dichiarazioni fiscali e rappresentarmi davanti alle autorità e ai tribunali o nei confronti di terzi. La persona incaricata è responsabile di tutte le questioni amministrative.',
  repLegal2:
    'La persona incaricata è autorizzata a vendere o gravare la mia proprietà immobiliare, a stabilire, modificare e cancellare annotazioni, prenotazioni e servitù iscritte a mio favore o a mio carico nel registro fondiario, a far autenticare tali operazioni e a richiedere le relative iscrizioni nel registro fondiario.',
  custodyOrder: 'Disposizione di custodia',
  childrenTrustee:
    'Se i miei figli non sono ancora maggiorenni, desidero che la seguente persona sia nominata come loro tutore e che i bambini siano affidati alla sua custodia:',
  childrenTrusteeRelationship: 'Relazione con me: {{relation}}',
  relation: {
    husband: 'Marito',
    wife: 'Moglie',
    husband_wife: 'Marito/Moglie',
    partner: 'Partner, Partnera',
    friend: 'Amico',
    'brother-sister': 'Fratello, Sorella',
    'brother-sister-in-law': 'Cognato, Cognata',
  },
  alternateChildrenTrustee: 'Nomino come persona sostituta',
  childrenTrusteeDeclare:
    "Sono consapevole che questa disposizione di custodia non fa parte legalmente del mandato precauzionale, che si tratta di un desiderio e che l'autorità di protezione dei minori non è vincolata a esso e che l'autorità competente può considerare il desiderio solo se corrisponde al benessere del bambino. Non esiste quindi un diritto legale affinché l'autorità di protezione dei minori affidi la tutela alla/e persona/e sopra menzionata/e.",
  releaseFromConfidentiality: 'Esenzione dal segreto professionale',
  releaseFromConfidentialityDeclare:
    "Esento tutte le persone e le autorità soggette a segreto professionale nei confronti della persona incaricata dal segreto professionale e d'ufficio (in particolare banche, medici e funzionari).",
  involvementOfAidsAndAssistants: 'Coinvolgimento di ausili e assistenti',
  involvementOfAidsAndAssistantsDeclare:
    "Per l'adempimento dell'incarico, la persona incaricata è autorizzata ad acquistare ausili a mie spese e a coinvolgere assistenti e sostituti (come ad esempio aiuti domestici, servizi di assistenza, consulenti fiscali, avvocati, gestori patrimoniali, ecc.) e a concludere, modificare o risolvere contratti con loro.",
  expensesAndCompensation: 'Spese e compensi',
  expenses: 'Spese',
  expensesDeclare:
    'Le spese necessarie e comprovate devono essere rimborsate alla persona incaricata della cura personale o patrimoniale.',
  indemnities: 'Compensi',
  indemnitiesPositive:
    "La persona incaricata della cura personale o patrimoniale ha diritto a un compenso adeguato per i suoi servizi, che si basa sui criteri usuali dell'autorità di protezione degli adulti per il compenso dei tutori.",
  indemnitiesNegative:
    "La/e persona/e incaricata/e della cura dei bambini, personale o patrimoniale, che secondo il desiderio del creatore del documento dovrebbe ricevere un compenso, riceve un compenso che si basa sui criteri usuali dell'autorità di protezione degli adulti per i tutori.",
  storageRegistration:
    'Conservazione e registrazione del mandato precauzionale',
  storage: 'Conservazione',
  storageRegistrationDeclare:
    "Sono consapevole che i mandati precauzionali possono essere depositati presso l'autorità di protezione dei minori e degli adulti competente per il comune di residenza per la conservazione. È una mia libera decisione se avvalermi di questa possibilità.",
  storageRegistrationDeclare2:
    "La persona incaricata della previdenza è incaricata e autorizzata a contattare l'autorità di protezione degli adulti del mio luogo di residenza in caso di necessità, affinché l'autorità possa rilasciarle il documento necessario per la mia rappresentanza ai sensi dell'art. 363 ZGB.",
  registration: 'Registrazione',
  registrationDeclare:
    "Sono consapevole della possibilità di informare l'ufficio dello stato civile competente sulla creazione e il luogo di deposito del presente mandato precauzionale ai fini della registrazione nel database centrale ai sensi dell'art. 361 cpv. 3 ZGB. È una mia libera decisione se avvalermi di questa possibilità.",
  applicableLaw: 'Legge applicabile e foro competente',
  applicableLawDeclare:
    'Questo mandato precauzionale è soggetto al diritto svizzero, indipendentemente dalla mia nazionalità o residenza, escludendo le norme di conflitto di leggi (IPRG). Il foro competente è:',
  revocation: 'Revoca',
  revocationDeclare:
    'Revoco con la presente tutti i mandati precauzionali di data precedente.',
  abilityToAct: 'Capacità di agire',
  abilityToActDeclare:
    "Rilascio questa dichiarazione dopo attenta considerazione e nella piena responsabilità di me stesso, nonché nella consapevolezza che, per quanto riguarda le misure mediche, i miei medici, tutori o rappresentanti sono vincolati alla decisione della persona incaricata. Ho discusso questa disposizione con le persone menzionate all'inizio della prima pagina, che possono confermare che, a loro avviso, ero capace di intendere e di volere in quel momento e che il contenuto corrisponde alla mia volontà.",
};

export default outputDoc;
