const outputDoc = {
  title: "Mandat pour cause d'inaptitude",
  from: 'de',
  born: 'né le ',
  civilStatus: {
    single: 'Célibataire',
    married: 'Marié',
    widowed: 'Veuf',
    divorced: 'Divorcé',
    registered_partnership: 'Partenariat enregistré',
  },
  primary: 'Personne principale',
  deputy: 'Substitut',
  civil_status: 'État civil : {{civil_status}}',
  address: 'Adresse : {{address}}',
  declare: 'Je, {{first_name}} {{last_name}}, déclare ce qui suit :  ',
  paragraph1:
    'Dans le cas où je deviendrais incapable de discernement et ne serais plus en mesure de former ou de communiquer ma volonté de manière permanente ou temporaire, je charge la/les personne(s) suivante(s) de prendre en charge les tâches de prévoyance et de gestion de patrimoine pour moi et de me représenter dans toutes les affaires juridiques : ',
  firstParagraph: {
    sameTrustees:
      'Soins personnels et gestion de patrimoine, même personne ainsi que',
    differentTrustees:
      'Soins personnels et gestion de patrimoine, personnes différentes',
  },
  assetCare: 'Gestion de patrimoine',
  personCare: 'Soins personnels',
  mainCharacter: 'Personne principale',
  alternate: 'Substitut',
  compensationDeclare: 'Reçoit une compensation selon la définition ci-dessous',
  nonCompensationDeclare: 'Ne reçoit aucune compensation',
  paragraph2: 'Je nomme comme personne de substitution',
  paragraph3:
    'Dans le cas où je deviendrais incapable de discernement et ne serais plus en mesure de former ou de communiquer ma volonté de manière permanente ou temporaire, je charge la/les personne(s) de substitution suivante(s) de prendre en charge les tâches de prévoyance et de gestion de patrimoine pour moi et de me représenter dans toutes les affaires juridiques : ',
  personCareDeclare1:
    'La personne mandatée détermine les mesures à prendre concernant mes soins et traitements médicaux, et veille à assurer un quotidien ordonné et une participation adéquate à la vie sociale.',
  personCareDeclare2:
    'Sauf disposition expresse dans votre testament, vos actifs numériques seront transmis à vos héritiers légaux en cas de décès. Vous devriez donc décider de votre vivant ce qui doit être supprimé ou à qui cela doit être transmis après votre décès. ',
  assetCare1:
    "La personne mandatée gère mes revenus et mon patrimoine, en dispose et prend toutes les mesures connexes. Elle donne ou soumet aux tiers, banques et autorités les instructions et demandes nécessaires à la protection de mes intérêts. La personne mandatée veille au paiement de mes factures par la poste ou la banque et reçoit les paiements pour moi. Elle est autorisée à recevoir et ouvrir tout courrier qui m'est adressé.",
  assetCare2:
    "La gestion des comptes bancaires et des portefeuilles de titres doit se conformer aux accords et pratiques existants. La gestion de patrimoine n'est expressément pas soumise aux dispositions du règlement sur la gestion de patrimoine dans le cadre d'une curatelle ou d'une tutelle.",
  assetCare3:
    'La personne mandatée ne peut effectuer de dons à partir de mon patrimoine ni aliéner des actifs à titre gratuit, sauf pour des cadeaux occasionnels ou des dons pour remplir un devoir moral.',
  repLegal: 'Représentation légale',
  repLegal1:
    'La personne mandatée est autorisée à prendre toutes les mesures juridiques et, le cas échéant, procédurales nécessaires dans le cadre de ce mandat de prévoyance, notamment à conclure ou résilier les contrats nécessaires, à déposer des déclarations fiscales et à me représenter devant les autorités et tribunaux ou vis-à-vis de tiers. La personne mandatée est responsable de toutes les questions administratives.',
  repLegal2:
    'La personne mandatée est autorisée à vendre ou grever mes biens immobiliers, à établir, modifier et supprimer des annotations, prénotations et servitudes inscrites à mon profit ou à ma charge dans le registre foncier, à faire authentifier de telles transactions et à ordonner les inscriptions correspondantes dans le registre foncier.',
  custodyOrder: 'Disposition de garde',
  childrenTrustee:
    'Si mes enfants ne sont pas encore majeurs, je souhaite que la personne suivante soit nommée comme leur tuteur et que les enfants soient placés sous sa garde :',
  childrenTrusteeRelationship: 'Relation avec moi : {{relation}}',
  relation: {
    husband: 'Époux',
    wife: 'Épouse',
    husband_wife: 'Époux/Épouse',
    partner: 'Partenaire',
    friend: 'Ami',
    'brother-sister': 'Frère, Sœur',
    'brother-sister-in-law': 'Beau-frère, Belle-sœur',
  },
  alternateChildrenTrustee: 'Je nomme comme personne de substitution',
  childrenTrusteeDeclare:
    "Je suis conscient que cette disposition de garde ne fait pas partie du mandat de prévoyance, qu'il s'agit d'un souhait et que l'autorité de protection de l'enfant n'y est pas liée et que l'autorité compétente ne peut tenir compte du souhait que s'il est conforme au bien de l'enfant. Il n'existe donc aucun droit légal à ce que l'autorité de protection de l'enfant confie la tutelle à la/les personne(s) mentionnée(s). ",
  releaseFromConfidentiality: 'Levée du secret professionnel',
  releaseFromConfidentialityDeclare:
    'Je libère toutes les personnes et autorités soumises au secret professionnel envers la personne mandatée du secret professionnel et de fonction (notamment les banques, médecins et fonctionnaires).',
  involvementOfAidsAndAssistants: 'Recours à des aides et assistants',
  involvementOfAidsAndAssistantsDeclare:
    "Pour l'exécution du mandat, la personne mandatée est autorisée à acquérir des aides à mes frais et à recourir à des assistants et substituts (tels que des aides ménagères, services de soins, conseillers fiscaux, avocats, gestionnaires de patrimoine, etc.) et à conclure, modifier ou résilier des contrats avec eux.",
  expensesAndCompensation: 'Frais et compensations',
  expenses: 'Frais',
  expensesDeclare:
    'Les dépenses nécessaires et prouvées doivent être remboursées à la personne mandatée pour les soins personnels ou la gestion de patrimoine.',
  indemnities: 'Compensations',
  indemnitiesPositive:
    "La personne mandatée pour les soins personnels ou la gestion de patrimoine a droit à une compensation appropriée pour ses services, qui est calculée selon les taux habituels de l'autorité de protection des adultes pour la compensation des curateurs.",
  indemnitiesNegative:
    "La/les personne(s) mandatée(s) pour les soins des enfants, personnels ou de patrimoine et qui, selon le souhait du créateur du document, doit recevoir une compensation, en reçoit une qui est calculée selon les taux habituels de l'autorité de protection des adultes pour les curateurs.",
  storageRegistration: 'Conservation et enregistrement du mandat de prévoyance',
  storage: 'Conservation',
  storageRegistrationDeclare:
    "Je suis conscient que les mandats de prévoyance peuvent être déposés auprès de l'autorité de protection de l'enfant et de l'adulte compétente pour la commune de résidence. Il est de mon libre choix de recourir à cette possibilité.",
  storageRegistrationDeclare2:
    "La personne chargée de la prévoyance est mandatée et autorisée à s'adresser à l'autorité de protection des adultes de mon lieu de résidence avec ce mandat de prévoyance, afin que l'autorité lui délivre le document nécessaire à ma représentation au sens de l'art. 363 CC. ",
  registration: 'Enregistrement',
  registrationDeclare:
    "Je suis conscient de la possibilité d'informer l'office de l'état civil compétent de l'établissement et du lieu de dépôt du présent mandat de prévoyance aux fins d'enregistrement dans la base de données centrale conformément à l'art. 361 al. 3 CC. Il est de mon libre choix de recourir à cette possibilité. ",
  applicableLaw: 'Droit applicable et for juridique',
  applicableLawDeclare:
    "Ce mandat de prévoyance est soumis – indépendamment de ma nationalité ou de mon domicile – au droit suisse, à l'exclusion des normes de droit international privé (LDIP). Le for juridique est :",
  revocation: 'Révocation',
  revocationDeclare:
    'Je révoque par la présente tous les mandats de prévoyance antérieurs.',
  abilityToAct: "Capacité d'agir",
  abilityToActDeclare:
    "Je fais cette déclaration après mûre réflexion et en pleine responsabilité pour moi-même, ainsi qu'en étant conscient que, concernant les mesures médicales, mes médecins, soignants ou mandataires sont liés par la décision de la personne mandatée. J'ai discuté de cette disposition avec les personnes mentionnées au début de la première page, qui peuvent confirmer qu'à ce moment-là, selon leur perception, j'étais capable de discernement et que le contenu correspond à ma volonté. ",
};

export default outputDoc;
