import {
  distributionQuestions,
  governanceQuestions,
  inheritorsDivorcedVersion,
  inheritorsMarriedVersion,
  inheritorsSingleVersion,
} from './questions';
import outputDoc from './outputDoc';

const lastWill = {
  mainHeaders: {
    shortPainFree: 'Il tuo testamento semplice ed efficace',
    madeIt: 'Il tuo testamento efficace',
  },

  yes: 'Sì',
  no: 'No',

  addAnotherChildren: 'Aggiungi altri figli',
  areYouSureYouWantToDeleteChild: 'Sei sicuro di voler eliminare il bambino?',
  deleteInformerChild:
    "Se elimini un bambino dall'elenco, tutti i suoi dati verranno cancellati",
  cancelBtn: 'Annulla',
  deleteBtn: 'Elimina',
  childBirthDate: 'Data di nascita',

  noChildrenModal: {
    title: 'Sei sicuro di voler reimpostare il numero di bambini?',
    description:
      'Se reimposti il numero di bambini, tutte le informazioni sui tuoi figli verranno cancellate.',
    resetNumberBtn: 'Reimposta numero',
    cancelBtn: 'Annulla',
  },

  stepperInfo: [
    {
      label: 'Beneficiari',
      title: 'Di cosa si tratta e perché è così importante?',
    },

    {
      label: 'Distribuzione',
      title: 'Definisci la distribuzione',
    },

    {
      label: 'Governance',
      title: '',
    },

    {
      label: 'Il tuo ultimo desiderio',
      title: 'Deposita il tuo testamento',
    },
    {
      label: 'Carica il tuo testamento',
      title: '',
    },
  ],

  stepOne: {
    introParagraph:
      "Un testamento ti offre la possibilità di dare ai tuoi discendenti istruzioni chiare sui tuoi desideri in un momento difficile. Ti offre anche la possibilità, nei limiti delle disposizioni legali, di garantire che i tuoi desideri riguardo alla gestione della tua eredità siano rispettati. Inoltre, il testamento ti offre la possibilità di evitare discussioni difficili ed emotive all'interno del sistema familiare nominando un esecutore testamentario.",
    helperParagraph:
      'Allo stesso tempo, redigere un testamento non è un compito facile per te personalmente, quindi prenditi il tuo tempo e non sentirti stressato. Con il nostro servizio di promemoria, puoi anche mettere in pausa in qualsiasi momento e continuare più tardi. Basta cliccare sul pulsante "Ho finito".',
    questions: {
      inheritorsMarriedVersion,
      inheritorsSingleVersion,
      inheritorsDivorcedVersion,
    },
    addAnotherBenefiter: 'Aggiungi beneficiario',
    isChildFromCommonPreviousMarriage:
      'Questo bambino è del matrimonio precedente',
    isChildFromCommonMarriage: 'Questo bambino è del matrimonio comune',
    isChildFromCommonRelationship: 'Questo bambino è della relazione comune',
  },

  stepTwo: {
    introParagraph: 'Cosa vuoi principalmente ottenere con questo testamento?',
    mainly: 'principalmente',
    yesIHaveProperty: 'Sì, ho proprietà o appartamenti che voglio donare a:',
    followingDistribution:
      'Sì, voglio che i seguenti beni ereditari / la somma di denaro sopra menzionata siano trasferiti alla persona sopra menzionata:',
    questions: distributionQuestions,
  },
  stepThree: {
    questions: governanceQuestions,
    orAddSomeoneNew: 'o aggiungi qualcuno di nuovo',
    addAnotherExecutor: 'Esecutore testamentario',
  },
  stepFour: {
    document: outputDoc,
    introParagraph: "Congratulazioni! Ce l'hai fatta.",
    helperParagraph:
      'In Svizzera è richiesto il requisito della forma scritta, il che significa che per rendere il tuo testamento legalmente valido, hai le seguenti opzioni:',
    helperParagraph2:
      'È anche consigliabile depositare il testamento presso un avvocato e registrarlo presso il registro ufficiale dei testamenti svizzero (https://www.ztr.ch/notariatsinfo/).',
    contactForm: 'Modulo di contatto',
    helperParagraph3:
      'Se desideri che ti aiutiamo, utilizza il nostro modulo di contatto.',
    obligation1:
      'Una copia manoscritta del testamento con luogo, data e la tua firma',
    obligation2:
      'Un testamento pubblico (procedura di lettura autonoma, Art. 500 f. ZGB o procedura di lettura, Art. 502 ZGB), per il quale hai bisogno di un notaio e due testimoni',
    nextStepTitle: 'I prossimi passi:',
    nextStep1:
      'O trascrivi completamente a mano il testamento, datalo e firmalo o autenticati con un avvocato e due testimoni (secondo la procedura di lettura autonoma, Art. 500 ff. ZGB o procedura di lettura, Art. 502 ZGB) e infine caricalo qui',
    nextStep2:
      'Assicurati che le persone fidate abbiano un accesso ospite a gut geregelt, in modo che possano accedere al testamento in caso di emergenza (clicca qui)',
    highlightText: 'clicca qui',
    nextStep3: 'Discuti il testamento con la famiglia, se necessario',
    highlightedText: {
      noterizeWithLawyer: 'autenticati con un avvocato e due testimoni',
      linkToSettings: 'https://gutgeregelt.ch/user/account',
    },
    stopProcess: {
      title:
        'Se hai stipulato un contratto matrimoniale, non possiamo continuare questo processo.',
      title1: 'Se scegli "No" qui, non possiamo continuare questo processo.',
      description:
        'Ti consigliamo di rivolgerti a un avvocato per un testamento che tenga conto di un contratto matrimoniale. Qui puoi trovare un avvocato per supporto',
      description1:
        'Se desideri scegliere "No", ti consigliamo di redigere un testamento che tenga conto del contratto matrimoniale. \n' +
        'Per questo dovresti rivolgerti direttamente a un avvocato.\n' +
        'Siamo felici di indirizzarti a uno specialista.',
      changeAnswerBtn: 'Cambia risposta',
      continueBtn: 'Termina il processo e continua con un avvocato',
    },
  },
  updateCivilStatusModal: {
    title: 'Vuoi aggiornare il tuo stato civile?',
    description:
      'Una modifica della tua risposta aggiornerà il tuo stato civile e reimposterà il tuo documento. Sei sicuro di voler continuare?',
    updateButton: 'Aggiorna',
    cancelButton: 'Annulla',
  },

  checkChildrenRelationship: {
    title:
      'Poiché hai cambiato il tuo stato di relazione attuale, i bambini associati verranno rimossi',
    description:
      'Se hai figli da una relazione precedente, aggiungili di nuovo',
    updateButton: 'Aggiorna informazioni sui bambini',
    cancelButton: 'Annulla',
  },

  alternativeGoverner:
    'Se la persona scelta da te non può agire come esecutore testamentario, verrà nominata la seguente persona:',
};

export default lastWill;
