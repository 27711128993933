import { Theme } from '@mui/material/styles';
import Card from './Card';
import Tabs from './Tabs';
import DataGrid from './DataGrid';
import List from './List';
import Switch from './Switch';
import Select from '#/theme/overrides/Select';
import { createTypographyOverrides } from './Typography';

type PartnerSettings = {
  title_font?: { family: string; size: string; color: string };
  subtitle_font?: { family: string; size: string; color: string };
  label_font?: { family: string; size: string; color: string };
  prose_font?: { family: string; size: string; color: string };
  link_font?: { family: string; size: string; color: string };
  // ...other partner settings
};

// Define the shape of the overrides object.
// This would depend on what your Card and Tabs overrides look like.
interface Overrides {
  [key: string]: any;
}

function mergeComponentOverrides(...overrides: Overrides[]): Overrides {
  return overrides.reduce((result, current) => {
    for (const [key, value] of Object.entries(current)) {
      if (result[key]) {
        result[key] = { ...result[key], ...value };
      } else {
        result[key] = value;
      }
    }
    return result;
  }, {});
}

export default function ComponentsOverrides(
  theme: Theme,
  partnerSettings?: PartnerSettings
): Overrides {
  return mergeComponentOverrides(
    Card(theme),
    Tabs(theme),
    DataGrid(theme),
    List(theme),
    Switch(theme),
    Select,
    createTypographyOverrides(theme, partnerSettings as any)
    // TextField(theme)
  );
}
