const registerAssets = {
  mainBanner: {
    title: 'Chiarezza e panoramica con il registro dei beni e delle proprietà',
    subtitle:
      'Una panoramica completa di tutto il tuo patrimonio non solo ti dà tranquillità, ma facilita anche la vita dei tuoi familiari.',
    buttonLabel: 'Ottieni chiarezza',
  },
  youtubeTutorial: 'Come preparare la tua eredità?',
  aboutCards: {
    logPhysicalAssets: {
      title: 'Registrare i beni fisici',
      description:
        'Registra i beni fisici, dove i tuoi cari possono trovarli e a chi desideri lasciarli.',
    },
    outlineRelationships: {
      title: 'Definire le relazioni',
      description:
        'Annota i tuoi dettagli bancari e altri, dove hai investito il tuo patrimonio, per facilitare le ricerche alla tua famiglia.',
    },
    simplifyCleanup: {
      title: 'Semplificare le operazioni di pulizia',
      description:
        'Conserva tutto in un luogo centralizzato e ben protetto, in modo che le persone fidate possano accedere rapidamente alle informazioni rilevanti.',
    },
  },
  subBanner: {
    title: 'Il quadro generale che risparmia alla tua famiglia decine di ore',
    subtitle:
      'Molti dei nostri clienti lo sanno per esperienza personale: quando poco è documentato e registrato, si perdono ore per ottenere una chiara panoramica delle proprietà e dei beni del defunto. Ti aiutiamo a risparmiare questa esperienza ai tuoi cari.',
    buttonLabel: 'Ottieni una panoramica',
  },
  testimonials: {
    testimonial1: {
      name: 'Elisabeth Müller',
      text: 'Mia figlia mi ha aiutato a inserire il patrimonio di mio marito e il mio nel registro dei beni. È stato un sollievo per tutti noi sapere dove abbiamo cosa.',
    },
    testimonial2: {
      name: 'Sascha Zürcher',
      text: 'Avevo già un testamento. Ma con gut geregelt ora ho anche una panoramica di tutti i miei beni, che posso aggiornare continuamente e rendere accessibile direttamente alle persone appropriate in caso di mia morte.',
    },
    testimonial3: {
      name: 'Vreni Frey',
      text: 'Una soluzione semplice, che è un buon esercizio per annotare tutto ciò che ha valore. Sono rimasta sorpresa di quanto sia emerso, a cui non avevo pensato direttamente.',
    },
  },
  newsletter: {
    title: 'La tua eredità, ben regolata',
    buttonLabel: 'Organizza ora',
  },
  faq: {
    questions: {
      question1:
        "Cos'è un registro dei beni e delle proprietà e perché è importante?",
      question2:
        'Quali tipi di beni possono essere inclusi nel registro dei beni e delle proprietà?',
      question3: 'Come funziona il servizio di registro dei beni?',
      question4:
        'Come posso garantire che i miei cari abbiano accesso al mio registro dei beni?',
      question5: 'Cosa succede ai miei beni dopo la mia morte?',
      question6:
        'Posso aggiornare o modificare il mio registro dei beni e delle proprietà?',
      question7: 'Quanto sono sicure le mie informazioni personali?',
      question8:
        'Quali aspetti legali dovrebbero essere considerati nella gestione di un registro dei beni e delle proprietà?',
      question9:
        'Il servizio può aiutare gli eredi a chiarire le questioni finanziarie?',
    },
    answers: {
      answer1:
        'Un registro dei beni e delle proprietà è una registrazione completa di tutti i beni fisici e delle relazioni con le istituzioni finanziarie di una persona. È importante mantenere un tale registro per avere una chiara panoramica dei propri beni e facilitare la gestione per i familiari dopo la morte. Questo può risparmiare ai tuoi cari decine di ore.',
      answer2:
        "Il registro può includere una varietà di beni, tra cui immobili, veicoli, gioielli, opere d'arte, conti bancari, conti di investimento e altro.",
      answer3:
        'Il nostro servizio offre una piattaforma facile da usare, dove i clienti possono inserire e gestire i loro beni. Possono definire la posizione, lo stato di proprietà e i beneficiari per ogni bene.',
      answer4:
        'Definisci persone fidate che, dopo il tuo decesso, avranno accesso illimitato al tuo account. Lo ottengono con un certificato di morte e un certificato di esecutore testamentario.',
      answer5:
        'Il registro dei beni e delle proprietà, insieme al testamento, facilita la gestione per i familiari, fornendo istruzioni chiare su come distribuire o gestire i beni e dove trovare cosa.',
      answer6:
        'Sì, i clienti possono aggiornare o modificare il loro registro dei beni e delle proprietà in qualsiasi momento per riflettere i cambiamenti nei loro beni o beneficiari.',
      answer7:
        'Implementiamo misure di sicurezza rigorose per garantire la massima riservatezza e sicurezza delle informazioni dei clienti, inclusa la crittografia standard del settore e i controlli di accesso.',
      answer8:
        'I clienti dovrebbero considerare questioni legali come la creazione di un testamento e le questioni ereditarie. È consigliabile ottenere consulenza legale per garantire che il registro sia conforme alle leggi applicabili.',
      answer9:
        "Sì, fornendo una chiara panoramica dei beni del defunto e facilitando l'identificazione e la gestione dei beni, il servizio supporta gli eredi nella risoluzione delle questioni finanziarie.",
    },
  },
  situations: {
    pensionierung: {
      content: {
        main: {
          title: 'Registro dei beni e delle proprietà',
          description:
            'Un registro dei beni e delle proprietà documenta i tuoi possedimenti e oggetti di valore. Ciò crea trasparenza e garantisce che tutto ciò che è importante per te rimanga in buone mani.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Dopo la morte, la divisione dei beni e dei possedimenti spesso diventa un peso. Con un registro dei beni, previeni le ambiguità e garantisci che tutto sia regolato secondo i tuoi desideri.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Chiarezza su beni e possedimenti',
              'Protezione contro le controversie',
              'Ordine e trasparenza per la tua famiglia',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      },
    },

    'tod-verwandter': {
      content: {
        main: {
          title: 'Registro dei beni e delle proprietà',
          description:
            'Un registro dei beni e delle proprietà documenta i tuoi possedimenti e oggetti di valore. Ciò crea trasparenza e garantisce che tutto ciò che è importante per te rimanga in buone mani.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Dopo la morte di un parente, la divisione dei beni e dei possedimenti spesso diventa un peso. Con un registro dei beni, previeni le ambiguità e garantisci che tutto sia regolato secondo i tuoi desideri.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Chiarezza su beni e possedimenti',
              'Protezione contro le controversie',
              'Ordine e trasparenza per la tua famiglia',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      },
    },
  },
};

export default registerAssets;
