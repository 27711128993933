import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  ReactNode,
} from 'react';
import useLocalStorage from '#/hooks/useLocalStorage';
import { getSubdomain } from '#/utils/helpers';
import PaxDisclaimerModal from '#/components/shared/PaxDisclaimerModal';

type PartnerContextType = {
  partnerKey: string | null;
  setPartnerKey: (key: string | null) => void;
  removePartnerKey: () => void;
  isPartnerMode: boolean;
};

const initialContext: PartnerContextType = {
  partnerKey: null,
  setPartnerKey: () => {},
  removePartnerKey: () => {},
  isPartnerMode: false,
};

export const PartnerContext = createContext<PartnerContextType>(initialContext);

type PartnerProviderProps = {
  children: ReactNode;
};

export function PartnerProvider({ children }: PartnerProviderProps) {
  const [partnerKey, setPartnerKeyStorage, removePartnerKeyStorage] =
    useLocalStorage('gg_partner_key', null);
  const [isPartnerMode, setIsPartnerMode] = useState<boolean>(!!partnerKey);

  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [paxDisclaimerAccepted, setPaxDisclaimerAccepted] = useLocalStorage(
    'pax_disclaimer_accepted',
    false
  );

  useEffect(() => {
    const isPaxPartner = partnerKey === 'pax';
    setShowDisclaimer(isPaxPartner && !paxDisclaimerAccepted);
  }, [partnerKey, paxDisclaimerAccepted]);

  const handleAcceptDisclaimer = () => {
    setPaxDisclaimerAccepted(true);
    setShowDisclaimer(false);
  };

  const handleCloseDisclaimer = () => {
    setShowDisclaimer(false);
  };

  useEffect(() => {
    const subdomain = getSubdomain(window.location.href);
    if (subdomain !== null) {
      setPartnerKeyStorage(subdomain);
    }
  }, []);

  useEffect(() => {
    setIsPartnerMode(!!partnerKey);
  }, [partnerKey]);

  const setPartnerKey = (key: string | null) => {
    if (key) {
      setPartnerKeyStorage(key);
    } else {
      removePartnerKeyStorage();
    }
  };

  const removePartnerKey = () => {
    removePartnerKeyStorage();
  };

  const contextValue = useMemo(
    () => ({
      partnerKey,
      setPartnerKey,
      removePartnerKey,
      isPartnerMode,
    }),
    [partnerKey, isPartnerMode]
  );

  return (
    <PartnerContext.Provider value={contextValue}>
      {children}
      <PaxDisclaimerModal
        open={showDisclaimer}
        onClose={handleCloseDisclaimer}
        onAccept={handleAcceptDisclaimer}
      />
    </PartnerContext.Provider>
  );
}
