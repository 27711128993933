const agb = {
  title: 'Allgemeine Geschäftsbedingungen',
  scopeOfApp: {
    title: 'Anwendungsbereich',
    content: {
      p1: 'Gegenstand dieser Allgemeinen Geschäftsbedingungen (AGB) ist die Nutzung der Services der gut geregelt AG (im Weiteren gut geregelt genannt). gut geregelt stellt dem Kunden für die Dauer des Vertrages in der jeweils aktuellen Version die Services entgeltlich zur Nutzung zur Verfügung.',
      p2: 'Die Vertragsbeziehung zwischen gut geregelt und dem Kunden kommt mit der Annahme des Angebotes zustande. Mit der Annahme des Angebotes erkennt der Kunde vorliegende AGB ausdrücklich an, auch wenn sie mit seinen Geschäftsbedingungen ganz oder teilweise in Widerspruch stehen.',
      p3: 'Der Kunde ist dazu verpflichtet, jederzeit genaue, vollständige und aktuelle Informationen zur Verfügung zu stellen. Wird dieser Anforderung nicht nachgekommen, liegt ein Verstoss gegen die Nutzungsbedingungen vor, der zur sofortigen Kündigung des Vertrags führen kann.',
    },
  },
  complience: {
    title: 'Einhaltung der AGB',
    content: {
      p1: 'Durch die Nutzung der gut geregelt Plattform und der Dienste erklärt sich der Nutzer ausdrücklich mit diesen AGB sowie deren integrierenden Bestandteilen, wie insbesondere der Datenschutzrichtlinie, einverstanden. Der Nutzer verpflichtet sich, im Rahmen der Nutzung der Dienste die vorliegenden AGB und deren integrierende Bestandteile einzuhalten.',
    },
  },
  yourAccount: {
    title: 'Ihr Account',
    content: {
      p1: 'gut geregelt User sind Kontobesitzer. Sie erklären sich mit Folgendem einverstanden: (1) Sie wählen ein sicheres Passwort, halten dieses geheim und behandeln es vertraulich; (2) Sie übertragen Ihr eigenes Konto nicht an andere (beispielsweise an Familienmitglieder oder andere Kontakte). Sie tragen die Verantwortung für sämtliche über Ihr Benutzerkonto vorgenommenen Aktionen, es sei denn, Sie haben das Konto geschlossen oder einen Missbrauch gemeldet.',
    },
  },
  payment: {
    title: 'Zahlung',
    content: {
      p1: 'Wenn Sie unsere gebührenpflichtigen Dienste erwerben, stimmen Sie den zusätzlichen Bedingungen für diese gebührenpflichtigen Dienste zu und erklären sich zur Zahlung der entsprechenden Gebühren und Steuern an uns bereit. Bei Nichtzahlung dieser Gebühren werden Ihre gebührenpflichtigen Dienste beendet. Des Weiteren erklären Sie sich mit Folgendem einverstanden:',
      subP1:
        'Wir können Ihre Zahlungsmethode (z. B. Kreditkarte) speichern und belasten, und zwar auch nachdem diese abgelaufen ist, um eine Unterbrechung unserer Dienste zu vermeiden und um sie zur Zahlung anderer Dienste zu verwenden, die Sie möglicherweise erwerben.',
      subP2:
        'Wenn Sie ein Abonnement kaufen, wird Ihre Zahlungsmethode jeweils bei Beginn einer Mitgliedschaftsperiode automatisch mit den für diese Periode anfallenden Gebühren und Steuern belastet. Kündigen Sie bitte vor dem Verlängerungsdatum, damit keine zukünftigen Kosten anfallen. Informieren Sie sich darüber, wie Sie Ihre Abonnement-Dienste kündigen oder aussetzen können.',
      subP3:
        'Wir berechnen die von Ihnen zu zahlenden Steuern auf Basis der Rechnungsdaten, die Sie uns zur Zeit des Kaufs zur Verfügung stellen.',
    },
  },
  termination: {
    title: 'Kündigung',
    content: {
      p1: 'gut geregelt ist berechtigt, in bestimmten Fällen den Nutzungsvertrag jederzeit ohne Angabe von Gründen zu kündigen. Ein solcher Fall und Grund für eine fristlose Kündigung des Nutzungsvertrages ist insbesondere ein Verstoss des Nutzers gegen diese AGB, bei Verdacht auf Missbrauch oder wenn die Sicherheit des Systems nicht mehr gewährleistet ist. Bei kostenlosen Accounts behält sich gut geregelt das Recht vor, den Nutzungsvertrag zu kündigen und aufzulösen, wenn der Nutzer mehr als 12 aufeinanderfolgende Monate nicht darauf zugreift. gut geregelt setzt den Nutzer zuvor per E-Mail an die in seinem Account hinterlegte Adresse davon in Kenntnis. Die Kündigung wird dem Nutzer 30 Tage vor Sperrung seines Accounts („Kündigungsfrist“) angekündigt, so dass er in der Zwischenzeit die von ihm auf der Plattform hinterlegten Daten exportieren kann. Nach Ablauf der Kündigungsfrist ist gut geregelt berechtigt, den Account des Nutzers und die darin hinterlegten Daten unwiderruflich zu löschen.',
    },
  },
  rightOfUse: {
    title: 'Nutzungsrechte',
    content: {
      p1: 'gut geregelt gewährt dem Kunden das nicht-exklusive und nicht-übertragbare Recht, die Dienste von gut geregelt während der Vertragslaufzeit bestimmungsgemäss zu nutzen. Dies umfasst die Erstellung und Verwaltung von Dokumenten für den eigenen Gebrauch des Kunden.',
      p2: 'Der Kunde ist nicht berechtigt, den Dienst zu nutzen, um Dokumente für nicht registrierte Nutzer zu erstellen oder den Dienst Dritten gegen Entgelt oder unentgeltlich zur Verfügung zu stellen.',
      p3: 'Der Kunde verpflichtet sich, die Nutzung des Dienstes auf registrierte Nutzer zu beschränken.',
    },
  },
  dataUsePartner: {
    title: 'Datennutzung durch Partnerorganisationen',
    content: {
      p1: "Partnerorganisationen von gut geregelt können im Rahmen ihrer Dienstleistungen auf bestimmte Daten ihrer Kunden zugreifen. Die Art der geteilten Daten ist in den Einstellungen unter dem Reiter 'Daten' aufgeführt.",
      p2: 'Der Kunde hat jederzeit die Möglichkeit, die Übertragung von Daten an Partnerorganisationen in den Einstellungen zu kontrollieren und bei Bedarf zu deaktivieren.',
      p3: 'gut geregelt verpflichtet sich, die Nutzung von Daten durch Partnerorganisationen auf das notwendige Mass zu beschränken und die Datenschutzrechte der Kunden zu schützen.',
    },
  },
  dataProtection: {
    title: 'Datenschutz und Datensicherheit',
    content: {
      p1: 'gut geregelt wird die Daten des Kunden mit grösster Sorgfalt behandeln und vor Missbrauch und Verlust schützen. Der Kunde wird darüber informiert, dass bestimmte Daten im Rahmen der Nutzung des Dienstes mit Partnerorganisationen geteilt werden können. Einzelheiten zur Art der geteilten Daten und zu den Kontrollmöglichkeiten finden sich in den Einstellungen des Benutzerkontos. gut geregelt trifft technische und organisatorische Massnahmen, die den geltenden Anforderungen der DSGVO entsprechen. Im Rahmen der technischen Möglichkeiten können in bestimmten Ländern besondere Vereinbarungen zur Datenspeicherung getroffen werden. Der Kunde ist für die Rechtmässigkeit der Datenübertragung oder deren Nutzung verantwortlich. Alle von gut geregelt gespeicherten und verarbeiteten Kundendaten sind ausschliessliches Eigentum des Kunden und werden von gut geregelt ausschliesslich zur Vertragserfüllung verwendet.',
    },
  },
  support: {
    title: 'Support',
    content: {
      p1: 'Der gut geregelt-Support steht dem Kunden von Montag bis Freitag von 8.00 – 12.00 Uhr und 13.00 – 17.00 Uhr per E-Mail an support@gutgeregelt.ch zur Verfügung.',
    },
  },
  intellectualPropertyRights: {
    title: 'Schutzrechte',
    content: {
      p1: 'Der Kunde erkennt die Schutzrechte insbesondere das Urheberrecht von gut geregelt an Software Services und Dokumentationen an. Der Kunde ist nicht berechtigt, diese Software Dritten entgeltlich oder unentgeltlich zur Verfügung zu stellen bzw. weiterzuvermieten und/oder ausserhalb des Rahmens der Vertragsbeziehung mit gut geregelt zu nutzen oder gut geregelt in irgendeiner Form streitig zu machen.',
    },
  },
  confidentiality: {
    title: 'Geheimhaltung',
    content: {
      p1: 'gut geregelt darf vertrauliche Informationen und/oder Materialien ohne ausdrückliche schriftliche Zustimmung des Kunden nicht an Dritte weitergeben oder Dritten direkt oder indirekt zugänglich machen.',
      p2: 'gut geregelt verpflichtet sich, die ihm bekannt gewordenen vertraulichen Informationen/Materialien ohne ausdrückliche schriftliche Zustimmung des Kunden nicht zu nutzen und/oder Dritten zur Verfügung zu stellen.',
      p3: 'Die vertraulichen Informationen sind von gut geregelt ausschliesslich im Zusammenhang mit und für die Erbringung des Services zu verwenden. gut geregelt darf vertrauliche Informationen an seine Mitarbeiter nur weitergeben, wenn dies für die Abwicklung des vorgenannten Projekts erforderlich ist. Mitarbeiter sind gemäss dieser Vereinbarung zur Vertraulichkeit zu verpflichten. Die Geheimhaltungsverpflichtung gilt auch für die mit gut geregelt verbundenen Unternehmen. gut geregelt wird sie auch entsprechend zur Geheimhaltung verpflichten.',
      p4: 'gut geregelt kann vertrauliche Informationen offenlegen, wenn dies gesetzlich oder durch andere staatliche Vorschriften vorgeschrieben ist. In diesen Fällen wird gut geregelt den Kunden unverzüglich informieren und auf Verlangen des Kunden die erforderlichen Schutzmassnahmen ergreifen, soweit diese angemessen sind. gut geregelt trifft geeignete Vorkehrungen, um vertrauliche Informationen und Materialien vor unbefugtem Zugriff durch Dritte zu schützen.',
    },
  },
  dataProtectionAndSecurity: {
    title: 'Datenschutz und Datensicherheit',
    content: {
      p1: 'gut geregelt wird die Daten des Kunden mit höchster Sorgfalt behandeln und sie vor Missbrauch und Verlust schützen. Dazu trifft gut geregelt technische und organisatorische Massnahmen, welche den gültigen Anforderungen der DSGVO entsprechen. Spezielle Vereinbarungen zur Speicherung der Daten in bestimmten Ländern können im Rahmen von technischen Möglichkeiten getroffen werden.',
      p2: 'Der Kunde ist für die Rechtmässigkeit der Datenweitergabe bzw. deren Verwendung verantwortlich. Alle von gut geregelt gespeicherten und bearbeiteten Daten des Kunden sind ausschliessliches Eigentum des Kunden und werden von gut geregelt ausschliesslich zu Zwecken der Vertragserfüllung genutzt.',
    },
  },
  disclaimer: {
    title: 'Haftungsausschluss',
    content: {
      p1: 'gut geregelt bietet keine Rechtsberatung an, sondern stellt abgestimmte Muster zur Verfügung. In komplizierten Angelegenheiten ist der Beizug einer Rechtsanwältin / eines Rechtsanwaltes unerlässlich. gut geregelt ersetzt keine anwaltliche Beratung.',
      p2: 'Die Haftung von gut geregelt wird, soweit gesetzlich zulässig, vollumfänglich wegbedungen, insbesondere die Haftung für leichtes und mittleres Verschulden, indirekte oder Folgeschäden, insbesondere entgangener Gewinn, nicht realisierte Einsparungen, Betriebsunterbrechung, Ansprüche Dritter, Mangelfolgeschäden oder Schäden aus Datenverlust sowie die Haftung für Hilfspersonen und beigezogene Dritte. Eine allfällige Haftung von gut geregelt ist maximal auf die Höhe der dem Nutzer von gut geregelt für die Nutzung der Plattform und der Dienstleistungen für die Dauer von 12 Monaten in Rechnung gestellten Nutzungsgebühr beschränkt. ',
    },
  },
  applicableLaw: {
    title: 'Anwendbares Recht',
    content: {
      p1: 'Auf alle Verträge zwischen gut geregelt und dem Nutzer ist ausschliesslich schweizerisches Recht unter Ausschluss des Kollisionsrechts, des Übereinkommens der Vereinten Nationen über Verträge über den internationalen Warenkauf (CISG) und des internationalen Privatrechts anwendbar.',
    },
  },
  jurisdiction: {
    title: 'Gerichtsstand',
    content: {
      p1: 'Gerichtsstand für sämtliche Streitigkeiten, Meinungsverschiedenheiten oder Ansprüche aus oder im Zusammenhang mit dem Vertragsverhältnis zwischen gut geregelt und dem Nutzer, einschliesslich dessen Gültigkeit, Ungültigkeit, Verletzung oder Auflösung, ist der Sitz von gut geregelt. ',
    },
  },
};

export default agb;
