import {
  Box,
  Switch,
  FormControlLabel,
  Paper,
  Typography,
} from '@mui/material';
import usePartner from '../../../lib/hooks/usePartner';

export default function PartnerSwitcher() {
  const { partnerKey, setPartnerKey, removePartnerKey, isPartnerMode } =
    usePartner();

  const TEST_PARTNER_KEY = 'pax';

  const handlePartnerToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    if (checked) {
      setPartnerKey(TEST_PARTNER_KEY);
      setTimeout(() => window.location.reload(), 100);
    } else {
      removePartnerKey();
      setTimeout(() => window.location.reload(), 100);
    }
  };

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 16,
        left: 70,
        zIndex: 9999,
        padding: 2,
        opacity: 0.9,
        boxShadow: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Typography variant="caption" color="text.secondary">
        Testing Tool
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={isPartnerMode}
            onChange={handlePartnerToggle}
            color="primary"
          />
        }
        label={
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body2">
              {isPartnerMode ? 'Partner Mode' : 'Default Mode'}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {isPartnerMode ? `Partner: ${partnerKey}` : 'No partner'}
            </Typography>
          </Box>
        }
      />
    </Paper>
  );
}
