const metadata = {
  main: {
    title:
      'Buona previdenza con gut geregelt - Il tuo portale svizzero per la previdenza',
    description:
      'Finalmente ben preparati: crea online tutti i documenti di previdenza importanti! Legalmente sicuro ✓ Completato in 10 min ✓ Comodamente da casa ✓ Piattaforma svizzera di previdenza ✓',
    keywords: [
      'Testamento biologico',
      'Mandato precauzionale',
      'Testamento',
      'Previdenza personale',
      'Pianificazione pensionistica',
      'Previdenza per la vecchiaia',
    ],
  },
  living_will: {
    title: 'Crea online il testamento biologico svizzero in 5 min',
    description:
      'In 5 min ben preparati: crea online il testamento svizzero con gut geregelt. Legalmente sicuro ✓ Comodamente da casa ✓ Fornitore svizzero ✓',
    keywords: [
      'Testamento biologico',
      'Stampa testamento biologico',
      'Direttiva anticipata',
      'Direttive anticipate da stampare',
    ],
  },
  power_of_attorney: {
    title: 'Crea online il mandato precauzionale svizzero in 5 min',
    description:
      'In 5 min ben preparati con un mandato precauzionale svizzero. Protezione per la famiglia ✓ Legalmente sicuro ✓ Comodamente da casa ✓ Fornitore svizzero ✓',
    keywords: [
      'Mandato precauzionale',
      'Modello di mandato precauzionale',
      'Modello mandato precauzionale',
      'Modello per mandato precauzionale',
      'Mandato precauzionale',
    ],
  },
  last_will: {
    title: 'Crea online il testamento svizzero in 10 min',
    description:
      'In 10 min ben preparati: crea online il testamento svizzero legalmente sicuro. Chiarezza per i familiari ✓ Semplice ✓ Comodamente da casa ✓ Fornitore svizzero ✓',
    keywords: [
      'Testamento',
      'Quota legittima eredità',
      'Eredità quota legittima',
      'Patrimonio',
      'Successione legale',
    ],
  },
  marriage_contract: {
    title: 'Crea online il contratto matrimoniale svizzero in 5 min',
    description:
      'In 10 min ben preparati: crea online il contratto matrimoniale svizzero con gut geregelt. Legalmente sicuro ✓ Comodamente da casa ✓ Fornitore svizzero ✓',
    keywords: [
      'Contratto matrimoniale',
      'Quota legittima eredità',
      'Contratto matrimoniale acquisto immobiliare',
      'Patrimonio',
      'Successione legale',
    ],
  },
  partner_with_us: {
    title: 'Amplia la tua offerta previdenziale con gut geregelt',
    description:
      'Utilizza la soluzione white label di gut geregelt per rendere accessibile ai tuoi clienti una previdenza completa. Si integra anche nel tuo processo di consulenza.',
    keywords: [
      'Fondazione previdenziale',
      'Soluzione previdenziale',
      'Consiglio di fondazione',
      'Consulenza previdenziale',
      'Consulente previdenziale',
    ],
  },
  digital_footprint: {
    title: "Crea online l'eredità digitale in Svizzera",
    description:
      "In 10 min ben preparati con un'eredità digitale. Documenta i beni ✓ Rappresenta i valori digitali ✓ Conserva i dati ✓ Comodamente da casa ✓ Fornitore svizzero ✓",
  },
  register_assets: {
    title: 'Crea online il registro dei beni e delle proprietà svizzero',
    description:
      'In 10 min ben preparati con un registro dei beni e delle proprietà svizzero. Registra i beni ✓ Crea chiarezza ✓ Comodamente da casa ✓ Fornitore svizzero ✓',
  },
  grosseItern: {
    title: 'Offerta speciale della rivista Nonni',
  },
  preventiveCheckUp: {
    title: 'Controllo previdenziale',
    description:
      'Con il controllo previdenziale puoi verificare e, se necessario, adattare la tua pianificazione previdenziale.',
    keywords: [
      'Controllo previdenziale',
      'Rischio di responsabilità',
      'Consiglio di fondazione',
      'Consulenza previdenziale',
      'Cassa pensione',
    ],
  },
  cohabitation_agreement: {
    title: 'Crea online il contratto di convivenza svizzero',
    description:
      'In 10 min ben preparati: crea online il contratto di convivenza svizzero con gut geregelt. Legalmente sicuro ✓ Comodamente da casa ✓ Fornitore svizzero ✓',
  },
  heirat: {
    title: 'Matrimonio: Questa previdenza è ora importante',
    description:
      'Sposarsi significa assumersi delle responsabilità. Per se stessi, per il coniuge, per la famiglia. Vi aiutiamo con la previdenza necessaria.',
  },
  'geburt-eines-kindes': {
    title: 'Nascita di un bambino: Questa previdenza è ora importante',
    description:
      'La nascita di un bambino è un evento meraviglioso. Ora è il momento ideale per proteggere i bei momenti e pensare a lungo termine.',
  },
  immobilienkauf: {
    title: 'Acquisto di un immobile: Questa previdenza è ora importante',
    description:
      "L'acquisto di una casa è un grande passo. Per mantenere questa sicurezza anche nei momenti difficili, è importante prepararsi in anticipo.",
  },
  pensionierung: {
    title: 'Pensionamento: Questa previdenza è ora importante',
    description:
      'Con il pensionamento inizia una nuova fase della vita. È il momento di pensare al futuro.',
  },
  krankheit: {
    title: 'Diagnosi di una malattia: Questa previdenza è ora importante',
    description:
      'Una malattia grave cambia tutto. Una disposizione anticipata e un testamento garantiscono che i vostri desideri siano rispettati e che i vostri cari siano protetti.',
  },
  'tod-verwandter': {
    title: 'Morte di un parente: Questa previdenza è ora importante',
    description:
      'La morte di una persona cara è un momento difficile. Create ordine e aiutate i vostri parenti a gestire tutto secondo i vostri desideri.',
  },
  unternehmensgruendung: {
    title: 'Avvio di un’attività: Questa previdenza è ora importante',
    description:
      "Avviare un'attività trasforma la vostra visione in realtà e crea valore duraturo. In questo momento emozionante, è importante garantire protezione.",
  },
  scheidung: {
    title: 'Divorzio: Questa previdenza è ora importante',
    description:
      'Il divorzio può essere un momento difficile. Vi aiutiamo a prendere tutte le disposizioni necessarie per garantire che voi e la vostra famiglia siate ben protetti.',
  },
};
export default metadata;
