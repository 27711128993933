const toast_notifications = {
  success: {
    register: 'Registrazione avvenuta con successo',
    login: 'Accesso effettuato con successo',
    logout: 'Disconnessione avvenuta con successo',
    representative_add: 'Rappresentante aggiunto con successo',
    representative_update: 'Rappresentante aggiornato con successo',
    representative_delete: 'Rappresentante eliminato con successo',
    message_sent: 'Messaggio inviato con successo',
    package_update: 'Pacchetto aggiornato con successo',
    good_update: 'Bene aggiornato con successo',
    good_create: 'Bene aggiunto con successo',
    profile_update: 'Profilo aggiornato con successo',
    password_update: 'Password aggiornata con successo',
    password_reset: 'Link per reimpostare la password inviato alla tua email',
    asset_delete: 'Bene eliminato con successo',
    property_update: 'Proprietà aggiornata con successo',
    property_create: 'Proprietà aggiunta con successo',
    beneficiary_update: 'Beneficiario aggiornato con successo',
    beneficiary_create: 'Beneficiario aggiunto con successo',
    beneficiary_delete: 'Beneficiario eliminato con successo',
    reminder_service: 'Servizio di promemoria aggiornato con successo',
    childAdd: 'Bambino aggiunto con successo',
    childDelete: 'Bambino eliminato con successo',
    childEdit: 'Bambino aggiornato con successo',
    partner_message: 'Messaggio inviato con successo',
    invitation_sent: 'Invito inviato con successo',
    document_submit: 'Documento inviato con successo',
    deathReport: 'Rapporto di morte inviato con successo',
    teamInvite: 'Membro del team invitato con successo',
    addAdvisor: 'Consulente aggiunto con successo',
    platform_update: 'Impostazioni della piattaforma aggiornate con successo',
    cancel_invitation: 'Invito annullato con successo',
    resend_invitation: 'Invito inviato nuovamente con successo',
    delete_team_member: 'Membro del team eliminato con successo',
    joinWaitList: "Iscritto con successo alla lista d'attesa",
    widgetRequest: 'Richiesta di widget inviata con successo',
    createUser: 'Utente creato con successo',
    deleteUser: 'Utente eliminato con successo',
    assignedNewPartner: 'Nuovo partner assegnato con successo',
    disconnectFromPartner: 'Disconnesso con successo dal partner',
    consulationRequest: 'Consulenza richiesta con successo',
    addedAsset: 'Bene aggiunto con successo',
    updatedAsset: 'Bene aggiornato con successo',
    deletedAsset: 'Bene eliminato con successo',
    addedFinancialInstitution: 'Istituto finanziario aggiunto con successo',
    updatedFinancialInstitution: 'Istituto finanziario aggiornato con successo',
    deletedFinancialInstitution: 'Istituto finanziario eliminato con successo',
    userTransfer: 'Utente trasferito con successo',
    addDigitalAsset: 'Bene digitale aggiunto con successo',
    editDigitalAsset: 'Bene digitale aggiornato con successo',
    deleteDigitalAsset: 'Bene digitale eliminato con successo',
    saving: 'Salvato con successo',
    otpCode: 'Codice OTP inviato con successo',
    fileUpload: 'File aggiornato con successo',
    fileMove: 'File spostato con successo',
    submitLatestTax: 'Rapporto di tassazione inviato con successo',
    folderCreatedSuccessfully: 'Cartella creata con successo',
    taxExpertHired: 'Consulente fiscale assegnato con successo',
    taxExpertRequested: 'Consulente fiscale richiesto con successo',
    userExists:
      'Esiste già un utente con questa email. Per favore utilizza questa email per accedere.',
    passwordChange: 'Password aggiornata con successo',
  },
  error: {
    register: 'Registrazione fallita',
    login: 'Accesso fallito, controlla le credenziali',
    logout: 'Disconnessione fallita',
    package_failed: 'Aggiornamento del pacchetto fallito',
    representative_add: 'Impossibile aggiungere il rappresentante',
    representative_update: 'Impossibile aggiornare il rappresentante',
    representative_delete: 'Impossibile eliminare il rappresentante',
    good_update: 'Impossibile aggiornare il bene',
    good_create: 'Impossibile aggiungere il bene',
    profile_update: 'Impossibile aggiornare il profilo',
    password_update: 'Impossibile aggiornare la password',
    password_reset: 'Impossibile inviare il link per reimpostare la password',
    asset_delete: 'Impossibile eliminare il bene',
    property_update: 'Impossibile aggiornare la proprietà',
    property_create: 'Impossibile aggiungere la proprietà',
    beneficiary_update: 'Impossibile aggiornare il beneficiario',
    beneficiary_create: 'Impossibile aggiungere il beneficiario',
    beneficiary_delete: 'Impossibile eliminare il beneficiario',
    reminder_service: 'Impossibile aggiornare il servizio di promemoria',
    childAdd: 'Impossibile aggiungere il bambino',
    childDelete: 'Impossibile eliminare il bambino',
    childEdit: 'Impossibile aggiornare il bambino',
    partner_message: 'Impossibile inviare il messaggio',
    invitation_sent: "Impossibile inviare l'invito",
    document_submit: 'Impossibile inviare il documento',
    deathReport:
      'Impossibile inviare il rapporto di morte, forse è già stato inviato',
    teamInvite: 'Impossibile invitare il membro del team',
    addAdvisor: 'Impossibile aggiungere il consulente',
    platform_update: 'Impossibile aggiornare le impostazioni della piattaforma',
    cancel_invitation: "Impossibile annullare l'invito",
    resend_invitation: "Impossibile inviare nuovamente l'invito",
    delete_team_member: 'Impossibile eliminare il membro del team',
    joinWaitList: "Impossibile iscriversi alla lista d'attesa",
    widgetRequest: 'Impossibile inviare la richiesta di widget',
    createUser: "L'utente esiste già",
    deleteUser: "Impossibile eliminare l'utente",
    assignedNewPartner: 'Impossibile assegnare il nuovo partner',
    disconnectFromPartner: 'Impossibile disconnettersi dal partner',
    consulationRequest: 'Impossibile richiedere la consulenza',
    addedAsset: 'Impossibile aggiungere il bene',
    updatedAsset: 'Impossibile aggiornare il bene',
    deletedAsset: 'Impossibile eliminare il bene',
    addedFinancialInstitution: "Impossibile aggiungere l'istituto finanziario",
    updatedFinancialInstitution:
      "Impossibile aggiornare l'istituto finanziario",
    deletedFinancialInstitution: "Impossibile eliminare l'istituto finanziario",
    userTransfer: "Impossibile trasferire l'utente",
    addDigitalAsset: 'Impossibile aggiungere il bene digitale',
    editDigitalAsset: 'Impossibile aggiornare il bene digitale',
    deleteDigitalAsset: 'Impossibile eliminare il bene digitale',
    saving: 'Salvataggio fallito',
    otpCode: 'Impossibile inviare il codice OTP',
    fileUpload: 'Impossibile caricare il file',
    fileMove: 'Impossibile spostare il file',
    submitLatestTax: 'Impossibile inviare il rapporto di tassazione',
    folderCreatedError: 'Impossibile creare la cartella',
    taxExpertHireFailed: 'Impossibile assegnare il consulente fiscale',
    taxExpertRequestFailed: 'Impossibile richiedere il consulente fiscale',
    userExists:
      'Esiste già un utente con questa email. Per favore utilizza questa email per accedere.',
    passwordChange:
      'Impossibile aggiornare la password, per favore controlla la tua password',
  },
};

export default toast_notifications;
