const digitalFootprint = {
  title: 'Impronta digitale',
  description: 'Condividi informazioni e accessi rilevanti con i tuoi parenti.',
  dashboardServiceCards: {
    importantInformation: {
      title: 'Informazioni importanti',
      description:
        'Fai una nota per i tuoi parenti su dove sono conservati documenti ufficiali, documenti bancari o assicurazioni, per ridurre lo sforzo e lo stress in un momento difficile.',
    },
    digitalAssets: {
      title: 'Beni digitali & Abbonamenti',
      description:
        'Tieni traccia di tutti i tuoi abbonamenti, profili sui social media o altri account e assicurati di poter trasmettere artefatti e beni digitali ai tuoi discendenti.',
    },
    picturesAndVideos: {
      title: 'Immagini & Video',
      description:
        'Assicurati che i tuoi discendenti abbiano accesso a foto e video che desideri conservare per le generazioni future.',
    },
  },
};

export default digitalFootprint;
