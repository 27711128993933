import {
  contractParties,
  lifeSituation,
} from '#/locales/de/cohabitationAgreement/questions';
import outputDoc from '#/locales/de/cohabitationAgreement/outputDoc';

const cohabitationAgreement = {
  mainHeader: {
    regulateTogether: 'Regeln Sie Ihr gemeinsames Leben',
    effectiveDocs: 'Ihr wirksamer Konkubinatsvertrag und Inventar',
  },
  stepperInfo: [
    {
      title: 'Partner',
      label: 'Vertragsparteien',
    },
    {
      title: 'Lebenssituation',
      label: 'Lebenssituation',
    },
    {
      title: 'Inventar',
      label: 'Inventar',
    },
    {
      title: 'Hinterlegen Sie Ihren Konkubinatsvertrag und Ihr Inventar',
      label: 'Ihr Vertrag',
    },
  ],

  stepOne: {
    questions: contractParties,
    malePartner: 'Ihr Lebenspartner',
    femalePartner: 'Ihre Lebenspartnerin',
  },
  stepTwo: {
    questions: lifeSituation,
    periodOptions: {
      monthly: 'Monatlich',
      bi_monthly: 'Zwei Monate',
      quarterly: 'Vierteljährlich',
      weekly: 'Wöchentlich',
      bi_weekly: 'Zwei Wochen',
    },
    jointQuestions: {
      joint_household_account_bank:
        'Bei welcher Bank haben Sie Ihr gemeinsames Haushaltskonto?',
      equal_payment:
        'Wie hoch sind die gleichen Zahlungen und wie häufig sind sie?',
      compensation_payment_cadence:
        'Wie ist das Verhältnis der Zahlungen auf das gemeinsame Konto und wie oft prüfen Sie den Kontostand auf allfällige Ausgleichszahlungen?',
      expenses_to_be_covered: 'Welche Kosten werden übernommen?',
    },
    oneFullTimeOnePartTime: {
      who_works_full_time: 'Wer arbeitet Vollzeit?',
      your_partner: '{{partnersGender}}',
      you: 'Sie',
      yourWorkload: 'Wie hoch ist Ihr Arbeitspensum?',
      partnersWorkload:
        'Wie hoch ist das Arbeitspensum im Vergleich zu {{partnersGender}}',
      malePartner: 'Ihres Partners',
      femalePartner: 'Ihrer Partnerin?',
      equal_payment:
        'Wie hoch sind die Zahlungsbeiträge auf das gemeinsame Konto und wie oft werden diese vorgenommen?',
      account_balance_in_case_of_separation:
        'Wie soll bei einer Auflösung der Lebensgemeinschaft das Kontoguthaben verteilt werden?',
    },
    oneFullTimeOtherNotWorking: {
      household_and_children_responsibility:
        'Wer kümmert sich um Haushalt und Kinder?',
      monthly_transfer_for_free_disposal:
        'Überweisen Sie Ihrem Partner monatlich einen Betrag zur freien Verfügung/Erhalten Sie von Ihrem Partner monatlich eine Überweisung zur freien Verfügung?',
      yes: 'Ja',
      no: 'Nein',
      free_disposal_amount: 'Wie viel?',
    },
  },
  stepThree: {
    soleOwnershipList:
      'Listen Sie bitte alle Gegenstände auf, die sich im alleinigen Eigentum von Ihnen oder Ihrem Partner befinden.',
    commonlyPurchasedList:
      'Listen Sie bitte alle Artikel auf, die Sie zusammen gekauft haben',
    confirmDeleteInventory: {
      title: 'Bestätigen Sie das Löschen',
      description: 'Möchten Sie diesen Artikel wirklich löschen?',
      cancel: 'Abbrechen',
      delete: 'Löschen',
    },
  },
  stepFour: {
    document: outputDoc,
    nextStepsTitle: 'Die nächsten Schritte',
    nextStep1:
      'Drucken Sie den Konkubinatsvertrag und das Inventar aus und lesen Sie ihn sorgfältig durch.',
    nextStep2: 'Unterschreiben Sie beide Dokumente.',
    nextStep3: 'Laden Sie die beiden Dateien unten hoch.',
    nextStep4: 'Aktualisieren Sie das Inventar regelmässig',
  },
  expensesOptions: {
    rent: 'Mietzins',
    additional:
      'Wohnnebenkosten wie Elektrizität, Heizkosten, Wasser, Abfallgebühren, etc.',
    radioTV: 'Radio- und TV-Anschluss ',
    groceries: 'Lebensmittel',
    cleaning: 'Reinigungsaufwände',
  },
};

export default cohabitationAgreement;
