import React, { createContext, useMemo } from 'react';
import { useGetServicesStats } from '#/api/servicesQueries';
import useAuth from '#/hooks/useAuth';
import {
  initialState,
  ServiceStatsContextProps,
} from '#/types/serviceStatsType';

export const ServiceStatsContext =
  createContext<ServiceStatsContextProps>(initialState);

type ServiceStatsProviderProps = {
  children: React.ReactNode;
};

export function ServiceStatsProvider({ children }: ServiceStatsProviderProps) {
  const { isAuthenticated, user } = useAuth();

  const isPartnerMemeber =
    user?.partner?.role === 'consultant' ||
    user?.partner?.role === 'admin' ||
    user?.partner?.role === 'owner';

  const { data: stats, isLoading } = useGetServicesStats(
    isAuthenticated && !isPartnerMemeber
  );

  const values = useMemo(
    () => ({
      ...stats,
      isLoading,
    }),
    [stats, isLoading]
  ) as ServiceStatsContextProps;

  return (
    <ServiceStatsContext.Provider value={values || initialState}>
      {children}
    </ServiceStatsContext.Provider>
  );
}
