const grosseItern = {
  mainBanner: {
    intro: 'Simplement une bonne sensation :',
    title: 'Prévoyance bien organisée',
    subtitle:
      'Un sujet difficile devient enfin simple : Créez une directive anticipée, une procuration et un testament confortablement depuis chez vous - en seulement 20 minutes. Commencez dès maintenant avec le prestataire suisse gut geregelt.',
    buttonLabel: 'Commencer maintenant',
    sale: {
      blueText: 'Économisez 50%',
      blackText: "jusqu'au 31.12.2024",
    },
  },
  aboutCards: {
    justDoIt: {
      title: "Faites-le simplement avant qu'il ne soit trop tard",
      description:
        "L'espoir est de ne jamais avoir à utiliser la directive anticipée et le mandat de protection future. Cependant, les coups du sort arrivent, c'est pourquoi il vaut la peine de les établir tôt.",
    },
    security: {
      title: 'Sécurité pour le conjoint et la famille',
      description:
        'En créant ces documents à temps, vous gardez le contrôle sur vos souhaits. En même temps, vous protégez toute votre famille et évitez des situations de stress inutiles.',
    },
    done: {
      title: 'Fait en 20 minutes - depuis chez vous',
      description:
        'Avec gut geregelt, vous créez des documents et contrats juridiquement sûrs dans un langage facile à comprendre, confortablement depuis chez vous. Et ne vous inquiétez pas : pour les cas plus complexes, des experts juridiques vous aident.',
    },
    swissProvider: {
      title: 'Fournisseur suisse : bien organiser la prévoyance',
      description:
        "gut geregelt est votre suite numérique suisse de prévoyance. Faites-vous une faveur à vous et à vos proches et laissez-vous bien organiser dès aujourd'hui.",
    },
  },
  angebot: {
    phrase: 'Connu des médias et du magazine Grandparents (Édition 30/2024)',
    highlightedPhrase: 'Magazine Grandparents',
  },
};

export default grosseItern;
