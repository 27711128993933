const pathnames = {
  auth: {
    index: 'auth',
    login: 'accesso',
    verify: 'verificare',
    resetPassword: 'reimposta-password',
    passwordResetConfirm: 'conferma-reimpostazione-password',
    partnerOnboarding: 'onboarding-partner',
  },
  dashboard: {
    index: 'cruscotto',
    home: 'home',
    guestOn: 'ospite-su',
    guestOnDocumentHistory: 'ospite-su/:guestId/storia-documento',
    contact: 'contatto',
    livingWill: 'testamento-biologico',
    powerAttorney: 'procura',
    lastWill: 'testamento',
    marriageContract: 'contratto-di-matrimonio',
    assetsRegister: 'registro-dei-beni-e-delle-proprietà',
    cohabitationAgreement: 'accordo-di-convivenza',
    digitalLegacy: {
      index: 'eredità-digitale',
      importantInformation: 'informazioni-importanti',
      digitalAssets: 'beni-digitali',
      photosVideos: 'foto-video',
    },
    taxFolderClient: 'cartella-fiscale',
    chat: 'chat',
    thankyouCard: 'accordi-finali',
    consultant: 'consulente',
    consulting: 'consulenza',
    createUser: 'consulenza/crea-utente',
    user: {
      index: 'utente',
      account: 'account',
    },
  },
  compact: {
    forbidden: '403',
    notFound: '404',
    error: '500',
    maintenance: 'manutenzione',
    comingSoon: 'in-arrivo',
    contact: 'contatto',
    pension: 'previdenza',
    register: 'registrazione',
    onboardingStepOne: 'previdenza/fase=1',
    onboardingStepTwo: 'previdenza/fase=2',
    onboardingStepThree: 'previdenza/fase=3',
    acceptInvitation: 'persona-di-fiducia/invito',
    familyInvitation: 'famiglia/invito',
    partnerInvitation: 'partner/invito',
  },
  main: {
    index: '/',
    situationCheck: 'controllo-situazione-di-vita',
    grosselternMagazin: 'offerte/magazine-nonni',
    preventiveCheckUp: 'controllo-preventivo',
    situationLanding: 'previdenza',
    aboutUs: 'chi-siamo',
    imprint: 'impronta',
    privacyPolicy: 'politica-sulla-privacy',
    agb: 'termini-e-condizioni',
  },
};

export default pathnames;
