const poaLanding = {
  mainBanner: {
    intro: 'Erstellen Sie Ihren Vorsorgeauftrag online:',
    title: 'Rechtssicher, in 5 Minuten erledigt',
    subtitle:
      'Ohne Vorsorgeauftrag können selbst Ihr Ehepartner oder Ihre Familie im Notfall keine Entscheidungen für Sie treffen. Legen Sie jetzt fest, wer rechtlich befugt ist, in Ihrem Namen zu handeln.',
    buttonLabel: 'Vorsorgeauftrag erstellen',
  },
  youtubeTutorial: 'Warum ist ein Vorsorgeauftrag wichtig?',
  aboutCards: {
    guaranteeForPower: {
      title: 'Garantierte Handlungsfähigkeit',
      description:
        'Ermöglichen Sie Ihren Angehörigen, Entscheidungen in Ihrem besten Interesse zu treffen, wenn Sie es selbst nicht mehr können.',
    },
    mutualProtection: {
      title: 'Gegenseitiger Schutz',
      description:
        'Schützen Sie die Integrität Ihres Vermögens, indem Sie sicherstellen, dass wichtige Familienentscheidungen nur von Familienmitgliedern getroffen werden.',
    },
    clarificationForFamily: {
      title: 'Klarheit für die Familie',
      description:
        'Vermeiden Sie unnötige Diskussionen und schaffen Sie Klarheit für Ihre Angehörigen.',
    },
  },
  subBanner: {
    title: 'Gut geregelt mit einem rechtskonformen Vorsorgeauftrag',
    subtitle:
      'Mit gut geregelt erstellen Sie schnell Ihren Vorsorgeauftrag, erhalten rechtliche Beratung zu sensiblen Themen oder offenen Fragen und tun Ihren Angehörigen einen Gefallen.',
    buttonLabel: 'Jetzt kostenlos erstellen',
  },
  situations: {
    'geburt-eines-kindes': {
      content: {
        main: {
          title: 'Vorsorgeauftrag',
          description:
            'Ein Vorsorgeauftrag legt fest, wer Entscheidungen für Sie und Ihre Familie trifft, wenn Sie dazu nicht mehr in der Lage sind. Schützen Sie sich und Ihr Kind mit klaren Regelungen.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'Mit der Geburt eines Kindes kommt zusätzliche Verantwortung. Ein Vorsorgeauftrag stellt sicher, dass Ihr Kind auch im Notfall gut versorgt ist.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Bestimmen Sie, wer sich um Ihr Kind kümmert',
              'Rechtliche Sicherheit für Ihre Familie',
              'Schutz im Krankheits- oder Notfall',
            ],
          },
        ],
        ctas: {
          learnMore: 'Mehr erfahren',
          startProcess: 'Prozess starten',
        },
      },
    },
    immobilienkauf: {
      content: {
        main: {
          title: 'Vorsorgeauftrag',
          description:
            'Ein Vorsorgeauftrag bestimmt, wer sich um Ihr Vermögen und Ihre persönlichen Angelegenheiten kümmert, wenn Sie es selbst nicht mehr können. Dies schützt Ihr Zuhause und Ihre Familie.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'Mit dem Kauf einer Immobilie steigt die Verantwortung. Ein Vorsorgeauftrag stellt sicher, dass Ihre Immobilie auch im Notfall geschützt ist und Ihre Wünsche umgesetzt werden.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Schutz Ihres Zuhauses bei Krankheit oder Unfall',
              'Sicherheit für Ihre Familie',
              'Rechtliche Klarheit für Notfälle',
            ],
          },
        ],
        ctas: {
          learnMore: 'Mehr erfahren',
          startProcess: 'Prozess starten',
        },
      },
    },
    pensionierung: {
      content: {
        main: {
          title: 'Vorsorgeauftrag',
          description:
            'Der Vorsorgeauftrag legt fest, wer Ihre Angelegenheiten regelt, wenn Sie es selbst nicht mehr können. Dies stellt sicher, dass Ihr Leben und Vermögen nach Ihren Wünschen geführt werden.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'Mit dem Rentenalter sollte auch festgelegt sein, wer sich im Notfall um Ihre finanziellen und persönlichen Angelegenheiten kümmert.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Klare Regelungen für den Fall der Urteilsunfähigkeit',
              'Schutz von Vermögen und Rechten',
              'Entlastung der Familie',
            ],
          },
        ],
        ctas: {
          learnMore: 'Mehr erfahren',
          startProcess: 'Prozess starten',
        },
      },
    },
    unternehmensgruendung: {
      content: {
        main: {
          title: 'Vorsorgeauftrag',
          description:
            'Ein Vorsorgeauftrag bestimmt, wer Ihre geschäftlichen und privaten Angelegenheiten übernimmt, wenn Sie es selbst nicht mehr können. Dies sichert die Kontinuität Ihres Unternehmens und schützt Ihre Familie.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'Als Unternehmer tragen Sie grosse Verantwortung – nicht nur für sich selbst, sondern auch für Ihre Mitarbeiter und Geschäftspartner. Ein Vorsorgeauftrag stellt sicher, dass Ihr Unternehmen auch in Krisenzeiten handlungsfähig bleibt.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Schutz des Unternehmens bei Krankheit oder Unfall',
              'Fortführung der Geschäftsführung im Notfall',
              'Entlastung der Familie',
            ],
          },
        ],
        ctas: {
          learnMore: 'Mehr erfahren',
          startProcess: 'Prozess starten',
        },
      },
    },
    scheidung: {
      content: {
        main: {
          title: 'Vorsorgeauftrag',
          description:
            'Ein Vorsorgeauftrag legt fest, wer Ihre persönlichen und finanziellen Angelegenheiten regelt, wenn Sie es nicht mehr können. Besonders nach einer Scheidung ist es wichtig, diese Regelungen neu zu definieren.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'Nach einer Scheidung ändern sich oft viele rechtliche Rahmenbedingungen. Ein aktualisierter Vorsorgeauftrag stellt sicher, dass Ihre Wünsche in Ihrer neuen Lebenssituation berücksichtigt werden.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Neuordnung Ihrer persönlichen Angelegenheiten',
              'Schutz im Krankheitsfall',
              'Klarheit und Sicherheit für die Zukunft',
            ],
          },
        ],
        ctas: {
          learnMore: 'Mehr erfahren',
          startProcess: 'Prozess starten',
        },
      },
    },
  },
  newsletter: {
    title: 'Werden Sie gut geregelt mit Ihrem Vorsorgeauftrag',
    buttonLabel: 'Jetzt erstellen',
  },
  faq: {
    questions: {
      question1: 'Was ist ein Vorsorgeauftrag?',
      question2: 'Wann tritt eine Urteilsunfähigkeit ein?',
      question3:
        'Was passiert, wenn ich urteilsunfähig werde und keinen Vorsorgeauftrag habe?',
      question4: 'Was muss ich im Vorsorgeauftrag festhalten?',
      question5: 'Wie erstelle ich meinen Vorsorgeauftrag?',
      question6: 'Wie wähle ich meine Vertrauensperson?',
      question7: 'Was ist die KESB und welche Rolle spielt sie?',
      question8: 'Kann ich meinen Vorsorgeauftrag jederzeit anpassen?',
      question9: 'Kann ich meinen Vorsorgeauftrag widerrufen?',
      question10:
        'Kann ich im Vorsorgeauftrag auch meine Behandlungswünsche für einen medizinischen Notfall festhalten?',
      question11:
        'Gibt es weitere Dokumente, die ich neben dem Vorsorgeauftrag erstellen sollte?',
      question12:
        'Wie unterstützt mich gut geregelt bei meinem Vorsorgeauftrag?',
    },
    answers: {
      answer1:
        'Im Vorsorgeauftrag halten Sie fest, wer Ihre Interessen in persönlichen und finanziellen Angelegenheiten im Falle einer Urteilsunfähigkeit vertreten soll. Mit dem Vorsorgeauftrag stellen Sie sicher, dass Ihre Interessen vertreten sind und Ihr Wille auch umgesetzt wird.',
      answer2:
        'Eine Urteilsunfähigkeit kann zum Beispiel infolge einer schweren Erkrankung wie Demenz, nach einem Schlaganfall oder auch nach einem schweren Unfall mit anschliessendem Koma eintreten.',
      answer3:
        'Das hängt davon ab, was Ihr Zivilstand ist: Als alleinstehende Person, als verheiratete Person oder in einer eingetragenen Partnerschaft, und als im Konkubinat lebende Person.',
      answer4:
        'Der Vorsorgeauftrag beinhaltet drei Bereiche: Personensorge, Vermögenssorge und Rechtsverkehr. Diese können Sie entweder alle der selben Vertretungsperson zusichern oder auch unterteilen.',
      answer5:
        'Sie können Ihren Vorsorgeauftrag selbst erstellen, wichtig dabei: Datum und Unterschrift nicht vergessen. Oder Sie erstellen ihn gemeinsam mit einem Notar und lassen ihn öffentlich beglaubigen.',
      answer6:
        'Sie können entweder eine Person für alle drei Bereiche oder auch mehrere Personen bestimmen. Wichtig ist, dass Sie dieser Person zu 100% vertrauen.',
      answer7:
        'Die Kindes- und Erwachsenenschutzbehörde (KESB) stellt den Schutz von gefährdeten Kindern und Erwachsenen sicher. Im Fall des Vorsorgeauftrages wird die KESB tätig, wenn sie über die Urteilsunfähigkeit einer Person informiert wird.',
      answer8:
        'Ja, mit der einzigen Voraussetzung, dass Sie zum Zeitpunkt der Anpassung urteilsfähig sein müssen. Ein neuer Vorsorgeauftrag ersetzt die letzte Version.',
      answer9:
        'Ja, Sie können Ihren Vorsorgeauftrag jederzeit annullieren, indem Sie das Dokument vernichten.',
      answer10:
        'Ja, das können Sie. Es macht allerdings zusätzlich Sinn, separat eine Patientenverfügung zu erstellen.',
      answer11:
        'Ja, das {{lastWill}} und die {{livingWill}} sind zwei weitere wichtige Dokumente, die Sie zeitnah verfassen sollten.',
      answer12:
        'Bei gut geregelt helfen wir Ihnen mit dem rechtsgültigen und reibungslosen Erstellen Ihres Vorsorgeauftrags.',
    },
    hyperlinks: {
      lastWill: 'Testament',
      livingWill: 'Patientenverfügung',
      powerOfAttorney: 'Vorsorgeauftrag',
    },
  },
};

export default poaLanding;
