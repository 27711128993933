const toast_notifications = {
  success: {
    register: 'Successfully registered',
    login: 'Login successful',
    logout: 'Logout successful',
    representative_add: 'Representative successfully added',
    representative_update: 'Representative successfully updated',
    representative_delete: 'Representative successfully deleted',
    message_sent: 'Message sent successfully',
    package_update: 'Package successfully updated',
    good_update: 'Item successfully updated',
    good_create: 'Item successfully added',
    profile_update: 'Profile successfully updated',
    password_update: 'Password successfully updated',
    password_reset: 'Password reset link sent to your email',
    asset_delete: 'Asset successfully deleted',
    property_update: 'Property successfully updated',
    property_create: 'Property successfully added',
    beneficiary_update: 'Beneficiary successfully updated',
    beneficiary_create: 'Beneficiary successfully added',
    beneficiary_delete: 'Beneficiary successfully deleted',
    reminder_service: 'Reminder service successfully updated',
    childAdd: 'Child successfully added',
    childDelete: 'Child successfully deleted',
    childEdit: 'Child successfully updated',
    partner_message: 'Message sent successfully',
    invitation_sent: 'Invitation sent successfully',
    document_submit: 'Document submitted successfully',
    deathReport: 'Death report submitted successfully',
    teamInvite: 'Team member successfully invited',
    addAdvisor: 'Advisor successfully added',
    platform_update: 'Platform settings successfully updated',
    cancel_invitation: 'Invitation successfully canceled',
    resend_invitation: 'Invitation successfully resent',
    delete_team_member: 'Team member successfully deleted',
    joinWaitList: 'Successfully joined the waitlist',
    widgetRequest: 'Widget request sent successfully',
    createUser: 'User successfully created',
    deleteUser: 'User successfully deleted',
    assignedNewPartner: 'New partner successfully assigned',
    disconnectFromPartner: 'Successfully disconnected from partner',
    consulationRequest: 'Consultation successfully requested',
    addedAsset: 'Asset successfully added',
    updatedAsset: 'Asset successfully updated',
    deletedAsset: 'Asset successfully deleted',
    addedFinancialInstitution: 'Financial institution successfully added',
    updatedFinancialInstitution: 'Financial institution successfully updated',
    deletedFinancialInstitution: 'Financial institution successfully deleted',
    userTransfer: 'User successfully transferred',
    addDigitalAsset: 'Digital asset successfully added',
    editDigitalAsset: 'Digital asset successfully updated',
    deleteDigitalAsset: 'Digital asset successfully deleted',
    saving: 'Successfully saved',
    otpCode: 'OTP code sent successfully',
    fileUpload: 'File successfully uploaded',
    fileMove: 'File successfully moved',
    submitLatestTax: 'Successfully submitted',
    folderCreatedSuccessfully: 'Folder created successfully',
    taxExpertHired: 'Tax expert successfully hired',
    taxExpertRequested: 'Tax expert successfully requested',
    userExists:
      'There is already a user with this email. Please use this email to login.',
    passwordChange: 'Password successfully changed',
  },
  error: {
    register: 'Registration failed',
    login: 'Login failed, please check your credentials',
    logout: 'Logout failed',
    package_failed: 'Package could not be updated',
    representative_add: 'Representative could not be added',
    representative_update: 'Representative could not be updated',
    representative_delete: 'Representative could not be deleted',
    good_update: 'Item could not be updated',
    good_create: 'Item could not be added',
    profile_update: 'Profile could not be updated',
    password_update: 'Password could not be updated',
    password_reset: 'Password reset link could not be sent',
    asset_delete: 'Asset could not be deleted',
    property_update: 'Property could not be updated',
    property_create: 'Property could not be added',
    beneficiary_update: 'Beneficiary could not be updated',
    beneficiary_create: 'Beneficiary could not be added',
    beneficiary_delete: 'Beneficiary could not be deleted',
    reminder_service: 'Reminder service could not be updated',
    childAdd: 'Child could not be added',
    childDelete: 'Child could not be deleted',
    childEdit: 'Child could not be updated',
    partner_message: 'Message could not be sent',
    invitation_sent: 'Invitation could not be sent',
    document_submit: 'Document could not be submitted',
    deathReport:
      'Death report could not be submitted, maybe it was already submitted',
    teamInvite: 'Team member could not be invited',
    addAdvisor: 'Advisor could not be added',
    platform_update: 'Platform settings could not be updated',
    cancel_invitation: 'Invitation could not be canceled',
    resend_invitation: 'Invitation could not be resent',
    delete_team_member: 'Team member could not be deleted',
    joinWaitList: 'Could not join the waitlist',
    widgetRequest: 'Widget request could not be sent',
    createUser: 'User already exists',
    deleteUser: 'User could not be deleted',
    assignedNewPartner: 'New partner could not be assigned',
    disconnectFromPartner: 'Could not disconnect from partner',
    consulationRequest: 'Consultation could not be requested',
    addedAsset: 'Asset could not be added',
    updatedAsset: 'Asset could not be updated',
    deletedAsset: 'Asset could not be deleted',
    addedFinancialInstitution: 'Financial institution could not be added',
    updatedFinancialInstitution: 'Financial institution could not be updated',
    deletedFinancialInstitution: 'Financial institution could not be deleted',
    userTransfer: 'User could not be transferred',
    addDigitalAsset: 'Digital asset could not be added',
    editDigitalAsset: 'Digital asset could not be updated',
    deleteDigitalAsset: 'Digital asset could not be deleted',
    saving: 'Saving failed',
    otpCode: 'OTP code could not be sent',
    fileUpload: 'File could not be uploaded',
    fileMove: 'File could not be moved',
    submitLatestTax: 'Could not be submitted',
    folderCreatedError: 'Folder could not be created',
    taxExpertHireFailed: 'Tax expert could not be hired',
    taxExpertRequestFailed: 'Tax expert could not be requested',
    userExists:
      'There is already a user with this email. Please use this email to login.',
    passwordChange:
      'Password could not be changed, please check your old password',
  },
};

export default toast_notifications;
