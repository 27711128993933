const outputDoc = {
  title: 'Contrat de Concubinage',
  from: 'de',
  born: 'né(e) le',
  civilStatus: {
    single: 'Célibataire',
    married: 'Marié(e)',
    widowed: 'Veuf/Veuve',
    divorced: 'Divorcé(e)',
    registered_partnership: 'Partenariat enregistré',
  },
  civil_status: 'État civil: {{civil_status}}',
  address: 'Adresse: {{address}}',
  male: 'Mari',
  female: 'Épouse',
  and: 'et',
  between: 'Entre',
  contractPartiesAgree: 'Les parties contractantes conviennent de ce qui suit:',
  spousePresentation:
    '{{partnerSurname}} {{partnerName}}, né(e) le {{partnerBirthday}}, citoyenneté {{partnerCitizenship}}, {{partnerCivilStatus}}, domicilié(e) à {{partnerAdress}}.',
  personalPresentation:
    '{{firstName}} {{lastName}}, né(e) le {{birthday}}, citoyenneté {{citizenship}}, {{civilStatus}}, domicilié(e) à {{adress}}.',
  preliminaryRemark: {
    title: 'Remarque Préliminaire',
    section1: {
      firstDefault:
        "Nous nous sommes rencontrés en {{meet_year}} et vivons ensemble depuis le {{date_of_moving_in}} à l'adresse suivante: {{adress}}.",
      secondDefault:
        "Nous avons l'intention de maintenir notre relation de concubinage pour une durée indéterminée.",
      bothEmployedNoChildren: 'Nous travaillons tous les deux à temps plein.',
      oneWorksFullTime:
        '{{who_works_full_time}} travaille à temps plein, tandis que {{who_works_part_time}} travaille à temps partiel ({{workload_distribution}}%).',
      oneFullTimeOneHousehold:
        "{{who_works_full_time}} travaille à temps plein, tandis que {{householder}} s'occupe du ménage et de {{childrenNo}}.",
      singularChild: "l'enfant",
      plurarChildren: 'les enfants',
    },
  },
  ownership: {
    title: 'Propriété',
    section1: {
      text: 'Un inventaire des biens mobiliers sera établi et régulièrement mis à jour. Cet inventaire, signé par les deux parties, fait partie intégrante du présent accord.',
    },
  },
  costOfLiving: {
    title: 'Frais de Subsistance',
    section1: {
      firstText:
        'Chacun de nous contribue, dans les limites de ses possibilités économiques, aux frais de vie communs. À cet effet, nous ouvrirons un compte bancaire commun chez {{joint_household_account_bank}}.',
      bothEmployedNoChildren: {
        firstText:
          'Avant le premier du mois, {{firstParty}} et {{secondParty}} verseront {{equal_payment_frequency}} CHF {{equal_payment_amount}} chacun.',
        secondText: 'Premier paiement le {{date_of_first_payment}}.',
        thirdText:
          '{{compensation_payment_cadence}} le solde du compte sera vérifié. Un éventuel déficit sera couvert par les deux parties à parts égales.',
      },
      oneWorksFullTime: {
        firstText:
          'Avant le premier du mois, {{firstParty}} versera CHF {{amount_paid_by_me}} et {{secondParty}} CHF {{amount_paid_by_partner}} {{equal_payment_frequency}}.',
        secondText:
          '(= proportion {{percentage_paid_by_me}}/{{percentage_paid_by_partner}}).',
        thirdText: 'Premier paiement le {{date_of_first_payment}}.',
        fourthText:
          '{{compensation_payment_cadence}} le solde du compte sera vérifié. Tout déficit sera couvert selon la proportion {{percentage_paid_by_me}}/{{percentage_paid_by_partner}}.',
      },
      oneFullTimeOneHousehold: {
        firstText:
          'Avant le premier du mois, {{who_works_full_time}} versera CHF {{equal_payment_amount}} {{equal_payment_frequency}} sur le compte bancaire.',
        secondText: 'Premier paiement le {{date_of_first_payment}}.',
        thirdText:
          '{{compensation_payment_cadence}} le solde du compte sera vérifié. Tout déficit sera couvert par {{who_works_full_time}}.',
      },
    },
    section2: {
      title: 'Les dépenses couvertes par ce compte incluent :',
      expensesOptions: {
        rent: 'Loyer',
        additional:
          'Charges locatives telles que électricité, chauffage, eau, taxes sur les déchets, etc.',
        radioTV: 'Abonnement radio et télévision',
        groceries: 'Aliments',
        cleaning: 'Frais de ménage',
      },
      bothEmployedNoChildren:
        'La gestion du ménage sera assurée par les deux parties.',
      oneWorksFullTime:
        'La gestion du ménage sera principalement assurée par {{who_works_part_time}}. La compensation de {{who_works_full_time}} est incluse dans sa contribution au budget du ménage.',
      malePartner: 'son',
      femalePartner: 'sa',
      oneFullTimeOneHousehold: {
        firstText: 'La gestion du ménage sera assurée par {{household}}.',
        isFreeDisposal:
          '{{who_works_full_time}} verse mensuellement à {{household}} un montant de CHF {{free_disposal_amount}}.',
        thirdText: 'Ce montant est à la libre disposition de {{household}}.',
        fourthText:
          'Pour le reste, chacun gère et utilise ses revenus et sa fortune de manière indépendante. En particulier, les dépenses pour les objets et activités personnels (vêtements, formation continue, frais de transport, etc.) sont à la charge de chaque partenaire.',
        fifthText:
          'En cas de dissolution du concubinage, le solde éventuel du compte commun sera partagé à parts égales.',
      },
    },
  },
  children: {
    title: '{{childrenNo}}',
    plural: 'Enfants',
    singular: 'Enfant',
    text: "L'entretien {{childrenNo}} est réglé par la convention approuvée par l'autorité tutélaire. Cette convention fait partie intégrante du présent accord.",
    pluralText: "de l'enfant",
    singularText: 'des enfants',
  },
  living: {
    title: 'Logement',
    onePartyNameAgreement:
      "Le contrat de bail actuel est uniquement au nom de {{agreement_name}}. Nous souhaitons être tous les deux inscrits comme locataires et convenons avec le bailleur d'un avenant au contrat de bail.",
    onePartyNameAgreementSublease:
      'Le contrat de bail actuel est uniquement au nom de {{agreement_name}}. Les parties prévoient que {{agreement_name}} conclue un contrat de sous-location avec {{non_agreement_name}}. Ce contrat de sous-location fait partie intégrante du présent accord.',
    onePartyNameAgreementNoPlans:
      'Le contrat de bail actuel est uniquement au nom de {{agreement_name}}.',
    bothPartiesNameAgreement: 'Le contrat de bail est au nom des deux parties.',
    rentBudget: 'Le loyer est payé par la caisse commune du ménage.',
    equalRights:
      'Les deux partenaires ont le droit de signer le contrat comme locataire en cas de changement de logement.',
  },
  cirsumstancesChange: {
    title: 'Changement de Circonstances',
    noKids:
      "Nous nous engageons à adapter ce contrat en cas de changement des circonstances personnelles, notamment en cas d'attente d'un enfant.",
    withKids:
      "Nous nous engageons à adapter ce contrat en cas de changement des circonstances personnelles, notamment en cas d'attente d'un nouvel enfant.",
  },
  dissolution: {
    title: 'Dissolution du Contrat de Concubinage',
    firstText:
      'En cas de dissolution, chaque partenaire récupère ses biens propres. Les biens en copropriété seront répartis équitablement.',
    secondText:
      'Cet accord prend effet à la signature et est soumis au droit suisse. Le tribunal compétent est {{city}}.',
  },
  signature: {
    place: 'Lieu',
    date: 'Date',
  },
  inventory: {
    title: 'Inventaire',
    agreementBetween: 'Complète le contrat de concubinage entre',
    partnerPresentation:
      '{{partnerSurname}}, {{partnerName}}, né(e) le {{partnerBirthday}}, citoyenneté {{partnerCitizenship}}, {{partnerCivilStatus}}, domicilié(e) à {{partnerAdress}}.',
    personalPresentation:
      '{{firstName}} {{lastName}}, né(e) le {{birthday}}, citoyenneté {{citizenship}}, {{civilStatus}}, domicilié(e) à {{adress}}.',
    soleOwnership:
      'Les objets suivants sont et restent la propriété exclusive de {{owner}}:',
    notListedItems:
      "Les objets non listés sont la propriété exclusive de la partie qui dispose du justificatif de paiement correspondant. En cas de doute, la présomption légale de copropriété s'applique.",
    joinedOwnership: 'Les objets suivants ont été acquis en commun:',
    otherPersonals:
      "Cet inventaire ne tient pas compte des objets d'usage quotidien tels que les vêtements et autres effets personnels.",
    ownershipBenefiter:
      'En cas de séparation, les objets suivants deviendront la propriété exclusive de {{owner}}:',
  },
};

export default outputDoc;
