const validations = {
  user: {
    name: 'Name is required',
    first_name: 'First name is required',
    last_name: 'Last name is required',
    email: 'Email is required',
    invalidEmail: 'Email is invalid',
    civil_status: 'Civil status is required',
    gender: 'Please select your gender',
    place_of_citinzenship: 'Place of citizenship is required',
    date_of_birth: {
      required: 'Date of birth is required',
      max: 'Representative must be 18 years or older',
    },
    mobile_phone: {
      required: 'Mobile phone number is required',
      min: 'The mobile phone number is too short',
      max: 'The mobile phone number is too long',
      matches: 'The mobile phone number is invalid',
    },
    minPassword: 'The password must be at least 6 characters long',
    password: 'Password is required',
    confirmPassword: 'Confirm password is required',
    passwordMatch: 'Passwords must match',
    number_of_children: {
      max: 'Maximum of 12 children allowed',
    },
    agree: 'Please make sure you agree to gut geregelt terms of register',
  },
  adress: {
    street: 'Street is required',
    house_number: 'House number is required',
    zip_code: 'Zip code is required',
    city: 'City is required',
    country: 'Country is required',
  },
  invalid_date: 'Invalid date',
  mustBeNumber: 'This field must be a number',
  date_errors: {
    min_date: 'Date must be after {{date}}',
    max_date: 'Date cannot be in the future',
    invalid_format: 'Invalid date format',
  },
  platform_logo: 'The image should be at least 630 x 80 pixels',
  primary_representative: 'Primary representative is required',
  secondary_representative: 'Deputy is required',
  secondary_kids: 'Please make sure to save or select a person',
  rep_selection: 'Please select primary and deputy representatives',
  rep_inform_date: 'Please select the information date',
  selectAnOption: 'Select an option',
  current_rental_agreement_name:
    'Name of the current rental agreement is required',
  description_good: 'Description is required',
  storage_location: 'Storage location is required',
  beneficiary: 'Beneficiary is required',
  next_benefiter: 'Next beneficiary is required',
  previous_owner: 'Previous owner is required',
  who_are_you: 'Please tell me who you are',
  shouldNotBeEmpty: 'This field should not be empty',
  default_message: 'This field is required',
  meet_year: 'Year of meeting is required',
  must_be_number: 'This field should be a number',
  min_0: 'This field should be at least 0',
  max_100: 'This field should be at most 100',
  payment_ratio_per_party: 'Payment ratio per party is required',
  date_of_moving_in: 'Date of moving in is required',
  successors: {
    first_name: 'First name is required',
    last_name: 'Last name is required',
    email: 'Email is required',
    invalidEmail: 'Email is invalid',
    agree: 'Please agree that these persons receive guest access.',
    min: 'Please select at least two trusted persons',
    general_access_right: 'Access is required',
  },
  message: 'Message is required',
  role: 'Role is required',
  shouldBeNumber: 'This field should be a number',
  duplicateChild: 'There is a child with the same name',
  otpCode: 'OTP code is required',
  otpMinimum: 'OTP code should be at least 6 characters long',
  marriage: {
    place_of_marriage: 'Place of marriage is required',
    date_of_marriage: 'Date of marriage is required',
    have_children: 'Please select an option',
    number_of_children: 'Number of children is required',
    is_less_than_children: 'The number of children must match the list count',
    childrenAmount: 'The number of children must match the list count',
    acquired_after_marriage: 'Please select an option',
    acquired_property_after_marriage: 'Please select an option',
    maintain_ordinary_property_regime: 'Please select an option',
    spouse_disposal_right: 'Please select an option',
    disposal_fixed_amount: 'Fixed amount is required',
    disposal_percentage: 'Percentage is required',
    executor: 'Executor is required',
    deputy: 'Deputy is required',
    sameAsContractParties:
      'The contract parties cannot be selected as executor or deputy',
    assets: {
      description_good: 'Description is required',
      approximate_value: 'Approximate value is required',
      approximate_value_number: 'The value must be a number',
      storage_location: 'Storage location is required',
      previous_owner: 'Previous owner is required',
    },
  },

  digitalAssets: {
    assets: {
      description: 'Description is required',
      storage_location: 'Storage location is required',
      beneficiary_id: 'Beneficiary is required',
    },
    financial: {
      institution_name: 'Institution name is required',
      type: 'Type is required',
    },
  },

  importantInformation: {
    passport_storage_location: 'Passport storage location is required',
    identity_card_storage_location:
      'Identity card storage location is required',
    residence_permit_storage_location:
      'Residence permit storage location is required',
    family_book_storage_location: 'Family book storage location is required',
    birth_certificate_storage_location:
      'Birth certificate storage location is required',
    bank_documents_storage_location:
      'Bank documents storage location is required',
    ahv_fund: 'AHV fund is required',
    ahv_number: 'AHV number is required',
    pension_fund_organization: 'Pension fund organization is required',
    trusted_person: 'Executor is required',
    insurancePolicySchema: {
      insurance_type: 'Insurance type is required',
      insurance_company: 'Insurance company is required',
      policy_number: 'Policy number is required',
      storage_location: 'Storage location is required',
    },
    threeASchema: {
      pension_type: 'Pension type is required',
      financial_institution: 'Financial institution is required',
      storage_location: 'Storage location is required',
    },
    notarySchema: {
      name: 'Name is required',
      email: 'Email is required',
      invalidEmail: 'Email is invalid',
    },
  },

  acceptedFiles: {
    type: {
      image: 'Only images are allowed',
      png: 'Only PNG files are allowed',
      jpg: 'Only JPG files are allowed',
      txt: 'Only TXT files are allowed',
      docx: 'Only DOCX files are allowed',
      pdf: 'Only PDF or Word documents are allowed.',
    },
  },
  tag: 'Tag is required',
};

export default validations;
