import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { allLangs, defaultLang } from '#/config';

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const [currentLang, setCurrentLang] = useState(() => {
    const baseLanguage = i18n.language.split('-')[0];
    return allLangs.find((lang) => lang.value === baseLanguage) || defaultLang;
  });

  useEffect(() => {
    const baseLanguage = i18n.language.split('-')[0];
    const newLang =
      allLangs.find((lang) => lang.value === baseLanguage) || defaultLang;
    setCurrentLang(newLang);
  }, [i18n.language]);

  const handleChangeLanguage = (newlang: string) => {
    i18n
      .changeLanguage(newlang)
      .then(() => localStorage.setItem('i18nextLng', newlang));
  };

  const safeTranslate = (text: string, options?: any): string => {
    const result = translate(text, options);
    return typeof result === 'string' ? result : String(result);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: safeTranslate,
    currentLang,
    allLangs,
  };
}
